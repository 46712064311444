<template>
  <v-card-text>
    <v-container>
      <v-stepper v-model="step" style="box-shadow: none !important">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row
              :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-5'"
              dense
              style="border-radius: 15px !important"
            >
              <v-col class="text-center" cols="12" sm="12">
                <v-btn-toggle
                  v-model="currentMonth"
                  borderless
                  color="blue accent-3"
                  group
                  rounded
                  style="transition: 0.3s ease-in-out !important"
                >
                  <v-btn
                    v-for="month in months"
                    :key="month.index"
                    :disabled="loadingBankCashMonthData"
                    :value="month.index"
                    rounded
                    style="transition: 0.5s ease-in-out !important"
                    @click="getMonthData(month.index)"
                  >
                    {{ month.text }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>

            <v-progress-linear
              v-if="loadingBankCashMonthData"
              color="blue darken-4"
              indeterminate
            ></v-progress-linear>

            <v-row
              :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-5'"
              style="border-radius: 15px !important"
            >
              <v-col cols="12" sm="4">
                <v-card
                  class="text-center"
                  color="ma-2"
                  flat
                  style="transition: 0.3s ease-in-out"
                >
                  <v-card-text class="pt-5 pb-5">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <p
                          v-show="monthData.percent_balance_change !== 0"
                          :class="
                            monthData.percent_balance_change > 0
                              ? 'green--text'
                              : 'red--text'
                          "
                          style="transition: 0.3s ease-in-out"
                          v-on="on"
                        >
                          {{ Math.abs(monthData.percent_balance_change) }}%
                          <v-icon
                            v-if="monthData.percent_balance_change > 0"
                            color="green"
                            large
                            >mdi-trending-up
                          </v-icon>
                          <v-icon v-else color="red" large
                            >mdi-trending-down</v-icon
                          >
                          <small class="d-block text-small"
                            >Versus previous month</small
                          >
                        </p>
                      </template>
                      <span
                        >Previous Cash & Bank Balance:
                        {{
                          monthData.previous_total_bank_cash_balance
                            | absolute
                            | currency_symbol
                        }}</span
                      >
                    </v-tooltip>

                    <p
                      v-if="monthData.percent_balance_change === 0"
                      class="text-italic pa-3"
                    >
                      <em> No changes this month </em>
                    </p>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <h1 class="blue--text font-weight-black" v-on="on">
                          {{
                            monthData.total_bank_cash_balance
                              | short_number
                              | currency_symbol
                          }}
                          <small class="text-small blue--text d-block"
                            >Total Bank & Cash Balance</small
                          >
                        </h1>
                      </template>

                      <span>{{
                        monthData.total_bank_cash_balance
                          | toMoney
                          | currency_symbol
                      }}</span>
                    </v-tooltip>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-alert color="blue" dark class="text-center mt-2">
                  <h2 class="text-h5 font-weight-light">
                    <v-icon x-large>mdi-shimmer</v-icon>Note:
                  </h2>
                  <p>
                    Balances shown here are not cumulative but only reflect the
                    inflows and outflows recorded within the selected month.
                  </p>
                </v-alert>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <apexchart
                  :options="chartOptionsSpark"
                  :series="series"
                  :type="chartType"
                  height="200"
                ></apexchart>
              </v-col>
              <v-col cols="12">
                <v-list
                  :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-5'"
                  style="border-radius: 15px"
                  two-line
                >
                  <v-subheader>Balance List</v-subheader>
                  <v-list-item
                    @click="selectAccount(item)"
                    v-for="item in monthData.accounts_list"
                    :key="item.id"
                    :class="$vuetify.theme.isDark ? 'dark lighten-2' : 'white'"
                    class="border-bottom ma-2"
                    style="border-radius: 15px"
                  >
                    <v-list-item-avatar class="pa-3" size="50">
                      <v-avatar
                        class="ma-3"
                        color="blue lighten-5 blue--text"
                        size="100"
                      >
                        <v-icon class="ma-3" color="blue" large
                          >mdi-bank-plus</v-icon
                        >
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 font-weight-light">
                        {{ item.balance | absolute | currency_symbol }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.account_name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="grey">mdi-chevron-right</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-row
              style="border-radius: 15px !important"
              v-if="selectedAccount"
              :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-5'"
            >
              <v-col cols="12" sm="2" class="mt-2 mb-2">
                <v-btn color="blue" depressed rounded text @click="step = 1">
                  <v-icon>mdi-arrow-left</v-icon> Back
                </v-btn>
              </v-col>
              <v-col cols="12" sm="10" class="mt-2 mb-2">
                <h3 class="text-h5 font-weight-light">
                  Transactions for {{ selectedAccount.account_name }} in
                  {{ monthData.month }}, {{ monthData.year }}
                </h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" v-if="step == 2">
                <accountpreview-component
                  :from="monthData.from"
                  :hideBalances="true"
                  :to="monthData.to"
                ></accountpreview-component>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-card-text>
</template>

<script>
import { store } from "../../store";
import axios from "axios";
import moment from "moment";
import AccountpreviewComponent from "../patials/accountpreviewComponent";

export default {
  name: "BankCashMonthDataComponent",
  components: { AccountpreviewComponent },
  data() {
    return {
      loading: false,
      selectedAccount: null,
      loadingBankCashMonthData: false,
      step: 1,
      currentMonth: 0,
      chartType: "area",
      months: [
        {
          text: "Jan",
          index: 1,
        },
        {
          text: "Feb",
          index: 2,
        },
        {
          text: "Mar",
          index: 3,
        },
        {
          text: "Apr",
          index: 4,
        },
        {
          text: "May",
          index: 5,
        },
        {
          text: "Jun",
          index: 6,
        },
        {
          text: "Jul",
          index: 7,
        },
        {
          text: "Aug",
          index: 8,
        },
        {
          text: "Sep",
          index: 9,
        },
        {
          text: "Oct",
          index: 10,
        },
        {
          text: "Nov",
          index: 11,
        },
        {
          text: "Dec",
          index: 12,
        },
      ],
    };
  },
  watch: {
    monthData() {
      this.currentMonth = Number(this.monthData.month_number);
    },
  },
  computed: {
    chartOptionsSpark() {
      let dataList = [];

      this.monthData.data.forEach((d) => {
        dataList.push(moment(d.entry_date).format("Do MMM, YY"));
      });

      return {
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        fill: {
          opacity: 0.3,
        },
        xaxis: {
          type: "date",
          categories: dataList,
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          y: {
            show: true,
            formatter: function (d) {
              if (d) {
                if (!isNaN(d)) {
                  return d < 0
                    ? store.state.user.user_infor.current_business.currency
                        .symbol +
                        "(" +
                        (Number(d) / -1)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
                        ")"
                    : store.state.user.user_infor.current_business.currency
                        .symbol +
                        Number(d)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                } else {
                  return d;
                }
              } else {
                return 0;
              }
            },
          },
        },
      };
    },

    monthData() {
      return this.$store.state.BankCashMonthData;
    },
    series() {
      let dataList = [];
      this.monthData.data.forEach((d) => {
        dataList.push(d.balance);
      });

      return [
        {
          name: "Cash & Bank Balance",
          data: dataList,
        },
      ];
    },
  },
  methods: {
    selectAccount(account) {
      this.selectedAccount = account;
      this.$store.state.account = account;
      this.$store.state.totalBalance = account.balance;
      this.$store.state.accountId = account.id;

      this.step = 2;
    },
    getMonthData(month) {
      const url =
        "/api/v2/dashboard/month/cash-bank/" +
        store.state.current_year +
        "/" +
        month;

      this.loadingBankCashMonthData = true;
      axios.get(url).then((res) => {
        store.state.BankCashMonthData = res.data;
        store.state.BankCashMonthDataMonthName = res.data.month;
        this.loadingBankCashMonthData = false;
      });
    },
  },
};
</script>

<style scoped></style>