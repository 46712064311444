<template>
<v-row>
  <v-col
      cols="12"
      sm="4"
      v-for="item in items"
      :key="item.id"
  >
    <processed-expense-item @close="$emit('close')" :item="item"></processed-expense-item>
  </v-col>
</v-row>
</template>

<script>

import ProcessedExpenseItem from "../sync/ProcessedExpenseItem";
export default {
  components: {ProcessedExpenseItem},
  props:['items'],
  name: "SearchSyncExpense"
}
</script>

<style scoped>

</style>