<template>
  <v-card
    flat
    class="p-0 text--accent-1 text-center menu-card"
    style="text-decoration: none; border-radius: 25px"
    :to="blank ? '' : route"
    :target="blank ? '_blank' : ''"
    @click="$emit('clicked')"
    :href="blank ? route : ''"
  >
    <v-card-title class="text-center m-0 p-o">
      <v-spacer></v-spacer>
      <v-icon :color="$vuetify.theme.isDark ? 'white' : 'blue'" x-large class="font-weight-light">{{
        icon
      }}</v-icon
      ><sup
        ><v-icon v-if="blank" x-small :color="$vuetify.theme.isDark ? 'white' : 'blue'"
          >mdi-open-in-new</v-icon
        ></sup
      >
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <h4
        class="font-weight-light  m-0"
        :class="$vuetify.theme.isDark ? 'white--text' : 'blue--text'"
      >
        {{ title }}
      </h4>
      {{ desription }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    blank: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "Select",
      type: String,
    },
    desription: {
      default: "",
      type: String,
    },
    icon: {
      default: "",
      type: String,
    },
    route: {
      default: "",
      type: String,
    },
  },
  name: "MenuCard",
};
</script>

<style>
.menu-card {
  transition: 0.3s ease-in-out !important;
}
.menu-card:hover {
  transform: scale(1.1);
  border: 2px solid #2196f3 !important;
}
</style>