<template>
  <span>
    <v-btn
      title="Click to copy"
      @click="copy"
      rounded
      depressed
      x-small
      :icon="icon"
      >{{ label }}<v-icon x-small>mdi-content-copy</v-icon></v-btn
    >
    <textarea style="display: none" :id="'copyInput-' + uuid">{{
      content
    }}</textarea>
    <v-snackbar v-model="success">{{ successMessage }}</v-snackbar>
  </span>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
    },
    label: {
      type: String,
    },
    uuid: {
      type: String,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  name: "copyBottun",
  data() {
    return {
      success: false,
      successMessage: "",
    };
  },
  methods: {
    copy() {
      const input = document.getElementById("copyInput-" + this.uuid);
      navigator.clipboard.writeText(input.textContent).then(() => {
        this.successMessage = "Copied successfully, you can now paste anywhere";
        this.success = true;
      });
    },
  },
};
</script>

<style scoped></style>
