<template>
<span>

      <v-stepper v-model="step" class="pa-0 ma-0" style="box-shadow: none!important;">
   <v-stepper-header>

      <v-stepper-step :color="step>1 ? 'green' : 'blue'" :complete="step>1" complete-icon="mdi-check" step="1">
        Import Customers
      </v-stepper-step>

            <v-divider></v-divider>

      <v-stepper-step :color="step>2 ? 'green' : 'blue'" :complete="step>2" complete-icon="mdi-check" step="2">
        Import Suppliers
      </v-stepper-step>

            <v-divider></v-divider>

      <v-stepper-step :color="step>3 ? 'green' : 'blue'" :complete="step>3" complete-icon="mdi-check" step="3">
        Import Products & Services
      </v-stepper-step>

            <v-divider></v-divider>

  <v-stepper-step
      :color="step>4 ? 'green' : 'blue'"
      :complete="step>4"
      complete-icon="mdi-check"
      step="4"
  >
        Bank Account Balances
      </v-stepper-step>


            <v-divider></v-divider>

  <v-stepper-step :color="finished ? 'green' : 'blue'" :complete="finished" complete-icon="mdi-check" step="5">
        Finish
      </v-stepper-step>



    </v-stepper-header>
        <v-stepper-items>

          <v-stepper-content class="pa-0" step="1">
                        <v-row>
              <v-col cols="12" sm="5">
                <h4 class="font-weight-bold ma-2">Import your customers from QuickBooks</h4>
              </v-col>
              <v-col class="text-right" cols="12" sm="5">
                <v-btn v-if="decodedCustomers && decodedCustomers.length" class="ma-2" color="red"
                       outlined rounded @click="clearAll=true">{{
                    $t("main.delete")
                  }} All <v-icon small>mdi-delete-outline</v-icon></v-btn>
              </v-col>
              <v-col class="text-right" cols="12" sm="2">
          <v-btn :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" rounded text x-large @click="step=2">Skip <v-icon>mdi-arrow-right</v-icon></v-btn>
              </v-col>
            </v-row>



              <v-form ref="customersForm">
        <v-simple-table class="table-bordered">
                     <thead>
              <tr class="bg-built white--text" style="color: white !important;">
                <th class="white--text" style="min-width: 250px">Name</th>
                <th class="white--text" style="min-width: 150px">Phone Number</th>
                <th class="white--text" style="min-width: 150px">Email</th>
                <th class="white--text" style="min-width: 350px">Address</th>
                <th class="white--text" style="min-width: 350px">Unpaid Amount/Credit</th>
                <th class="white--text text-center" style="min-width: 50px">--</th>
              </tr>
              </thead>

                </v-simple-table>
            <v-simple-table v-show="decodedCustomers.length" style="max-height: 80vh!important; overflow: auto">

              <tbody :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-4'">

    <v-virtual-scroll
        :bench="30"
        :items="decodedCustomers"
        height="70vh"
        item-height="60"
        width="93em"
    >

              <template v-slot:default="{ item,index }">

                <tr>
                  <td class="pa-3" style="min-width: 250px">
                    <v-text-field v-model="item.name" :rules="[rules.required]" class="mt-2" dense
                                  flat placeholder="Name"></v-text-field>
                  </td>

                  <td class="pa-3" style="min-width: 150px">
                    <v-text-field v-model="item.phone_number" class="mt-2" dense flat
                                  placeholder="phone number"></v-text-field>
                  </td>

                  <td class="pa-3" style="min-width: 150px">
                    <v-text-field v-model="item.email"
                                  :rules="Boolean(item.email)?emailRules:[]"
                                  class="mt-2"
                                  dense
                                  flat
                                  placeholder="Email"
                                  type="email"></v-text-field>
                  </td>

                  <td class="pa-3" style="min-width: 350px">
                    <v-textarea v-model="item.address" auto-grow class="mt-2" dense flat placeholder="Address"
                                rows="2"></v-textarea>
                  </td>
                  <td class="pa-3" style="min-width: 350px">
                    <v-text-field v-model="item.amount" :prefix="businessCurrency" class="mt-2"
                                  dense flat hint="How much they owe you" persistent-hint placeholder="Debt"
                                  type="number"></v-text-field>
                  </td>
                  <td class="pa-3">
                    <v-btn color="red" icon @click="removeCustomer(index)"><v-icon>mdi-delete-outline</v-icon></v-btn>
                  </td>

                </tr>

                </template>

              </v-virtual-scroll>
              </tbody>
            </v-simple-table>


                              </v-form>


  <v-row dense>
              <v-col cols="12" sm="8">

              </v-col>
              <v-col class="text-right" cols="12" sm="4">
                                  <v-btn :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" outlined text
                                         @click="addCustomerRow"><v-icon>mdi-plus</v-icon> Row</v-btn>

              </v-col>
            </v-row>

            <v-row v-if="decodedCustomers && decodedCustomers.length">
              <v-col cols="12" sm="8">

                <h1 class="ma-2">{{ decodedCustomers.length }} Customers</h1>
              </v-col>
              <v-col cols="12" sm="4">
                <v-btn :loading="loading" block class="mt-5" color="#0D2E57" dark depressed rounded x-large
                       @click="saveCustomers">Save & Continue <v-icon>mdi-arrow-right</v-icon>


                </v-btn>
                  <v-alert v-if="CustomersHaveErrors" class="mt-3" color="red--text" dense
                           style="background-color: rgba(255,82,82,0.27)" type="error">
                  An error was detected in the customers list, please check and fix all errors to continue
                </v-alert>
              </v-col>
            </v-row>


            <div class="pa-6 text-center border mt-3">
              <v-alert class="text-left" color="blue lighten-5 blue--text" prominent>
                <h4>1.Your uploaded data will be shown here for correction and final submission</h4>
                <h4>2.Export your customers in PDF format from your QuickBooks account</h4>
                <h4>3.if you do not want to use the import file you can add empty rows and fill them manually</h4>
              </v-alert>
              <p class="text--disabled">import QuickBooks customers from csv or excel file</p>
              <v-btn :loading="loading" class="ma-3" color="#0D2E57" dark depressed rounded x-large
                     @click="triggerFileSelect">{{ selectedFile ? "Upload another" : "Upload" }} file <v-icon>mdi-arrow-up</v-icon></v-btn>

              <v-file-input
                  id="quickbooks_customers_selector"
                  v-model="selectedFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="d-none" @change="fileSelected"></v-file-input>
            </div>



          </v-stepper-content>




          <v-stepper-content class="pa-0" step="2">
                        <v-row>
                      <v-col cols="1">
                        <v-btn class="ma-2" color="grey" small text @click="step--"><v-icon>mdi-arrow-left</v-icon>Back</v-btn>
                      </v-col>
              <v-col cols="12" sm="5">
                <h4 class="font-weight-bold ma-2">Import your vendors from QuickBooks</h4>
              </v-col>
              <v-col class="text-right" cols="12" sm="4">
                <v-btn v-if="decodedSuppliers && decodedSuppliers.length" class="ma-2" color="red"
                       outlined rounded @click="clearAllSuppliers=true">{{
                    $t("main.delete")
                  }} All <v-icon small>mdi-delete-outline</v-icon></v-btn>
              </v-col>
              <v-col class="text-right" cols="12" sm="2">
<v-btn :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" rounded text x-large @click="step=3">Skip <v-icon>mdi-arrow-right</v-icon></v-btn>
              </v-col>
            </v-row>

            <v-form ref="quickbooksSupplierForm">

                <v-simple-table class="table-bordered">
                     <thead>
              <tr class="bg-built white--text" style="color: white !important;">
                <th class="white--text" style="min-width: 250px">Name</th>
                <th class="white--text" style="min-width: 150px">Phone Number</th>
                <th class="white--text" style="min-width: 150px">Email</th>
                <th class="white--text" style="min-width: 350px">Address</th>
                <th class="white--text" style="min-width: 350px">Unpaid Amount/Credit</th>
                <th class="white--text text-center" style="min-width: 50px">--</th>
              </tr>
              </thead>

                </v-simple-table>
            <v-simple-table v-show="decodedSuppliers && decodedSuppliers.length">

              <tbody :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-4'">
                 <v-virtual-scroll
                     :bench="30"
                     :items="decodedSuppliers"
                     height="70vh"
                     item-height="60"
                     width="93em"
                 >
              <template v-slot:default="{ item,index }">

                <tr :key="index">
                  <td class="pa-3" style="min-width: 250px">
                    <v-text-field v-model="item.name" :rules="[rules.required]" class="mt-2" dense
                                  flat placeholder="Name"></v-text-field>
                  </td>

                  <td class="pa-3" style="min-width: 150px">
                    <v-text-field v-model="item.phone_number" class="mt-2" dense flat
                                  placeholder="phone number"></v-text-field>
                  </td>

                  <td class="pa-3" style="min-width: 150px">
                    <v-text-field v-model="item.email"
                                  :rules="Boolean(item.email)?emailRules:[]"
                                  class="mt-2"
                                  dense
                                  flat
                                  placeholder="Email"
                                  type="email"></v-text-field>
                  </td>

                  <td class="pa-3" style="min-width: 350px">
                    <v-textarea v-model="item.address" auto-grow class="mt-2" dense flat placeholder="Address"
                                rows="2"></v-textarea>
                  </td>
                  <td class="pa-3" style="min-width: 350px">
                    <v-text-field v-model="item.amount" :prefix="businessCurrency" class="mt-2"
                                  dense flat hint="How much you owe them" persistent-hint placeholder="Credit"
                                  type="number"></v-text-field>
                  </td>
                  <td class="pa-3">
                    <v-btn color="red" icon @click="removeSupplier(index)"><v-icon>mdi-delete-outline</v-icon></v-btn>
                  </td>

                </tr>
              </template>
                 </v-virtual-scroll>

              </tbody>


            </v-simple-table>
            </v-form>
              <v-row dense>
              <v-col cols="12" sm="8">

              </v-col>
              <v-col class="text-right" cols="12" sm="4">
                <v-btn :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" outlined text @click="addSupplierRow"><v-icon>mdi-plus</v-icon> Row</v-btn>

              </v-col>
            </v-row>

             <v-row v-if="decodedSuppliers.length">
              <v-col cols="12" sm="8">
                <h1 class="ma-2">{{ decodedSuppliers.length }} suppliers</h1>

              </v-col>
              <v-col cols="12" sm="4">
                <v-btn :loading="loading" block class="mt-5" color="#0D2E57" dark depressed rounded x-large
                       @click="saveSuppliers">Save & Continue <v-icon>mdi-arrow-right</v-icon></v-btn>

                 <v-alert v-if="SuppliersHaveErrors" class="mt-3" color="red--text" dense
                          style="background-color: rgba(255,82,82,0.27)" type="error">
                  An error was detected in the suppliers list, please check and fix all errors to continue
                </v-alert>
              </v-col>
            </v-row>

            <div class="pa-6 text-center border mt-3">
              <v-alert class="text-left" color="blue lighten-5 blue--text" prominent>
                <h4>1.Your uploaded data will be shown here for correction and final submission</h4>
                <h4>2.Export your vendor list in excel from quickbooks and import it to continue</h4>
                <h4>3.if you do not want to use the import file you can add empty rows and fill them manually</h4>
              </v-alert>
              <p class="text--disabled">import quickbooks vendor list from csv or excel file</p>
              <v-btn :loading="loading" class="ma-3" color="#0D2E57" dark depressed rounded x-large
                     @click="triggerSuppliersSelect">{{ selectedSupplierFile ? "Upload another" : "Upload" }} file <v-icon>mdi-arrow-up</v-icon></v-btn>

              <v-file-input
                  id="auickbooks_supplier_selector"
                  v-model="selectedSupplierFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="d-none" @change="suppliersSelected"></v-file-input>
            </div>




          </v-stepper-content>



          <v-stepper-content class="pa-0" step="3">
                        <v-row>
                      <v-col cols="1">
                        <v-btn class="ma-2" color="grey" small text @click="step--"><v-icon>mdi-arrow-left</v-icon>Back</v-btn>
                      </v-col>
              <v-col cols="12" sm="5">
                <h4 class="font-weight-bold ma-2">Import your products & services from QuickBooks</h4>
              </v-col>
              <v-col class="text-right" cols="12" sm="4">
                <v-btn v-if="decodedSuppliers && decodedSuppliers.length" class="ma-2" color="red"
                       outlined rounded @click="clearItems=true">{{
                    $t("main.delete")
                  }} All <v-icon small>mdi-delete-outline</v-icon></v-btn>
              </v-col>
              <v-col class="text-right" cols="12" sm="2">
          <v-btn :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" rounded text x-large @click="step=4">Skip <v-icon>mdi-arrow-right</v-icon></v-btn>
              </v-col>
            </v-row>



              <v-form ref="itemsForm" class="table-responsive">

                <v-simple-table class="table-bordered">
                       <thead>
              <tr class="bg-built white--text" style="color: white !important;">
                <th class="white--text" style="min-width: 250px">Name</th>
                <th class="white--text" style="min-width: 150px">Unit Cost</th>
                <th class="white--text" style="min-width: 150px">Selling Price</th>
                <th class="white--text" style="min-width: 150px">Quantity</th>
                <th class="white--text" style="min-width: 150px">Type</th>
                <th class="white--text" style="min-width: 150px">Track Inventory</th>
                <th class="white--text" style="min-width: 150px">I sell</th>
                <th class="white--text" style="min-width: 150px">I Buy</th>
                <th class="white--text text-center" style="min-width: 50px">--</th>
              </tr>
              </thead>

                </v-simple-table>
            <v-simple-table v-if="decodedItems && decodedItems.length" class="grey lighten-4"
                            style="max-height: 80vh!important; overflow: auto">
                            <tbody>

           <v-virtual-scroll
               :bench="20"
               :items="decodedItems"
               height="70vh"
               item-height="180"
               width="93em"
           >
              <template v-slot:default="{ item,index }">

                <tr :key="index">
                  <td class="pa-3" style="min-width: 250px">
                    <v-text-field v-model="item.name" :rules="[rules.required]" class="mt-2" dense
                                  flat placeholder="Name"></v-text-field>

                    <v-textarea
                        v-model="item.description"
                        auto-grow
                        dense

                        flat
                        label="Description"
                        rows="2"
                    ></v-textarea>
                  </td>

                  <td class="pa-3" style="min-width: 150px">
                    <v-text-field v-model="item.unit_cost"
                                  :prefix="businessCurrency"
                                  class="mt-2"
                                  dense

                                  flat
                                  hint="How much you buy it"
                                  persistent-hint
                                  placeholder="Unit Cost"
                                  type="number"></v-text-field>
                  </td>

                  <td class="pa-3" style="min-width: 150px">
                    <v-text-field v-model="item.selling_price"
                                  :prefix="businessCurrency"
                                  class="mt-2"
                                  dense
                                  flat
                                  hint="How much you sell it"
                                  placeholder="Selling Price"
                                  type="number"></v-text-field>
                  </td>

                  <td class="pa-3" style="min-width: 150px">
                    <v-text-field v-model="item.quantity"
                                  class="mt-2"
                                  dense

                                  flat
                                  placeholder="Quantity"
                                  type="number"
                    ></v-text-field>
                  </td>
                  <td class="pa-3" style="min-width: 150px">
                    <v-select
                        v-model="item.type"
                        :items="['Product','Service','Fixed asset']"
                        :rules="[rules.required]"
                        class="mt-2"
                        dense flat
                    >
                    </v-select>
                  </td>

                      <td class="pa-3" style="min-width: 150px">
                    <v-select
                        v-model="item.track_inventory"
                        :items="['Yes','No']"
                        :rules="[rules.required]"
                        class="mt-2"
                        dense flat
                    >
                    </v-select>
                  </td>

                           <td class="pa-3" style="min-width: 150px">
                    <v-select
                        v-model="item.selling_item"
                        :items="['Yes','No']"
                        :rules="[rules.required]"
                        class="mt-2"
                        dense flat
                    >
                    </v-select>
                  </td>

                            <td class="pa-3" style="min-width: 150px">
                    <v-select
                        v-model="item.buying_item"
                        :items="['Yes','No']"
                        :rules="[rules.required]"
                        class="mt-2"
                        dense flat
                    >
                    </v-select>
                  </td>


                  <td class="pa-3">
                    <v-btn color="red" icon @click="removeItem(index)"><v-icon>mdi-delete-outline</v-icon></v-btn>
                  </td>

                </tr>
              </template>
           </v-virtual-scroll>

              </tbody>
            </v-simple-table>
                              </v-form>

               <v-row dense>
              <v-col cols="12" sm="8">

              </v-col>
              <v-col class="text-right" cols="12" sm="4">
                  <v-btn :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" outlined text @click="addItemRow"><v-icon>mdi-plus</v-icon> Row</v-btn>

              </v-col>
            </v-row>



            <v-row v-if="decodedItems.length">
              <v-col cols="12" sm="8">
                <h1 class="ma-2">{{ decodedItems.length }} Products & Services</h1>
              </v-col>
              <v-col cols="12" sm="4">
                <v-btn :loading="loading" block class="mt-5" color="#0D2E57" dark depressed rounded x-large
                       @click="saveItems">Save & Continue <v-icon>mdi-arrow-right</v-icon></v-btn>

                 <v-alert v-if="ItemsHaveErrors" class="mt-3" color="red--text" dense
                          style="background-color: rgba(255,82,82,0.27)" type="error">
                  An error was detected in the items list, please check and fix all errors to continue
                </v-alert>
              </v-col>
            </v-row>


            <div class="pa-6 text-center border mt-3">
              <v-alert class="text-left" color="blue lighten-5 blue--text" prominent>
                <h4>1.Your uploaded data will be shown here for correction and final submission</h4>
                <h4>2.Export your products and services in excel from QuickBooks and import it to continue </h4>
                <h4>3.if you do not want to use the import file you can add empty rows and fill them manually</h4>
              </v-alert>
              <p class="text--disabled">import supplier from csv or excel file</p>
              <v-btn :loading="loading" class="ma-3" color="#0D2E57" dark depressed rounded x-large
                     @click="triggerItemsSelect">{{ selectedItemsFile ? "Upload another" : "Upload" }} file <v-icon>mdi-arrow-up</v-icon></v-btn>

              <v-file-input
                  id="quickbooks_items_selector"
                  v-model="selectedItemsFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="d-none" @change="ItemsSelected"></v-file-input>
            </div>


          </v-stepper-content>

          <v-stepper-content step="4">


            <v-row>
              <v-col cols="1">
                        <v-btn class="ma-2" color="grey" small text @click="step--"><v-icon>mdi-arrow-left</v-icon>Back</v-btn>
                      </v-col>
                        <v-col cols="12" sm="5">
                <h4 class="font-weight-bold ma-2">List your bank & cash accounts and their balances</h4>
              </v-col>
              <v-col class="text-right" cols="12" sm="4">
                <v-btn v-if="decodedSuppliers && decodedSuppliers.length" class="ma-2" color="red"
                       outlined rounded @click="clearItems=true">{{
                    $t("main.delete")
                  }} All <v-icon small>mdi-delete-outline</v-icon></v-btn>
              </v-col>
              <v-col class="text-right" cols="12" sm="2">
          <v-btn :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" rounded text x-large @click="step=5">Skip <v-icon>mdi-arrow-right</v-icon></v-btn>
              </v-col>
            </v-row>

                   <v-alert class="text-left ma-3" color="blue lighten-5 blue--text" prominent>
                <h3>List your bank accounts and balances to continue</h3>
              </v-alert>

              <v-form v-if="step==4" ref="bankForm" class="table-responsive">

            <v-simple-table class="table-bordered" style="max-height: 80vh!important; overflow: auto">
              <thead>
              <tr class="bg-built white--text" style="color: white !important;">
                <th class="white--text">Account Name</th>
                <th class="white--text">Account Code</th>
                <th class="white--text">Account Balance</th>
                <th class="white--text text-center" style="min-width: 50px">--</th>
              </tr>
              </thead>

              <tbody>

                <tr v-for="(item,index) in bankAccounts" :key="index">
                  <td>
                    <v-text-field
                        v-model="item.name"
                        :rules="[rules.required]"
                        class="mt-2"
                        dense
                        filled
                        flat
                        hint="Name of bank or cash account eg. Mobile Money"
                        persistent-hint
                        placeholder="Name">
                    </v-text-field>

                  </td>
    <td>
                    <v-text-field
                        v-model="item.account_code"
                        class="mt-2"
                        dense
                        filled
                        flat
                        placeholder="Account Code">
                    </v-text-field>

                  </td>

                  <td>
                    <v-text-field v-model="item.balance"
                                  :prefix="businessCurrency"
                                  class="mt-2"
                                  dense
                                  filled
                                  flat
                                  persistent-hint
                                  placeholder="Balance"
                                  type="number"
                    ></v-text-field>
                  </td>

                  <td class="text-center">
                    <v-btn color="red" icon @click="removeBankAccount(index)"><v-icon>mdi-delete-outline</v-icon></v-btn>
                  </td>

                </tr>


              </tbody>

            </v-simple-table>
                              </v-form>
   <v-row>
              <v-col cols="12" sm="8">

              </v-col>
              <v-col class="text-right" cols="12" sm="4">
 <v-btn class="mt-3" :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" block dark depressed x-large outlined
        rounded @click="addBankRow"><v-icon>mdi-plus</v-icon> Bank & cash account</v-btn>
              </v-col>
            </v-row>

            <v-row v-if="bankAccounts.length">
              <v-col cols="12" sm="8">

              </v-col>
              <v-col class="text-center border-top" cols="12" sm="4">
                <v-btn block :loading="loading" class="mt-5" color="#0D2E57" dark depressed rounded x-large
                       @click="saveBanks">Save & Continue <v-icon>mdi-arrow-right</v-icon></v-btn>
              </v-col>
            </v-row>


          </v-stepper-content>


          <v-stepper-content step="5" class="pa-0">
         <v-row v-if="!finished">
              <v-col cols="1" >
                        <v-btn class="ma-2" color="grey" small text @click="step--"><v-icon>mdi-arrow-left</v-icon>Back</v-btn>
                      </v-col>

            </v-row>


            <business-owner-final-step class="mt-5" user-type="quickbooks" v-if="step==5" @back="step--"></business-owner-final-step>
          </v-stepper-content>


        </v-stepper-items>


      </v-stepper>



    <v-dialog v-model="clearItems" width="500">
    <v-card>
      <v-card-title>{{ $t("main.confirm") }}</v-card-title>
      <v-card-text>
        <h3 class="font-weight-light">Do you want delete products and service from the list?</h3>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="error" dark depressed rounded x-large
               @click="decodedItems=[]; clearItems=false; saveItems">Yes,Delete all</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>



  <v-dialog v-model="clearAllSuppliers" width="500">
    <v-card>
      <v-card-title>{{ $t("main.confirm") }}</v-card-title>
      <v-card-text>
        <h3 class="font-weight-light">Do you want delete suppliers from the list?</h3>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="error" dark depressed rounded x-large
               @click="decodedSuppliers=[]; clearAllSuppliers=false;">Yes,Delete all</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    <v-dialog v-model="clearAll" width="500">
    <v-card>
      <v-card-title>{{ $t("main.confirm") }}</v-card-title>
      <v-card-text>
        <h3 class="font-weight-light">Do you want delete customers from the list?</h3>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="error" dark depressed rounded x-large
               @click="decodedCustomers=[]; clearAll=false;">Yes,Delete all</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</span>

</template>

<script>
import axios from "axios";
import AccountantFinishedComponenet from "../accountantFinishedComponenet";
import BusinessOwnerFinalStep from "../BusinessOwnerFinalStep";

export default {
  components: {BusinessOwnerFinalStep, AccountantFinishedComponenet},
  props: ['customers', 'suppliers', 'items', 'defaultBankAccounts','finished'],
  name: "quickBooksWizard",
  data() {
    return {
      clearAll: false,
      loading: false,
      step: 1,
      decodedItems: [],
      decodedSuppliers: [],
      decodedCustomers: [],
      bankAccounts: [],
      CustomersHaveErrors: false,
      selectedSupplierFile: null,
      selectedFile: null,
      clearAllSuppliers: false,
      SuppliersHaveErrors: false,
      ItemsHaveErrors: false,
      selectedItemsFile: null,
      clearItems: false,
      userType: "quickbooks",
      emailRules: [
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
        },
      }
    }
  },
  computed: {

    businessCurrency() {

      return this.$store.state.user.user_infor.current_business ? this.$store.state.user.user_infor.current_business.currency.code : "";

    },
    totalBalance() {

      let sum = 0;
      this.bankAccounts.forEach(account => {
        sum += Number(account.balance);
      })

      return sum;

    }
  },
  methods: {

    removeBankAccount(i) {
      this.bankAccounts.splice(i, 1);
    },
    addBankRow() {

      const account = {
        name: "",
        account_code: "",
        balance: 0
      };
      this.bankAccounts.push(account);

    },

    saveBanks() {

      if (this.$refs.bankForm.validate()) {

        this.loading = true;

        const URL = "/api/onboard/" + this.userType + "/bank-accounts";
        axios.post(URL, {
          accounts: this.bankAccounts
        })
            .then(res => {

              this.step = 5;

              this.loading = false;

            })
            .catch(error => {

            })


      }

    },
    triggerItemsSelect() {
      document.getElementById('quickbooks_items_selector').click();
    },
    ItemsSelected() {
      this.loading = true;
      const formData = new FormData();
      formData.append("items", this.selectedItemsFile);

      axios.post("/api/onboard/decode/quickbooks/items", formData)
          .then(res => {

            this.loading = false;

            const newList = res.data;
            const oldList = this.decodedItems;

            this.decodedItems = [...newList, ...oldList];
            this.$refs.itemsForm.validate();

            this.selectedItemsFile = null;

          })
          .catch(error => {
            this.loading = false;
            this.selectedItemsFile = null;

          })


    },

    saveItems() {

      if (this.$refs.itemsForm.validate()) {

        this.ItemsHaveErrors = false;
        this.loading = true;

        const URL = "/api/onboard/" + this.userType + "/items";
        axios.post(URL, {
          items: this.decodedItems
        })
            .then(res => {


              this.step = 4;

              this.loading = false;

            })
            .catch(error => {

            })


      } else {
        this.ItemsHaveErrors = false;
      }

    },
    addItemRow() {

      const item = {
        name: "",
        unit_cost: 0,
        description: "",
        selling_price: 0,
        quantity: 0,
        type: null,
        track_inventory: 'no',
        selling_item: 'yes',
        buying_item: 'yes'
      };
      this.decodedItems.push(item);

    },
    removeItem(i) {
      this.decodedItems.splice(i, 1);
    },
    saveSuppliers() {

      if (this.$refs.quickbooksSupplierForm.validate()) {
        this.SuppliersHaveErrors = false;

        this.loading = true;
        const URL = "/api/onboard/" + this.userType + "/suppliers";
        axios.post(URL, {
          suppliers: this.decodedSuppliers
        })
            .then(res => {

              this.step = 3;

              this.loading = false;

            })
            .catch(error => {

            })


      } else {
        this.SuppliersHaveErrors = true;
      }

    },
    addSupplierRow() {

      const person = {
        name: "",
        phone_number: "",
        email: "",
        address: "",
        amount: 0
      };
      this.decodedSuppliers.push(person);

    },
    suppliersSelected() {
      this.loading = true;
      const formData = new FormData();
      formData.append("suppliers", this.selectedSupplierFile);

      axios.post("/api/onboard/decode/quickbooks/suppliers", formData)
          .then(res => {

            this.loading = false;

            const newList = res.data;
            const oldList = this.decodedSuppliers;

            this.decodedSuppliers = [...newList, ...oldList];
            this.$refs.supplierForm.validate();

            this.selectedSupplierFile = null;

          })
          .catch(error => {
            this.loading = false;
            this.selectedSupplierFile = null;


          })


    },
    triggerSuppliersSelect() {
      document.getElementById('auickbooks_supplier_selector').click();
    },
    removeSupplier(i) {
      this.decodedSuppliers.splice(i, 1);
    },
    triggerFileSelect() {
      document.getElementById('quickbooks_customers_selector').click();
    },
    removeCustomer(i) {
      this.decodedCustomers.splice(i, 1);
    },
    addCustomerRow() {

      const person = {
        name: "",
        phone_number: "",
        email: "",
        address: "",
        amount: 0
      };
      this.decodedCustomers.push(person);

    },
    fileSelected() {
      this.loading = true;
      const formData = new FormData();
      formData.append("customers", this.selectedFile);

      axios.post("/api/onboard/decode/quickbooks/customers", formData)
          .then(res => {

            this.loading = false;

            const newList = res.data;
            const oldList = this.decodedCustomers;
            this.decodedCustomers = [...newList, ...oldList];

            this.$refs.customersForm.validate();

            this.selectedFile = null;


          })
          .catch(error => {
            this.loading = false;

          })


    },
    saveCustomers() {

      if (this.$refs.customersForm.validate()) {
        this.CustomersHaveErrors = false;

        this.loading = true;

        const URL = "/api/onboard/" + this.userType + "/customers";
        axios.post(URL, {
          customers: this.decodedCustomers
        })
            .then(res => {


              this.step = 2;

              this.loading = false;

            })
            .catch(error => {

            })


      } else {
        this.CustomersHaveErrors = true;
      }

    }


  },
  mounted() {
    if (this.finished){
      this.step=5;
    }
    if (this.customers) {
      this.decodedCustomers = this.customers;
    }

    if (this.suppliers) {
      this.decodedSuppliers = this.suppliers;
    }

    if (this.items) {
      this.decodedItems = this.items;
    }
    if (this.defaultBankAccounts) {
      this.bankAccounts = this.defaultBankAccounts;
    }

  }
}
</script>

<style scoped>

</style>