<template>
  <v-card>
    <v-card-title class="font-weight-light">
      {{ $t("customer.send_customer_transactions") }}
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="5">
          <v-switch
            inset
            v-model="send_tome"
            :label="$t('main.send_email_copy')"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="7">
          <v-switch
            inset
            v-model="send_attachments"
            :label="$t('invoice.send_attachment')"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12">
          <h2 class="font-weight-light">
            {{ $t("main.send_to") }}: {{ invoice.customer.business_name }}
          </h2>
          {{ invoice.customer.business_email }}<br />
        </v-col>
        <v-col cols="12" sm="12">
          <v-form ref="mails_form">
            <v-list>
              <v-list-item v-for="(email, index) in other_mails" :key="index">
                <v-text-field
                  autofocus
                  @keydown.native.tab="add_email"
                  :rules="emailRules"
                  type="email"
                  :placeholder="$t('main.email')"
                  dense
                  outlined
                  v-model="email.address"
                ></v-text-field>

                <v-list-item-action>
                  <v-btn
                    class="mb-4"
                    @click="remove_email(index)"
                    icon
                    color="red"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-btn @click="add_email()" rounded small block text>
                  <v-icon>mdi-plus</v-icon>{{ $t("main.email") }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-form>
        </v-col>
        <v-col cols="12" sm="12">
          <rich-text-editor
            v-model="email_message"
            placeholder="Add an email message"
          ></rich-text-editor>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn
        @click="send_email"
        color="blue darken-4"
        dark
        depressed
        block
        rounded
        x-large
        :loading="progress"
        >{{ $t("main.send") }} {{ $t("main.invoice") }}
      </v-btn>
    </v-card-actions>

    <v-snackbar v-model="error_message" color="error">
      {{ error_msg }}
    </v-snackbar>

    <v-snackbar v-model="success_message">
      {{ success_msg }}
    </v-snackbar>
  </v-card>
</template>

<script>
import RichTextEditor from "../RichTextEditor/RichTextEditor.vue";
export default {
  name: "SendinvoiceMailComponent",
  components: {
    RichTextEditor,
  },
  data() {
    return {
      canSendToSelf: false,
      message: "",
      emailRules: [
        (v) => !!v || this.$t("main.email_required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("main.invalid_email"),
      ],
      otherMails: [],
      send_tome: false,
      send_attachments: false,
      other_mails: [],
      email_message: "",
      progress: false,
      error_message: false,
      error_msg: "",
      success_message: false,
      success_msg: "",
    };
  },
  computed: {
    invoice() {
      return this.$store.state.selected_invoice;
    },
    compact_other_emails() {
      let list = [];

      this.other_mails.forEach((mail) => {
        list.push(mail.address);
      });
      return list;
    },
  },
  methods: {
    send_email() {
      if (!this.invoice.customer.business_email && !this.other_mails.length) {
        this.error_msg = "No emails, add recipient emails";
        this.error_message = true;
      }

      if (this.$refs.mails_form.validate()) {
        this.progress = true;
        let formdata = {
          invoice_id: this.invoice.id,
          send_tome: this.send_tome ? 1 : 0,
          other_emails: this.compact_other_emails.toString(),
          message: this.email_message,
          send_attachments: this.send_attachments,
        };

        axios
          .post("/api/sendinvoice", formdata)
          .then((res) => {
            this.success_msg =
              this.$t("invoice.with_number") +
              this.invoice.invoice_number +
              " " +
              this.$t("quote.was_sent") +
              this.invoice.customer.business_name +
              this.$t("quote.others");
            this.success_message = true;
            this.send_dialog = false;
            this.progress = false;
          })
          .catch(() => {
            this.progress = false;
            // this.error_msg = this.$t("main.mail_send_err");
            // this.error_message = true;
          });
      }
    },
    remove_email(index) {
      this.other_mails.splice(index, 1);
    },
    add_email() {
      this.other_mails.push({ address: "" });
    },
    removeEmail(index) {
      this.otherMails.splice(index, 1);
    },
    addEmail() {
      this.otherMails.push({ address: "" });
    },
    sendEmail() {
      if (!this.userEmail && !this.otherMails.length) {
        this.$emit("errors", "No emails, add recipient emails");
      }

      if (this.$refs.mailForm.validate()) {
        const secondaryMails = this.otherMails.map((mail) => mail.address);
        const data = {
          send_tome: this.canSendToSelf ? 1 : 0,
          other_emails: secondaryMails,
          message: this.message,
        };
        this.$emit("send", data);
      }
    },
  },
};
</script>

<style scoped></style>
