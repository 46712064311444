<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          :to="'/expense/expenses/bills/'+item.id"
          class="border"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/expense/expenses/bills/'+item.id)"
      >
        <v-list-item-content>
          <v-list-item-title>{{item.bill_id}}</v-list-item-title>
          <v-list-item-subtitle>Amount Due:{{item.fx_currency}}{{item.amount_left | toMoney}}</v-list-item-subtitle>
          <v-list-item-subtitle>Amount Paid:{{item.fx_currency}}{{item.amount_paid | toMoney}}</v-list-item-subtitle>
          <v-list-item-subtitle>From:{{item.supplier.supplier_name}}</v-list-item-subtitle>
          <v-list-item-subtitle>Issue Date:{{item.purchase_date | humanDate}}</v-list-item-subtitle>
          <v-list-item-subtitle>Due Date:{{item.due_date | humanDate}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-col>
    <v-col cols="12" sm="4">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
              large
              text
              rounded
              color="blue"
              to="/expense/expenses"
              block
              @click="$emit('close'); $router.push('/expense/expenses')"
          >Go to Bills <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
export default {
  props:['items'],
  name: "SearchBills"
}
</script>

<style scoped>

</style>