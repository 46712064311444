<template>
	<v-row>
		<v-col cols="4" class="mx-auto text-center">
			<center>
				<v-img width="100" src="/img/icons/fireworks.svg"></v-img>
			</center>
			<h2>{{ title }}</h2>
			<small class="text-muted">{{ description }}</small>
			<div class="mt-5" v-if="btnText">
				<v-btn dark color="blue darken-4" x-large rounded :to="btnTo" @click="$emit('buttonClicked')">{{
					btnText }}</v-btn>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	emits: ["buttonClicked"],
	props: {
		title: {
			type: String,
			default: "No Transactions Yet",
		},
		description: {
			type: String,
			default:
				"There are no transactions yet, click on the New + button to add new records",
		},
		btnText: {
			type: String
		},
		btnTo: {
			type: String
		}
	},
	name: "norecordsComponent",
};
</script>

<style scoped></style>
