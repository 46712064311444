<template>

  <div>

    <v-row v-if="promotions.length>0">
      <v-col cols="12">
        <v-card outlined rounded color="green lighten-5" dark>
          <v-card-text class="text-center green--text">
            <h2 class="font-weight-black">
              Take advantage of our discounts on subscriptions.
            </h2>
            As shown below, you stand a chance of cutting down cost of subscription depending
            on the billing cycle selected.

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-for="promotion in promotions" :key="promotion.id">
      <v-col cols="12">

        <vs-card class="promo-card" type="1" style="width: 100%">
          <template #title>

            <h1 class="font-weight-light white--text display-3 text-center w-100" style="width: 100%">
              <sup>
                <v-icon color="white">mdi-tag-minus-outline</v-icon>
              </sup>{{ promotion.discount }}% - OFF

            </h1>

          </template>
          <!--          <template #img>-->
          <!--            <img height="300" width="100" src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/5b17a6869e8c8b2cc8075e4d_discount-buy-2-get-1_850.png" alt="">-->
          <!--          </template>-->
          <template #text>
            <h3>
              Billing Cycle: {{ promotion.months }} Months
            </h3>
          </template>
          <template #interactions>

            <vs-button class="btn-chat" shadow primary>
              <i class='bx bx-chat'></i>
              <span class="span">
          Feb, 2023
        </span>
            </vs-button>
          </template>
        </vs-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['promotions'],
  name: "SubcrpitonPromotions"
}
</script>

<style>
.promo-card div.vs-card {
  max-width: 100% !important;
  width: 100% !important;
  text-align: center !important;
  background: #31ce36 !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #179d08, #31ce36) !important;
  background: linear-gradient(-45deg, #179d08, #31ce36) !important;
  color: #ffffff !important;

}

</style>
