import { render, staticRenderFns } from "./accountMigration.vue?vue&type=template&id=c7ab128e&scoped=true"
import script from "./accountMigration.vue?vue&type=script&lang=js"
export * from "./accountMigration.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7ab128e",
  null
  
)

export default component.exports