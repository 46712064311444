import { render, staticRenderFns } from "./upgradeComponent.vue?vue&type=template&id=62cdf712&scoped=true"
import script from "./upgradeComponent.vue?vue&type=script&lang=js"
export * from "./upgradeComponent.vue?vue&type=script&lang=js"
import style0 from "./upgradeComponent.vue?vue&type=style&index=0&id=62cdf712&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62cdf712",
  null
  
)

export default component.exports