<template>
<v-row dense>
    <v-col cols="4">
        <v-card flat rounded tile class="text-center blue lighten-5 ">
            
            <v-card-text>
                <strong class="blue--text">{{income | short_number}}</strong>
            </v-card-text>
        </v-card>
    </v-col>

     <v-col cols="4">
        <v-card flat rounded tile class="text-center">
            <v-card-text>
                <strong>{{expense | short_number}}</strong>
            </v-card-text>
        </v-card>
    </v-col>

     <v-col cols="4">
        <v-card flat rounded tile class="text-center">
            <v-card-text>
                <strong>{{profit | short_number}}</strong>
            </v-card-text>
        </v-card>
    </v-col>
</v-row>
    
</template>
<script>
export default {
    props:['income','expense','profit'],
    name:"MobilePNL",
   
}
</script>