import { render, staticRenderFns } from "./shortcutMenuComponent.vue?vue&type=template&id=5049de42&scoped=true"
import script from "./shortcutMenuComponent.vue?vue&type=script&lang=js"
export * from "./shortcutMenuComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5049de42",
  null
  
)

export default component.exports