<template>
  <span>

  <v-card flat color="green lighten-5 blue--text" v-if="showStart">
    <v-card-text>

      <v-row dense>
        <v-col cols="12" sm="4">

          <v-img src="/img/uploadSL.svg"></v-img>
        </v-col>

        <v-col cols="12" sm="8">
          <h2 class="font-weight-black green--text">Upload your data!</h2>
          <p class="green--text">
           If you have downloaded your data from your old business, you can upload them here
          </p>
          <v-btn @click="showDialog=true" depressed color="green darken-4" dark large rounded block>Upload now<v-icon>mdi-upload-outline</v-icon></v-btn>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>

      <v-card v-else>
        <v-card-title class="font-weight-light">
          Import data <v-spacer></v-spacer>
          <v-btn color="red lighten-5 red--text" rounded depressed small @click="$emit('closed'); step=1;">close<v-icon>mdi-close</v-icon></v-btn>

        </v-card-title>
        <v-card-text>

          <v-stepper v-model="step" class="p-0" style="box-shadow: none">
            <v-stepper-items class="p-0">
              <v-stepper-content step="1" class="p-0">
                <v-alert color="green lighten-5 green--text">

                 <h2 class="font-weight-light">Please import the products and services your downloaded from the old account</h2>

                </v-alert>

                <v-autocomplete
                    outlined
                    label="Select item category"
                    item-text="name"
                    item-value="id"
                    :items="categories"
                    auto-select-first
                    v-model="category_id"
                ></v-autocomplete>

                <v-file-input
                    v-if="category_id"
                    label="Select products & service spreadsheet file"
                    v-model="file"
                    outlined
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    readonly=""
                ></v-file-input>


                <v-card-actions>

                <v-btn @click="importItems" :loading="progress" color="green" v-show="category_id && file" rounded large
                       depressed dark>Upload <v-icon>mdi-upload-outline</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="gray" @click="step=2">skip</v-btn>


                </v-card-actions>

              </v-stepper-content>

              <v-stepper-content step="2" class="pa-0">
                <v-alert color="green lighten-5 green--text">

                 <h2 class="font-weight-light">Next, import the customers you downloaded from the old account</h2>

                </v-alert>


                              <v-autocomplete
                                  outlined
                                  label="Select customer category"
                                  item-text="name"
                                  item-value="id"
                                  :items="clients_categories"
                                  auto-select-first
                                  v-model="clients_category_id"
                              ></v-autocomplete>

                <v-file-input
                    v-if="clients_category_id"
                    label="Select customers spreadsheet file"
                    v-model="clientsFile"
                    outlined
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    readonly=""
                ></v-file-input>



                    <v-card-actions>

                <v-btn @click="importCustomers" :loading="progress" color="green" v-show="clients_category_id && clientsFile" rounded large
                       depressed dark>Upload <v-icon>mdi-upload-outline</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="gray" @click="step=1">back</v-btn>
                <v-btn text color="gray" @click="step=3">skip</v-btn>


                </v-card-actions>

              </v-stepper-content>


              <v-stepper-content step="3" class="pa-0">

                <v-alert color="green lighten-5 green--text">

                 <h2 class="font-weight-light">Next,import the suppliers your downloaded from the old account</h2>

                </v-alert>

                <v-file-input
                    label="Select suppliers spreadsheet file"
                    v-model="suppliersFile"
                    outlined
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    readonly=""
                ></v-file-input>

                    <v-card-actions>

                <v-btn @click="importSuppliers" :loading="progress" color="green" v-show="suppliersFile" rounded large
                       depressed dark>Upload <v-icon>mdi-upload-outline</v-icon></v-btn>

                      <v-spacer></v-spacer>

                <v-btn text color="gray" @click="step=2"><v-icon>mdi-arrow-left</v-icon>back</v-btn>
                <v-btn text color="gray" @click="step=4">skip<v-icon>mdi-arrow-right</v-icon></v-btn>


                </v-card-actions>

              </v-stepper-content>

              <v-stepper-content step="4">
                  <v-alert color="green lighten-5 green--text">

                 <h2 class="font-weight-light">Finally, import the accounts you downloaded from the old account</h2>

                </v-alert>

                  <v-file-input
                      label="Select other accounts spreadsheet file"
                      v-model="accountsFile"
                      outlined
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      readonly=""
                  ></v-file-input>



                  <v-btn @click="importAccounts" :loading="progress" color="green" v-show="accountsFile" rounded large
                         depressed dark>Upload <v-icon>mdi-upload-outline</v-icon></v-btn>

              </v-stepper-content>
              <v-stepper-content step="5">

                  <v-alert color="green lighten-5 green--text">
                    <h1>Import complete!</h1>
                    <p>You have successfully migrated your data to the new denomination</p>
                  </v-alert>

                <v-btn color="green" block depressed dark x-large @click="done">Done</v-btn>

              </v-stepper-content>



            </v-stepper-items>
          </v-stepper>


        </v-card-text>
      </v-card>


    <v-dialog persistent scrollable v-model="showDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title class="font-weight-light">Import data

        <v-spacer></v-spacer>
          <v-btn color="red lighten-5 red--text" rounded depressed small @click="$emit('closed'); step=1;">close<v-icon>mdi-close</v-icon></v-btn>

        </v-card-title>
        <v-card-text>

          <v-stepper v-model="step" class="p-0" style="box-shadow: none">
            <v-stepper-items class="p-0">
              <v-stepper-content step="1" class="p-0">
                <v-alert color="green lighten-5 green--text">

                 <h2 class="font-weight-light">Let's begin by importing your products and service!</h2>

                </v-alert>

                <v-autocomplete
                    outlined
                    label="Select item category"
                    item-text="name"
                    item-value="id"
                    :items="categories"
                    auto-select-first
                    v-model="category_id"
                ></v-autocomplete>

                <v-file-input
                    v-if="category_id"
                    label="Select products & service spreadsheet file"
                    v-model="file"
                    outlined
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    readonly=""
                ></v-file-input>


                <v-card-actions>

                <v-btn @click="importItems" :loading="progress" color="green" v-show="category_id && file" rounded large
                       depressed dark>Upload <v-icon>mdi-upload-outline</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="gray" @click="step=2">skip</v-btn>


                </v-card-actions>

              </v-stepper-content>

              <v-stepper-content step="2" class="pa-0">
                <v-alert color="green lighten-5 green--text">

                 <h2 class="font-weight-light">Now let's upload your customers!</h2>

                </v-alert>


                              <v-autocomplete
                                  outlined
                                  label="Select customer category"
                                  item-text="name"
                                  item-value="id"
                                  :items="clients_categories"
                                  auto-select-first
                                  v-model="clients_category_id"
                              ></v-autocomplete>

                <v-file-input
                    v-if="clients_category_id"
                    label="Select customers spreadsheet file"
                    v-model="clientsFile"
                    outlined
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    readonly=""
                ></v-file-input>



                    <v-card-actions>

                <v-btn @click="importCustomers" :loading="progress" color="green" v-show="clients_category_id && clientsFile" rounded large
                       depressed dark>Upload <v-icon>mdi-upload-outline</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="gray" @click="step=1">back</v-btn>
                <v-btn text color="gray" @click="step=3">skip</v-btn>


                </v-card-actions>

              </v-stepper-content>


              <v-stepper-content step="3" class="pa-0">

                <v-alert color="green lighten-5 green--text">

                 <h2 class="font-weight-light">Next, we import your suppliers!</h2>

                </v-alert>

                <v-file-input
                    label="Select suppliers spreadsheet file"
                    v-model="suppliersFile"
                    outlined
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    readonly=""
                ></v-file-input>

                    <v-card-actions>

                <v-btn @click="importSuppliers" :loading="progress" color="green" v-show="suppliersFile" rounded large
                       depressed dark>Upload <v-icon>mdi-upload-outline</v-icon></v-btn>

                      <v-spacer></v-spacer>

                <v-btn text color="gray" @click="step=2"><v-icon>mdi-arrow-left</v-icon>back</v-btn>
                <v-btn text color="gray" @click="step=4">skip<v-icon>mdi-arrow-right</v-icon></v-btn>


                </v-card-actions>

              </v-stepper-content>

              <v-stepper-content step="4">
                  <v-alert color="green lighten-5 green--text">

                 <h2 class="font-weight-light">Lastly, let's import any other accounts and their balances</h2>

                </v-alert>

                  <v-file-input
                      label="Select other accounts spreadsheet file"
                      v-model="accountsFile"
                      outlined
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      readonly=""
                  ></v-file-input>



                  <v-btn @click="importAccounts" :loading="progress" color="green" v-show="accountsFile" rounded large
                         depressed dark>Upload <v-icon>mdi-upload-outline</v-icon></v-btn>

              </v-stepper-content>



            </v-stepper-items>

                <v-stepper-content step="5">

                  <v-alert color="green lighten-5 green--text">
                    <h1>Import complete!</h1>
                    <p>You have successfully migrated your data to the new denomination</p>
                  </v-alert>

                <v-btn color="green" block depressed dark x-large @click="done">Done</v-btn>

              </v-stepper-content>

          </v-stepper>


        </v-card-text>
      </v-card>

    </v-dialog>
  </span>
</template>

<script>
import axios from "axios";

export default {
  name: "MoveIn",
  props:{
    showStart:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      showDialog: false,
      step: 1,
      categories: [],
      clients_categories: [],
      category_id: null,
      clients_category_id: null,
      file: null,
      progress: false,
      clientsFile: null,
      suppliersFile:null,
      accountsFile:null
    }
  },
  methods: {
    done(){
      localStorage.setItem("HideSLImportBanner",'true');
      location.reload();

    },

    importAccounts(){
      this.progress=true;
      const formData = new FormData();
      formData.append("accounts",this.accountsFile);

      axios.post("/api/other-accounts/import",formData)
          .then(res=>{
            this.progress=false;
            this.step=5;
          })

    },
    importSuppliers(){

      this.progress=true;

      const formData = new FormData();
      formData.append('suppliers',this.suppliersFile);

      axios.post('/api/importsuppliers?skip_entry=true',formData)
          .then(res=>{

            this.step=4
            this.progress=false;

          })

    },
    importCustomers(){
      this.progress=true;
      const formData = new FormData();
      formData.append('customers', this.clientsFile);
      formData.append('category_id', this.clients_category_id);
      axios.post('/api/importcustomers?skip_entry=true', formData)
            .then(res=>{

              this.step=3;
              this.progress=false;


            })
    },
    importItems() {
      this.progress = true;
      const formData = new FormData();
      formData.append("items", this.file);
      formData.append("category_id", this.category_id);
      axios.post('/api/importitems?skip_entry=true', formData)
          .then(res => {
            this.step = 2;
            this.progress = false;

          })

    },
    getCategories() {
      axios.get("/api/itemcategories")
          .then(res => {
            this.categories = res.data;
          })
    },

    getClientCategories() {
      axios.get("/api/clients/categories")
          .then(res => {
            this.clients_categories = res.data;
          })
    }


  },
  computed: {
    business() {
      return this.$store.state.user.user_infor.current_business;
    }

  },
  mounted() {

    this.getCategories();
    this.getClientCategories();

  }
}
</script>
<style scoped>

</style>