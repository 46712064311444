<template>
  <v-tour name="InvoiceExtraHeaders" :steps="steps">

    <template slot-scope="tour">
      <transition name="fade">
        <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            class="blue"
        >
          <template>
            <div slot="actions">
              <v-btn @click="tour.stop(); turnOff() " text small dark>Okay, Got It</v-btn>
            </div>
          </template>
        </v-step>
      </transition>
    </template>

  </v-tour>

</template>

<script>
export default {
  name: "InvoiceExtraHeaders",

  data () {
    return {


      steps: [
        {
          target: '#InvoiceExtraHeaders',
          content: `Add more header information to your invoice`
        },

      ]
    }
  },
  methods:{
    turnOff(){
      localStorage.setItem('InvoiceExtraHeaders',true);
    }
  },
  mounted () {
    if (!localStorage.InvoiceExtraHeaders) {

      this.$tours['InvoiceExtraHeaders'].start()
    }
  }

}
</script>

<style scoped>

</style>