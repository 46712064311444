<template>

    <div class="card col-md-9 mx-auto" :class="$vuetify.theme.isDark ? 'bg-black' : 'bg-white'">

        <v-card flat class="border-bottom" v-if="$store.state.selected_invoice.is_recuring">
            <v-card-text>
                <v-list-item class="mb-3 border">
                    <v-list-item-icon>
                        <v-chip
                                v-if="$store.state.selected_invoice.schedule_plan.status==='active'"
                                color="green lighten-5"
                                class="green--text"
                        >
                            {{$t('invoice.active')}} <v-icon>mdi-check</v-icon>
                        </v-chip>

                        <v-chip
                                v-else
                                color="red lighten-5"
                                class="red--text"
                        >
                            {{$t('invoice.inactive')}} <v-icon>mdi-close</v-icon>
                        </v-chip>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title><h2 class="font-weight-light">{{$t('invoice.schedule_info')}}</h2></v-list-item-title>
                        <v-list-item-subtitle>
                            <strong>{{$t('invoice.frequency')}}: </strong> {{$t('invoice.every')}} {{$store.state.selected_invoice.schedule_plan.frequency}} {{$store.state.selected_invoice.schedule_plan.frequency_type}} &nbsp; | &nbsp;
                            {{$store.state.selected_invoice.schedule_plan.limit>0 ? $store.state.selected_invoice.schedule_plan.limit : $t('invoice.unlimited') }} {{$t('invoice.times')}}
                            &nbsp; | &nbsp; <strong>{{$t('invoice.date_scheduled')}}: </strong> {{$store.state.selected_invoice.schedule_plan.created_at}}
                           <br> &nbsp; | &nbsp; <strong>{{$t('invoice.next_date_scheduled')}}: </strong> {{$store.state.selected_invoice.schedule_plan.next_dispatch_date}}
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="$store.state.selected_invoice.schedule_plan.status==='active'">
                        <v-btn @click="deactivate_dialog=true" color="red" dark >{{$t('invoice.deactivate')}}</v-btn>
                    </v-list-item-action>
                </v-list-item>

                <v-alert  :color="$store.state.selected_invoice.invoice_color" dark>

                    <h2 class="font-weight-bold">{{$t('main.note')}}:</h2>
                    <li>{{$t('invoice.recurring_note1')}}</li>
                    <li>{{$t('invoice.recurring_note2')}}</li>
                    <li>{{$t('invoice.recurring_note3')}}</li>
                </v-alert>


            </v-card-text>
        </v-card>


        <div class="card-header" v-if="invoices.length">
            <h2 class="font-weight-light">{{invoices.length}} {{$t('invoice.recurred_copies')}}</h2>
        </div>
        <div class="card-body" v-if="invoices.length">
            <v-timeline
                    dense
                    clipped
            >

                <v-timeline-item
                        class="mb-4 border-bottom hover"
                        :color="$store.state.selected_invoice.invoice_color"
                        small
                        v-for="(invoice,i) in invoices"
                        :key="i"


                >
                    <v-row justify="space-between">
                        <v-col cols="12"  sm="10" >
                            <h2 class="font-weight-light">{{invoice.invoice_number}}</h2>
                            {{invoice.created_at}} <br>

                        </v-col>

                        <v-col cols="12" sm="2">
                          <v-spacer></v-spacer>
                            <v-btn target="_blank" :to="'/income/invoices/'+invoice.child_id" :color="$store.state.selected_invoice.invoice_color" dark small rounded depressed> {{$t('main.preview')}}</v-btn>

                        </v-col>

                    </v-row>
                </v-timeline-item>




            </v-timeline>


        </div>

        <v-dialog v-model="deactivate_dialog" width="500">
            <v-card>
                <v-card-title>{{$t('invoice.confirm_deactivate')}} ({{$store.state.selected_invoice.invoice_number}})</v-card-title>
                <v-card-text>
                    <h3 class="font-weight-light">{{$t('invoice.schedule_warning')}}</h3>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="deactivate_schedule" :loading="deactivating" text color="red">{{$t('invoice.deactivate_schedule')}}</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>


</template>

<script>
    export default {
        name: "childrenComponent",
        data(){
          return{
              finished:false,
              invoices:[],
              deactivate_dialog:false,
              deactivating:false,
          }
        },
        methods:{
            deactivate_schedule(){
              this.deactivating=true;
              axios.delete(`/api/invoice/deactivateschedule/${this.$store.state.selected_invoice.schedule_plan.id}`)
                .then(res=>{
                    axios.get(`/api/invoice/${this.$store.state.selected_invoice.id}`)
                        .then(res=>{
                            this.$store.state.selected_invoice = res.data;
                            this.deactivating=false;
                            this.deactivate_dialog=false;
                        });
                })
            },
            getChildren(){

                if(this.$store.state.selected_invoice.children){

                    axios.get(`/api/invoice/children/${this.$store.state.selected_invoice.id}`)

                    .then(res=>{
                        this.invoices=res.data;
                        this.finished = true;

                    })
                }
            }
        },
        mounted() {
            this.getChildren();
        }
    }
</script>

<style scoped>


</style>