<template>
  <v-row>


    <v-col cols="12" sm="2">
      <v-card :loading="$store.state.loading" flat>
        <v-card-text class="text-right">
          <p class="font-weight-light mb-2">Transactions</p>
          <h3 class="font-weight-bold" style="font-size: 1.2rem">
            {{
              results.count
            }}
          </h3>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="3">
      <v-card :loading="loading" flat>
        <v-card-text class="text-right">
          <p class="font-weight-light mb-2 blue--text">Total Debits</p>
          <h3 class="font-weight-bold" style="font-size: 1.2rem">
            {{ results.total_debits | toMoney | currency_symbol }}
          </h3>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="3">
      <v-card :loading="loading" flat>
        <v-card-text class="text-right">
          <p class="font-weight-light mb-2 red--text">Total Credits</p>
          <h3 class="font-weight-bold" style="font-size: 1.2rem">
            {{ results.total_credits | toMoney | currency_symbol }}

          </h3>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="3">
      <v-card :loading="$store.state.loading" flat>
        <v-card-text class="text-right">
          <p class="font-weight-light mb-2">Balance (<small>{{ filter ? "Per filter" : "Total" }}</small>)</p>
          <h3 v-if="$store.state.accountTransactions.data && $store.state.accountTransactions.data.length"
              class="font-weight-bold" style="font-size: 1.2rem">

            {{ $store.state.accountTransactions.data[0].balance | absolute | currency_symbol }}

          </h3>
          <h3 v-else>
            {{ $store.state.account.currency }} 0.00
          </h3>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>

import axios from "axios";

export default {
  name: "AccountSummariesComponent",
  props: {
    filter:{
      type:Boolean,
      default:false
    },
    account_id: {
      type: Number
    },
    fromDate: {
      type: String,
      default: null
    },
    toDate: {
      type: String,
      default: null
    },
    filter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      results: {
        total_debits: 0,
        total_credits: 0,
        count: 0
      }
    }
  },

  watch: {
    account_id() {

      this.getSummaries();
    },
    fromDate() {
      this.getSummaries();
    },
    toDate() {
      this.getSummaries();
    }
  },
  methods: {
    getSummaries() {
      if (this.account_id) {
        this.loading = true;

        let URL = "/api/accounts/" + this.account_id + "/summaries";

        if (this.fromDate && this.toDate) {

          URL = "/api/accounts/" + this.account_id + "/summaries?from=" + this.fromDate + "&to=" + this.toDate;

        }

        axios.get(URL)
            .then(res => {
              this.results = res.data;
              this.loading = false;
            })


      }
    }
  },
  mounted() {
    this.getSummaries();
  }
}
</script>

<style scoped>

</style>