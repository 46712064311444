<template>
  <v-expansion-panels class="rounded" flat hover inset multiple style="border-radius: 15px">

    <v-expansion-panel style="border-radius: 15px;">
      <v-expansion-panel-header>
        <h4 v-if="plan.toLowerCase()=='enterprise'" class="font-weight-black">
          <v-icon>mdi-account-multiple-outline</v-icon>
          Add more employees to your payroll
          (Current Limit {{ totalEmployees }})
        </h4>
        <h4 v-else class="text-h5 font-weight-light">Unlimited employees</h4>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-2" v-if="plan.toLowerCase()=='enterprise'">
        <h5 class="text-h6 font-weight-light">Current number of employees:{{currentEmployeesCount}}</h5>
        <h5 class="mt-0">Each extra employee would cost : {{ currency }} {{ baseCost | toMoney }}/Month</h5>
        <h5 v-if="extendedEmployeesNumber>0" class="green--text mt-0">Cost for extra {{ extendedEmployeesNumber }}
          employees per month : {{ currency }} {{ totalExtraCost | toMoney }}</h5>
        <h3 v-if="extendedEmployeesNumber>0" class="green--text mt-0">Amount due({{ cycle }} Months) : {{ currency }}
          {{ totalAmountDue | toMoney }}</h3>
        <v-text-field v-model="extendedEmployeesNumber" color="green" label="Add more employees" min="0" outlined
                      type="number" @input="employeesChanged"></v-text-field>


      </v-expansion-panel-content>

      <v-expansion-panel-content v-else class="text-center">

        <h4 class="text-h6 font-weight-light">
          Manage and process payroll for unlimited number of employees
        </h4>

      </v-expansion-panel-content>
    </v-expansion-panel>


    <v-expansion-panel class="mt-3" v-if="plan.toLowerCase()!=='enterprise'" style="border-radius: 15px;">
      <v-expansion-panel-header>
        <h4 class="font-weight-black">
          <v-icon>mdi-clock-outline</v-icon>
          Add onsite support | Extra Cost:{{ currency }} {{ totalCostPerExtraHours | toMoney }}
        </h4>
      </v-expansion-panel-header>

      <v-expansion-panel-content>

        <h4 class="pa-3 text-h5 green--text">Amount Due: {{ currency }} {{ totalCostPerExtraHours | toMoney }}</h4>
        <h5 class="text--disabled">Amount per hour: {{ currency }} {{ amountPerHours | toMoney }}</h5>
        <h5 class="text--disabled">Duration:  {{ cycle }} Months</h5>

        <v-select
            v-model="hours"
            :items="hoursList"
            clearable
            color="green"
            item-color="green"
            label="Hourly visits per day"
            min="0"
            outlined
            type="number"
            @change="visitsChanged"
        ></v-select>

        <v-select v-model="weekVisits" :items="weeks" color="green" item-color="green" label="Daily Visits Per week"
                  outlined
                  @change="visitsChanged"></v-select>


        <v-autocomplete v-model="monthVisits" :items="months" color="green" item-color="green" label="Weekly Visits per month"
                        outlined
                        @change="visitsChanged"></v-autocomplete>


      </v-expansion-panel-content>

    </v-expansion-panel>

  </v-expansion-panels>

</template>

<script>
export default {
  name: "SubscriptionExtensionComponent",
  props: {
    currentEmployeesCount: {
      type: Number,
      default: 0
    },
    costPerHour: {
      type: Number,
      default: 0
    },
    plan: {
      type: String,
      default: "Enterprise"
    },
    cycle: {
      type: Number,
      default: 3
    },
    currency: {
      type: String,
      default: "GHS"
    },
    rate: {
      type: Number,
      default: 1
    },
    unit_cost: {
      type: Number,
      default: 0
    },

  },
  data() {
    return {
      defaultEmployeeLimit: 5,
      extendedEmployeesNumber: 0,
      weekVisits: 1,
      monthVisits: 1,
      hours: 0,
      weeks: [
        {
          value: 1,
          text: "One visit per Week"
        },
        {
          value: 2,
          text: "Two visits per Week"
        },
        {
          value: 3,
          text: "Three visits per Week"
        },
        {
          value: 4,
          text: "Four visits per Week"
        }

      ]
    }
  },
  computed: {

    finalLimit(){
      if (this.currentEmployeesCount>this.defaultEmployeeLimit){
        return 0;
      }

      return this.defaultEmployeeLimit-this.currentEmployeesCount;

    },

    amountPerHours() {
      return Number(this.costPerHour) * Number(this.rate);
    },
    totalCostPerExtraHours() {

      const total = (Number(this.hours) * Number(this.monthVisits) * Number(this.weekVisits)) * Number(this.amountPerHours);

      return total*this.cycle;

    },

    hoursList() {
      let list = [];
      for (let i = 1; i <= 5; i++) {
        list.push({
          value: i,
          text: i + " Hours daily"
        });


      }

      return list;
    },

    months() {

      let list = [];
      for (let i = 1; i <= 4; i++) {

        list.push({
          value: i,
          text: i + " Times"
        });

      }
      return list;

    },
    weeklyVisitFrequency() {
      let i = 1;
      let list = [];
      for (i = 1; i <= 36; i++) {
        if (i == 1) {
          list.push({
            value: i,
            text: i + " Time"
          });
        } else {
          list.push({
            value: i,
            text: i + " Times"
          });
        }

      }

      return list;

    },
    totalEmployees() {
      return Number(this.extendedEmployeesNumber) + this.finalLimit;
    },
    baseCost() {
      return this.rate * this.unit_cost;
    },
    totalExtraCost() {

      return Number(this.extendedEmployeesNumber) * Number(this.baseCost);

    },
    totalAmountDue() {
      return Number(this.totalExtraCost) * Number(this.cycle);
    }

  },
  methods: {

    visitsChanged() {

      const data = {
        amount: this.totalCostPerExtraHours,
        hours: this.hours,
        visits_per_week: this.weekVisits,
        visits_per_month: this.monthVisits
      };

      this.$emit("visitsChanged", data);

    },
    employeesChanged() {
      const data = {
        amount: this.totalAmountDue,
        defaultNumber: 5,
        extraNumber: Number(this.extendedEmployeesNumber),
        totalNumber: this.totalEmployees
      };

      this.$emit("employeeChanged", data);
    }


  },
  mounted() {
  }
}
</script>

<style scoped>

</style>