<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          :to="'/payroll/employee/'+item.id"
          class="border"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/payroll/employee/'+item.id)"
      >
        <v-list-item-avatar><v-img src="/img/photo.png"></v-img></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{item.first_name}} {{item.last_name}}</v-list-item-title>
          <v-list-item-subtitle v-if="item.phone_number1">Phone Number:{{item.phone_number1}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-col>
    <v-col cols="12" sm="4">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
              large
              text
              rounded
              color="blue"
              to="/payroll/employees"
              block
              @click="$emit('close'); $router.push('/payroll/employees')"
          >Go to Employees <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>

</template>

<script>
export default {
  props:['items'],
  name: "SearchEmployees"
}
</script>

<style scoped>

</style>