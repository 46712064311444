<template>
  <span>

<v-alert v-if="business.currency.symbol==='NLe' && importBannerVisible" class="green lighten-5 green--text">
  <v-row>
    <v-col cols="12" sm="2">
          <v-img src="/img/uploadSL.svg"></v-img>

    </v-col>
    <v-col cols="12" sm="8">
      <h1 class="font-weight-light">Hello, {{business.name}} let's help you move your data from your old denominated business to your new business.</h1>

      <p>
        Please make sure you have downloaded all your data from your old account.<br>
        To begin, click on the import button.<br>
        If you have already done this, please dismiss this banner.
      </p>
    </v-col>
    <v-col cols="2">
      <v-btn @click="showImportDialog=true" block color="green" rounded x-large depressed dark outlined>Import <v-icon>mdi-upload-outline</v-icon></v-btn>
      <v-btn @click="dismiss()" small class="mt-3" block color="red" rounded depressed dark text>Already done, dismiss <v-icon>mdi-close</v-icon></v-btn>
    </v-col>
  </v-row>

</v-alert>

    <v-alert color="blue lighten-5 blue--text" v-else-if="business.currency.symbol==='Le' && importBannerVisible">
  <v-row>
    <v-col cols="12" sm="2">
          <v-img src="/img/migrate_export.svg"></v-img>

    </v-col>
    <v-col cols="12" sm="8">
      <h1 class="font-weight-light">Hello, {{business.name}} let's help you move your data from your old denominated account.</h1>

      <p>
        Please make sure you have downloaded all your data<br>
        To begin, click on the export button.<br>
        If you have already done this, please dismiss this banner.<br>
        After downloading all your data, please create a new business and import all the files as directed by the wizard.
      </p>
    </v-col>
    <v-col cols="2">
      <v-btn @click="showExportDialog=true" block color="blue" rounded x-large depressed dark outlined>Export <v-icon>mdi-download-outline</v-icon></v-btn>
      <v-btn @click="dismiss()" small class="mt-3" block color="red" rounded depressed dark text>Already done, dismiss <v-icon>mdi-close</v-icon></v-btn>
    </v-col>
  </v-row>


    </v-alert>

    <v-dialog width="500" v-model="showImportDialog" persistent>
      <move-in @closed="showImportDialog=false" :show-start="false"></move-in>
    </v-dialog>


    <v-dialog width="500" v-model="showExportDialog" persistent>
      <move-out @closed="showExportDialog=false" :show-start="false"></move-out>
    </v-dialog>




    <v-snackbar dark v-model="showInfor" timeout="-1">
      You can always find this in your settings
      <v-btn text @click="showInfor=false">{{$t("main.ok")}}</v-btn>
    </v-snackbar>

  </span>

</template>

<script>
import MoveIn from "../SLMigration/MoveIn";
import MoveOut from "../SLMigration/MoveOut";
export default {
  name: "SLMigrateBanner",
  components: {MoveOut, MoveIn},
  data(){
    return{

      showImportDialog:false,
      showInfor:false,
      importBannerVisible:true,
      showExportDialog:false

    }
  },
  computed:{
    business(){
      return this.$store.state.user.user_infor.current_business;
    },
    showImportBanner(){

      return localStorage.getItem("HideSLImportBanner") ==='true';
    }
  },
  methods:{
    dismiss(){

      this.showInfor=true;

      localStorage.setItem("HideSLImportBanner",'true');
      this.importBannerVisible=false;
    }
  },
  mounted() {

    this.importBannerVisible = !this.showImportBanner;
  }
}
</script>

<style scoped>

</style>