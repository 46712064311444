<template>

  <v-card flat v-if="visits && visits.total_views>0">

    <v-row v-if="visits.total_views>0">
      <v-col offset="2" cols="12" sm="4" class="text-center" v-if="visits.invoice_sent_status">

        <h2 class="font-weight-black mb-0">{{visits.invoice_sent_status.created_at | humanDate}}</h2>
        <small class="text--disabled">Sent on</small>

      </v-col>

      <v-col cols="12" sm="4" class="text-center">

        <h2 class="font-weight-black mb-0">{{visits.total_views}}</h2>
        <small class="text--disabled">Number of times it was viewed</small>

      </v-col>
      <v-col cols="12" sm="12" class="mx-auto" v-if="visits.total_views">
        <v-expansion-panels flat>
          <v-expansion-panel

          >
            <v-expansion-panel-header class="blue--text">
              View detailed visits
            </v-expansion-panel-header>
            <v-expansion-panel-content style="max-height: 400px; overflow: auto">
              <v-list>
                <v-list-item link class="border" v-for="visit in visits.visits" :key="visit.id">

                    <v-list-item-title>{{visit.visited_at}}</v-list-item-title>
                    <v-list-item-subtitle><strong>Device type:</strong>{{visit.device_type}}</v-list-item-subtitle>
                    <v-list-item-subtitle><strong>Browser:</strong>{{visit.browser}}</v-list-item-subtitle>
                    <p class="d-block w-100" v-if="visit.referer_url"><strong>Referer URL:</strong>{{visit.referer_url}}</p>

                </v-list-item>
              </v-list>


            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-col>
    </v-row>



  </v-card>
</template>

<script>
export default {
  props:["invoice_uuid"],
  name: "VisistsComponent",
  data() {
    return {
      progress: false,
      visits:null
    }
  },
  methods: {
    getVisits() {
      this.progress = true;
      axios.get("/api/invoice/link/visits/" + this.invoice_uuid)
          .then(res => {

            this.visits=res.data;

          })

    }
  },
  mounted() {
    this.getVisits();
  }
}
</script>

<style scoped>

</style>