<template>
<!--<v-card flat class="gradient-card" dark color="blue darken-4">-->
<!--  <v-card-text class="text-center pt-1 pb-1">-->
<!--    <v-icon size="30" class="mb-2">mdi-shimmer</v-icon>-->
<!--<p class="font-weight-black white&#45;&#45;text">Generate professional financial reports with CFO AI.</p>-->

<!--  </v-card-text>-->
<!--<v-card-actions class="mt-0 pt-0">-->
<!--  <v-btn target="_blank" :href="'https://cfoai.built.africa/?s=' + getAccessToken()" color="#F9E251" block light rounded depressed>Generate Report<sup><v-icon x-small>mdi-open-in-new</v-icon></sup></v-btn>-->
<!--</v-card-actions>-->
<!--</v-card>-->

  <span>
    <v-btn target="_blank" :href="'https://cfoai.built.africa/?s=' + getAccessToken()" depressed class="shinny-button" dark x-large rounded block>CFOAI<sup><v-icon class="ai-shimmer" small>mdi-shimmer</v-icon></sup></v-btn>
  </span>
</template>
<script>
import {defineComponent} from 'vue'
import {getAccessToken} from "../../utils";

export default defineComponent({
  name: "AdCard",
  methods: {getAccessToken}
})
</script>

<style scoped>

@keyframes turn-shimmer {
  50% {transform: rotate(180deg);}
}


.ai-shimmer{
  animation:turn-shimmer 5s infinite;
}

@-webkit-keyframes shinning {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%;}
  100%{background-position:0% 50%}
}
@-moz-keyframes shinning {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%;}
  100%{background-position:0% 50%}
}
@keyframes shinning {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%;}
  100%{background-position:0% 50%}
}


.shinny-button{
  background: linear-gradient(270deg, #290aa5, #890aa5);
  background-size: 400% 400%;

  -webkit-animation: shinning 10s ease infinite;
  -moz-animation: shinning 10s ease infinite;
  animation: shinning 10s ease infinite;

}
.gradient-card{
  background: radial-gradient(circle at -8.9% 51.2%, rgb(255, 124, 0) 0%, rgb(255, 124, 0) 15.9%, rgb(255, 163, 77) 15.9%, rgb(255, 163, 77) 24.4%, rgb(19, 30, 37) 24.5%, rgb(19, 30, 37,0.3) 66%);
}
</style>