<template>

 <span>
   <v-card>
      <v-card-title style="transition: 0.5s ease-in-out">

      <v-btn v-if="step==1" color="#0D2E57" dark depressed fab small style="transition: 0.5s ease-in-out"
             @click="$emit('back')"><v-icon>mdi-arrow-left</v-icon></v-btn>

      <v-btn v-if="step==4" color="#0D2E57" dark depressed fab small style="transition: 0.5s ease-in-out"
             @click="step=1"><v-icon>mdi-arrow-left</v-icon></v-btn>

      <h1 :class="$vuetify.theme.isDark ? 'white--text':'text-built'" class="ma-3 font-weight-light"
          style="transition: 0.3s ease-in-out">Continue <strong
          class="font-weight-bold">Account Migration from {{ APP_NAME }}</strong> </h1>
      <v-spacer></v-spacer>
    </v-card-title>
  <v-stepper v-model="step" style="box-shadow: none">
    <v-stepper-items>
      <v-stepper-content step="1">
  <v-row class="mt-5 mb-5">

    <v-col cols="12" sm="4">
      <v-card outlined @click="step=2">
        <v-card-text class="text-center">
          <v-avatar size="150">
            <v-img
                eager
                src="https://built-storage.ams3.cdn.digitaloceanspaces.com/wave_logo.jpeg"
            ></v-img>
          </v-avatar>
          <h1 :class="$vuetify.theme.isDark ? 'white--text':'text-built'" class="font-weight-bold">Wave</h1>
          <small class="text-muted">Import from wave</small>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="4">
      <v-card outlined @click="step=3">
        <v-card-text class="text-center">
          <v-avatar size="150">
            <v-img
                eager
                src="https://built-storage.ams3.cdn.digitaloceanspaces.com/xero_logo.png"
            ></v-img>
          </v-avatar>
          <h1 :class="$vuetify.theme.isDark ? 'white--text':'text-built'" class="font-weight-bold">Xero</h1>
          <small class="text-muted">Import from Xero</small>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="4">
      <v-card outlined @click="step=4">
        <v-card-text class="text-center">
          <v-avatar size="150">
            <v-img
                eager
                src="https://built-storage.ams3.cdn.digitaloceanspaces.com/quickbooks_logo.svg"
            ></v-img>
          </v-avatar>
          <h1 :class="$vuetify.theme.isDark ? 'white--text':'text-built'" class="font-weight-bold">QuickBooks</h1>
          <small class="text-muted">Import from QuickBooks</small>
        </v-card-text>
      </v-card>
    </v-col>

  </v-row>
          </v-stepper-content>



      <v-stepper-content step="2">

        <wave-wizard v-if="step==2"
                     :default-trial-balance="trialBalance"
                     :finished="finish"
                     @back="step=1"></wave-wizard>
      </v-stepper-content>

      <v-stepper-content step="3">
        <xero-wizard
            v-if="step==3"
            :default-trial-balance="trialBalance"
            :finished="finish"
            @back="step=1"
        ></xero-wizard>
      </v-stepper-content>


      <v-stepper-content step="4">
<quick-books-wizard
    v-if="step==4"
    :customers="defaultCustomers"
    :default-bank-accounts="defaultBankAccounts"
    :finished="finish"
    :items="defautlItems"
    :suppliers="defaultSuppliers"
></quick-books-wizard>

      </v-stepper-content>

    </v-stepper-items>
  </v-stepper>

   </v-card>
 </span>

</template>

<script>
import WaveWizard from "./waveWizard";
import QuickBooksWizard from "./quickBooksWizard";
import XeroWizard from "./XeroWizard";

export default {
  name: "accountMigration",
  props: ['defaultApp', 'trialBalance', 'defaultCustomers', 'defaultSuppliers', 'defautlItems', 'defaultBankAccounts', 'finish'],
  components: {XeroWizard, QuickBooksWizard, WaveWizard},
  data() {
    return {
      step: 1
    }
  },
  computed: {
    APP_NAME() {
      if (this.step == 2) {
        return "Wave";
      } else if (this.step == 3) {
        return "Xero"

      } else if (this.step == 4) {
        return "QuickBooks"

      } else {

        return "supported apps";

      }

    }
  },
  mounted() {

    if (this.defaultApp == 'wave') {
      this.step = 2;
    }

    if (this.defaultApp == 'quickbooks') {
      this.step = 4;
    }

    if (this.defaultApp == 'xero') {
      this.step = 3;
    }


  }
}
</script>

<style scoped>

</style>