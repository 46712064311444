<template>

    <div>
        <v-btn :loading="loading" @click="resolve_transaction()" small text :color="$vuetify.theme.isDark ?'white' : 'blue'" class="text-none p-0">
            {{ledger.entry_date | humanDate}} <v-icon small>mdi-open-in-new</v-icon>
        </v-btn>
        <v-snackbar color="red" dark v-model="error">
            {{error_message}}
        </v-snackbar>
    </div>

</template>

<script>
    export default {
        props:['ledger'],
        name: "ResolveLedgerComponent",
        data(){
            return{
                loading:false,
                error:false,
                error_message:''
            }
        },
        methods:{
            resolve_transaction(){
                this.loading=true;
                axios.get('/api/resolvetransaction/'+this.ledger.id)
                    .then(res=>{
                        this.loading=false;
                        window.open(res.data.url,'_blank');
                    })
                .catch(error=>{
                    this.loading=false;
                    // if (error.response.status===302){
                    //     this.error_message = error.response.data;
                    //     this.error=true;
                    // }
                })
            }

        }
    }
</script>

<style scoped>

</style>