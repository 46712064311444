<template>
  <v-tour :name="index" :steps="steps">

    <template slot-scope="tour">
      <transition name="fade">
        <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            class="blue"
        >
          <template>
            <div slot="actions">
              <v-btn @click="tour.stop(); turnOff() " outlined small dark>Okay, got it</v-btn>
            </div>
          </template>
        </v-step>
      </transition>
    </template>

  </v-tour>
</template>

<script>
export default {
  name: "TourComponent",
  props:{
    index:{
      type:String,
      default:"InvoiceAmountDueTour"
    },
    target:{
      type:String,
      default:"#invoice_amount_due_btn"
    },
    description:{
      type:String
    }

  },
  data () {
    return {


      steps: [

        {
          target: this.target,
          content: this.description
        },

      ]
    }
  },
  methods:{
    turnOff(){
      localStorage.setItem(this.index,true);
      localStorage.setItem(this.index,'yes');
    },

  },
  mounted () {

    if (!localStorage.getItem(this.index)) {
      this.$tours[this.index].start()
    }

  }
}
</script>

<style scoped>

</style>