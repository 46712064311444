<template>
  <div>

    <div class="col-md-12">
      <v-progress-circular size="64" indeterminate color="blue darken-4"
                           v-if="$store.state.loadingAssets"></v-progress-circular>

      <div id="chart-spark3" v-else>
        <v-progress-linear color="blue darken-4" indeterminate v-if="$store.state.loadingBankCashMonthData"></v-progress-linear>

        <h2 class="font-weight-bolder p-0 m-0">{{ total | absolute | currency_symbol }}</h2>
        <small>{{ $t('dashboard.cash_bank_title') }} </small>
<!--        <apexchart :type="chartType" height="200" :options="chartOptionsSpark3" :series="series"></apexchart>-->
      </div>
    </div>

<!--    <v-bottom-sheet scrollable persistent v-model="$store.state.ShowBankCashMonthBottomSheet">-->
<!--      <v-card flat>-->

<!--        <v-card-title>-->
<!--          <h2 class="font-weight-light">Bank & cash balances for {{$store.state.BankCashMonthDataMonthName}}, {{$store.state.current_year}}</h2>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn rounded depressed color="red lighten-5 red&#45;&#45;text" @click="$store.state.ShowBankCashMonthBottomSheet=false" small>Close <v-icon small>mdi-close</v-icon></v-btn>-->
<!--        </v-card-title>-->

<!--        <bank-cash-month-data-component></bank-cash-month-data-component>-->

<!--      </v-card>-->
<!--    </v-bottom-sheet>-->

  </div>
</template>

<script>
import {store} from "../../store";
import axios from "axios";
import BankCashMonthDataComponent from "../dashboard/BankCashMonthDataComponent";

export default {
  components: {BankCashMonthDataComponent},
  props: ["total"],
  name: "cashbankComponent",
  data() {
    return {
      chartType:"area",
      balance: 0,
      progress: false,
      series: [{
        name: "Cash & Bank Balance",
        data: []
      }],
      chartOptionsSpark3: {
        chart: {
          events: {
            markerClick(event, chartContext, config) {


              const month = config.dataPointIndex + 1;

              const url = "/api/v2/dashboard/month/cash-bank/" + store.state.current_year + "/" + month;


              store.state.loadingBankCashMonthData =true;
              axios.get(url)
                  .then(res => {
                    store.state.BankCashMonthData = res.data;
                    store.state.ShowBankCashMonthBottomSheet=true;
                    store.state.BankCashMonthDataMonthName=res.data.month;
                    store.state.loadingBankCashMonthData =false;




                  })

            },

          },
          height: 160,
          sparkline: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width:1
        },
        fill: {
          opacity: 0.3,
        },
        xaxis: {
          categories: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          y: {
            show: true,
            formatter: function (d) {


              if (d) {
                if (!isNaN(d)) {
                  return d < 0
                      ? store.state.user.user_infor.current_business.currency.symbol + "(" +
                      (Number(d) / -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
                      ")"
                      : store.state.user.user_infor.current_business.currency.symbol + Number(d)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                } else {
                  return d;
                }
              } else {
                return 0;
              }


            },
          },

        }

      },
    }
  },

  computed: {
    year() {
      return this.$store.state.current_year;
    }
  },
  watch: {

    year() {
      this.$store.state.loadingAssets = true;
      this.get_balances();
    }
  },
  methods: {

    absolute(d) {
      if (d) {
        if (!isNaN(d)) {
          return d < 0
              ? "(" +
              (Number(d) / -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              ")"
              : Number(d)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
          return d;
        }
      } else {
        return 0;
      }
    },


    get_balances() {
      this.progress = true;
      axios
          .get('/api/cashbankpermonth/' + this.$store.state.current_year)
          .then(res => {
            let d = [];
            res.data.forEach(balalnce => {
              d.push(balalnce);

            });

            this.series = [{
              name: 'Cash & Bank Balance',
              data: d
            }];
            this.progress = false;
            this.$store.state.loadingAssets = false;

          });

    }
  },
  mounted() {
    this.get_balances();
  }
}
</script>

<style scoped>
.apexcharts-title-text {
  color: white !important;
}

</style>
