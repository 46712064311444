<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          class="border"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/accounting/loans/'+item.uuid)"
          :to="'/accounting/loans/'+item.uuid"
      >
        <v-list-item-content>
          <v-list-item-title class="font-weight-black blue--text">{{item.paid_amount | toMoney | currency_symbol}}/{{item.amount | toMoney | currency_symbol}}</v-list-item-title>

          <v-list-item-subtitle>
            Loan Account:{{item.loan_account.account_name}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Bank Account:{{item.bank_account.account_name}}
          </v-list-item-subtitle>

          <v-list-item-subtitle>
            Description:{{item.description}}
          </v-list-item-subtitle>

        </v-list-item-content>
      </v-list-item>

    </v-col>
    <v-col cols="12" sm="4">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
              large
              text
              rounded
              color="blue"
              to="/accounting/loans"
              block
              @click="$emit('close'); $router.push('/accounting/loans')"
          >Go to Accounts <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>

</template>

<script>
export default {
  props:['items'],
  name: "SearchLoans"
}
</script>

<style scoped>

</style>