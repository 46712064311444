<template>
<span>

  <v-dialog v-model="showDialog" width="70%" persistent>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="close" color="red lighten-5 red--text" rounded depressed small>close <v-icon small>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>

      <v-row>
        <v-col cols="6">
          <v-img src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/share_sheet.png"></v-img>
        </v-col>
        <v-col cols="6">
          <center>
            <h1 class="display-2 font-weight-black black--text mt-5">Cheers to more convenient ways to share invoices!</h1>


<p>
  <h4>You can now choose to share your invoices via <v-icon large color="green">mdi-whatsapp</v-icon>whatsapp or both whatsapp and SMS.</h4>
</p>
                      </center>

          <v-alert color="blue lighten-5 blue--text">
            <strong class="font-weight-black">Note:</strong>
            <p>
              As shown on the left side, all methods of sharing invoices have been put under the share button where you can share to email,SMS or WhatsApp, you can also copy the invoice link or visit the link to preview it.
            </p>
          </v-alert>

        </v-col>
      </v-row>
      </v-card-text>

    </v-card>
  </v-dialog>
</span>
</template>

<script>
import moment from "moment";

export default {
  name: "shareSheetAlert",
  data(){
    return{
      showDialog:false
    }
  },
  methods:{
    close(){
      this.showDialog=false;
      localStorage.setItem("share_sheet_alert","true");
    }
  },
  mounted() {

    if (moment().isBefore("2022-07-30") && !localStorage.getItem("share_sheet_alert")){

      this.showDialog=true;

    }
  }
}
</script>

<style scoped>

</style>