<template>
	<v-dialog v-model="show" width="80%" persistent>
		<v-card color="orange" dark>
			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
			</v-card-title>
			<v-carousel
				cycle
				hide-delimiters
				hide-delimiter-background
				:show-arrows="false"
				height="65vh"
				progress
				interval="6000"
				progress-color="white"
			>
				<v-carousel-item>
					<v-card-text>
						<v-row>
							<v-col cols="12" sm="8">
								<v-img src="/img/imac_pos.png"></v-img>
							</v-col>
							<v-col cols="12" sm="4" class="text-left">
								<h1 class="font-weight-black display-2">
									We moved the POS out and made it better!
								</h1>

								<v-card
									dark
									outlined
									color="orange"
									style="border: 1px solid white !important"
								>
									<v-card-text>
										<h2 class="font-weight-black white--text">
											Multiple Sale Points
										</h2>
										<p class="white--text">Invite users to sell for you</p>
									</v-card-text>
								</v-card>

								<v-card
									dark
									outlined
									color="orange"
									style="border: 1px solid white !important"
								>
									<v-card-text>
										<h2 class="font-weight-black white--text">Easy to setup</h2>
										<p class="white--text">
											Setup your POS taxes and accounts under the POS settings
											section on the business settings page.
										</p>
										<p class="white--text">
											In the event of a promotion, setup discounts on items for
											your cashiers to sell at discounted prices per item
										</p>
									</v-card-text>
								</v-card>

								<v-btn
									:href="posURL"
									target="_blank"
									style="text-decoration: none !important"
									color="orange lighten-2"
									class="mt-3"
									x-large
									depressed
									rounded
									block
									>Go to POS <v-icon>mdi-arrow-right</v-icon></v-btn
								>
							</v-col>
						</v-row>
					</v-card-text>
				</v-carousel-item>

				<v-carousel-item>
					<v-card-text>
						<v-row>
							<v-col cols="12" sm="8">
								<v-img src="/img/pos_imac_charge.png"></v-img>
							</v-col>
							<v-col cols="12" sm="4" class="text-left">
								<h1 class="font-weight-black display-2">
									Accept instant payments!
								</h1>

								<v-card
									dark
									outlined
									color="orange"
									style="border: 1px solid white !important"
								>
									<v-card-text>
										<h2 class="font-weight-black white--text">
											Charge your customers as you sell
										</h2>
										<p class="white--text">
											Accept payments via mobile money and Mpesa instantly as
											you sell
										</p>
									</v-card-text>
								</v-card>

								<v-btn
									:href="posURL"
									target="_blank"
									style="text-decoration: none !important"
									color="orange lighten-2"
									class="mt-3"
									x-large
									depressed
									rounded
									block
									>Go to POS <v-icon>mdi-arrow-right</v-icon></v-btn
								>
							</v-col>
						</v-row>
					</v-card-text>
				</v-carousel-item>
			</v-carousel>
		</v-card>
	</v-dialog>
</template>

<script>
import { getAccessToken } from "../../utils";
import moment from "moment";
export default {
	name: "POSAlert",
	data() {
		return {
			show: false,
		};
	},
	computed: {
		posURL() {
			return "https://pos.built.africa?s=" + getAccessToken();
		},
	},
	methods: {
		close() {
			this.show = false;
			localStorage.setItem("pos_alert", "true");
		},
	},
	mounted() {
		if (moment().isBefore("2022-07-05") && !localStorage.getItem("pos_alert")) {
			this.show = true;
		}
	},
};
</script>

<style scoped></style>
