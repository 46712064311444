<template>
  <v-card flat>
    <v-card-title v-if="ShowHeader">
      <h2 class="font-weight-bolder">Profit & Loss</h2>
      <v-spacer></v-spacer>
      <v-menu
          v-model="Dates_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          :close-on-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="dates"
              :label="$t('main.period')"
              readonly
              flat
              filled
              rounded
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker show-adjacent-months :multiple="true" range v-model="dates"></v-date-picker>
        <br>
        <v-btn @click="Dates_menu=false; get_pnl_summary();" color="blue" text small :disabled="dates.length<2">
          {{ $t('main.apply') }}
        </v-btn>
        <v-btn text small @click="Dates_menu=false">{{ $t('main.cancel') }}</v-btn>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card v-if="pnl" flat rounded :class="$vuetify.theme.isDark ? 'bg-dark':'bg-light'">
            <v-card-text>
             <span v-if="ShortNumber">
               <v-tooltip bottom>
                 <template v-slot:activator="{on}">
              <h2 v-on="on" class="font-weight-bolder">{{ pnl.net_profit | short_number | currency_symbol }}</h2>
                 </template>
                 <span>
                   {{ pnl.net_profit | absolute | currency_symbol }}
                 </span>
               </v-tooltip>
             </span>

              <h2 v-else class="font-weight-bolder">{{ pnl.net_profit | absolute | currency_symbol }}</h2>
              <small>Net Profit</small>
            </v-card-text>
          </v-card>
          <v-skeleton-loader type="card" v-else></v-skeleton-loader>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card v-if="pnl" flat rounded :class="$vuetify.theme.isDark ? 'bg-dark':'bg-light'">
            <v-card-text>

                  <span v-if="ShortNumber">
               <v-tooltip bottom>
                 <template v-slot:activator="{on}">
              <h2 v-on="on" class="font-weight-bolder">{{ pnl.operating_profit | short_number | currency_symbol }}</h2>
                 </template>
                 <span>
                   {{ pnl.operating_profit | absolute | currency_symbol }}
                 </span>
               </v-tooltip>
             </span>

              <h2 v-else class="font-weight-bolder">{{ pnl.operating_profit | absolute | currency_symbol }}</h2>
              <small>Operating Profit</small>
            </v-card-text>
          </v-card>
          <v-skeleton-loader type="card" v-else></v-skeleton-loader>

        </v-col>

        <v-col cols="12" sm="12">
          <v-list v-if="pnl">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Net Profit Margin: {{ pnl.profit_margin | toMoney }}%</v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-linear height="20" rounded color="blue" width="100%"
                                     :value="pnl.profit_margin"></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('dashboard.income') }}: {{ pnl.income | toMoney | currency_symbol }}
                  ({{ pnl.income_percentage }}%)
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-linear height="20" rounded color="blue" width="100%"
                                     :value="pnl.income_percentage"></v-progress-linear>

                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>


            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('dashboard.expense') }}: {{ pnl.expense | toMoney | currency_symbol }}
                  ({{ pnl.expense_percentage }}%)

                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-linear height="20" rounded color="red" width="100%"
                                     :value="pnl.expense_percentage"></v-progress-linear>

                </v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>
          <v-skeleton-loader type="list-item@3" v-else></v-skeleton-loader>

        </v-col>
      </v-row>

      <span v-if="ShowMore">
         <v-btn
             text
             small
             :color="$vuetify.theme.dark ? 'white' : 'blue'"
             rounded
             block
             to="/reports/analytics"
         >Get more insight
          <v-icon
              small
              :color="$vuetify.theme.dark ? 'white' : 'blue'"
              class="ml-2"
          >mdi-finance
          </v-icon>

        </v-btn>
      </span>
    </v-card-text>
    <v-card-actions v-if="ShowHeader">
      <v-spacer></v-spacer>
      <view-button to="/reports/incomestatement"></view-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import ViewButton from "./viewButton";

export default {
  props: {
    ShowMore:{
      type:Boolean,
      default:false
    },
    ShowHeader: {
      default: true,
      type: Boolean
    },
    DefaultStartDate: {
      type: String,
      default: null
    },
    DefaultEndDate: {
      type: String,
      default: null
    },
    ShortNumber: {
      type: Boolean,
      default: false
    }

  },
  name: "ProfitAndLost",
  components: {ViewButton},
  data() {
    return {
      dates: [],
      Dates_menu: false,
      pnl: null
    }
  },
  mounted() {
    if (this.DefaultStartDate && this.DefaultEndDate) {

      this.dates.push(this.DefaultStartDate);
      this.dates.push(this.DefaultEndDate);

    } else {
      let startdate = moment().format('YYYY-MM-DD');
      startdate = startdate.substring(0, 8) + '01';
      let enddate = moment().format('YYYY-MM-DD');
      this.dates.push(startdate);
      this.dates.push(enddate);
    }

    this.get_pnl_summary();

  },
  methods: {
    get_pnl_summary() {
      axios.get(`/api/analytics/profitlostsummary?startdate=${this.dates[0]}&enddate=${this.dates[1]}`)
          .then(res => {
            this.pnl = res.data;
          })
    }
  }
}
</script>

<style scoped>
progress {
  width: 100%;
  height: 25px;
}

progress.success-progress::-webkit-progress-value {
  background-color: green !important;
}

progress.success-progress::-webkit-progress-bar {
  background-color: green !important;
}


</style>