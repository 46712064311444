<template>
  <div>
    <s-l-migrate-banner v-if="!progress && IS_ADMIN"></s-l-migrate-banner>

    <div
        v-if="
        $store.state.user.user_infor.is_admin === 0 &&
        $store.state.user.user_infor.invite.has_dashboard === 0
      "
    >
      <dashboardPermissionComponent/>
    </div>

    <div v-else>
      <v-row v-if="!get_started" class="pl-4 pr-6">
        <!--        <fortune-wheel></fortune-wheel>-->
        <!--main content area-->
        <v-col cols="12" sm="8">
          <div v-if="!progress">
            <v-card flat>
              <v-card-title class="font-weight-light" v-if="selectedMonth">
                <p
                    class="font-weight-black text-capitalize"
                    style="font-size: 1.2rem"
                    v-if="selectedMonth"
                >
                  Your business performance for {{ fullMonthText }}
                  {{ selectedYear }}
                </p>
                <p
                    class="font-weight-black text-capitalize"
                    style="font-size: 1.2rem"
                    v-else
                >
                  Your business performance for the year
                  {{ selectedYear }}
                </p>

              </v-card-title>
              <v-card-text>

              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-select
                      class="fw-bold"
                      filled
                      rounded
                      :block="$vuetify.breakpoint.mobile"
                      v-model="selectedMonth"
                      :items="dropdownItems"
                      @change="handlePeriodChange"
                      id="dashboardMonthSelector"

                  >
                    <template v-slot:item="{item}">
                      {{ item === selectedYear ? "All of " + item : item }}
                    </template>

                    <template v-slot:selection="{item}">
                      {{ item === selectedYear ? "All of " + item : item }}
                    </template>

                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                      class="fw-bold"

                      filled
                      id="dashboardYearSelector"
                      rounded
                      :block="$vuetify.breakpoint.mobile"
                      v-model="selectedYear"
                      :items="yearsList"
                      @change="handlePeriodChange"
                  ></v-select>
                </v-col>
              </v-row>
              </v-card-text>


              <v-card-text v-if="selectedMonth">
                <v-row
                    v-if="monthlyDash && monthsList.includes(selectedMonth)"
                    :class="$vuetify.theme.dark ? 'black' : 'grey lighten-5'"
                    style="border-radius: 15px !important"
                >
                  <v-col cols="12" sm="4">
                    <v-card
                        class="text-center"
                        color="ma-2"
                        flat
                        style="transition: 0.3s ease-in-out"
                    >
                      <v-card-text class="pt-5 pb-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <p
                                v-show="
                                monthlyDash.percent_income_change_from_previous_month !==
                                0
                              "
                                :class="
                                monthlyDash.percent_income_change_from_previous_month >
                                0
                                  ? 'green--text'
                                  : 'red--text'
                              "
                                style="transition: 0.3s ease-in-out"
                                v-on="on"
                            >
                              {{
                                Math.abs(
                                    monthlyDash.percent_income_change_from_previous_month
                                )
                              }}%
                              <v-icon
                                  v-if="
                                  monthlyDash.percent_income_change_from_previous_month >
                                  0
                                "
                                  color="green"

                              >mdi-trending-up
                              </v-icon>
                              <v-icon v-else color="red"
                              >mdi-trending-down
                              </v-icon>
                              <small class="d-block text-small"
                              >Versus previous month</small
                              >
                            </p>
                          </template>
                          <span
                          >Previous income:
                            {{
                              monthlyDash.previous_total_income
                                  | toMoney
                                  | currency_symbol
                            }}</span
                          >
                        </v-tooltip>

                        <p
                            v-if="
                            monthlyDash.percent_income_change_from_previous_month ===
                            0
                          "
                            class="text-italic pa-3"
                        >
                          <em> No changes this month </em>
                        </p>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h1 class="blue--text font-weight-black" v-on="on">
                              {{
                                monthlyDash.total_income
                                    | short_number
                                    | currency_symbol
                              }}
                              <small class="text-small blue--text d-block"
                              >Total Income</small
                              >
                            </h1>
                          </template>

                          <span>{{
                              monthlyDash.total_income | toMoney | currency_symbol
                            }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-card class="text-center" color="ma-2" flat>
                      <v-card-text class="pt-5 pb-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <p
                                v-show="
                                monthlyDash.percent_expense_change_from_previous_month !==
                                0
                              "
                                :class="
                                monthlyDash.percent_expense_change_from_previous_month <
                                0
                                  ? 'green--text'
                                  : 'red--text'
                              "
                                style="transition: 0.3s ease-in-out"
                                v-on="on"
                            >
                              {{
                                Math.abs(
                                    monthlyDash.percent_expense_change_from_previous_month
                                )
                              }}%
                              <v-icon
                                  v-if="
                                  monthlyDash.percent_expense_change_from_previous_month >
                                  0
                                "
                                  color="red"

                              >mdi-trending-up
                              </v-icon>
                              <v-icon v-else color="green"
                              >mdi-trending-down
                              </v-icon>
                              <small class="d-block text-small"
                              >Versus previous month</small
                              >
                            </p>
                          </template>
                          <span
                          >Previous expense:
                            {{
                              monthlyDash.previous_total_expense
                                  | toMoney
                                  | currency_symbol
                            }}</span
                          >
                        </v-tooltip>

                        <p
                            v-if="
                            monthlyDash.percent_expense_change_from_previous_month ===
                            0
                          "
                            class="text-italic pa-3"
                        >
                          <em>No changes this month</em>
                        </p>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h1 class="red--text font-weight-black" v-on="on">
                              {{
                                monthlyDash.total_expense
                                    | short_number
                                    | currency_symbol
                              }}
                              <small class="red--text text-small d-block"
                              >Total Expense</small
                              >
                            </h1>
                          </template>
                          <span>{{
                              monthlyDash.total_expense
                                  | toMoney
                                  | currency_symbol
                            }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-card class="text-center ma-2" flat>
                      <v-card-text class="pt-5 pb-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <p
                                v-show="
                                monthlyDash.percent_profit_change_from_previous_month !==
                                0
                              "
                                :class="
                                monthlyDash.percent_profit_change_from_previous_month >
                                0
                                  ? 'green--text'
                                  : 'red--text'
                              "
                                style="transition: 0.3s ease-in-out"
                                v-on="on"
                            >
                              {{
                                Math.abs(
                                    monthlyDash.percent_profit_change_from_previous_month
                                )
                              }}%
                              <v-icon
                                  v-if="
                                  monthlyDash.percent_profit_change_from_previous_month >
                                  0
                                "
                                  color="green"

                              >mdi-trending-up
                              </v-icon>
                              <v-icon v-else color="red"
                              >mdi-trending-down
                              </v-icon>
                              <small class="d-block text-small"
                              >Versus previous month</small
                              >
                            </p>
                          </template>
                          <span
                          >Previous profit:
                            {{
                              monthlyDash.previous_total_profit
                                  | toMoney
                                  | currency_symbol
                            }}</span
                          >
                        </v-tooltip>

                        <p
                            v-if="
                            monthlyDash.percent_profit_change_from_previous_month ===
                            0
                          "
                            class="text-italic pa-3"
                        >
                          <em>No changes this month</em>
                        </p>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h1
                                :class="
                                monthlyDash.total_profit > 0
                                  ? 'green--text'
                                  : 'warning--text'
                              "
                                class="red--text font-weight-black"
                                v-on="on"
                            >
                              {{
                                monthlyDash.total_profit
                                    | short_number
                                    | currency_symbol
                              }}
                              <small
                                  :class="
                                  monthlyDash.total_profit > 0
                                    ? 'green--text'
                                    : 'warning--text'
                                "
                                  class="text-small d-block"
                              >Total
                                {{
                                  monthlyDash.total_profit > 0
                                      ? "Profit"
                                      : "Loss"
                                }}</small
                              >
                            </h1>
                          </template>
                          <span>{{
                              monthlyDash.total_profit | toMoney | currency_symbol
                            }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row
                    v-else
                    :class="$vuetify.theme.dark ? 'black' : 'grey lighten-5'"
                >
                  <v-col cols="12" sm="4">
                    <v-card
                        class="text-center"
                        color="ma-2"
                        flat
                        style="transition: 0.3s ease-in-out"
                    >
                      <v-card-text class="pt-5 pb-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h1 class="blue--text font-weight-black" v-on="on">
                              {{
                                total_income | short_number | currency_symbol
                              }}
                              <small class="text-small blue--text d-block"
                              >Total Income</small
                              >
                            </h1>
                          </template>

                          <span>{{
                              total_income | toMoney | currency_symbol
                            }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-card class="text-center" color="ma-2" flat>
                      <v-card-text class="pt-5 pb-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h1 class="red--text font-weight-black" v-on="on">
                              {{
                                total_expenses | short_number | currency_symbol
                              }}
                              <small class="red--text text-small d-block"
                              >Total Expense</small
                              >
                            </h1>
                          </template>
                          <span>{{
                              total_expenses | toMoney | currency_symbol
                            }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-card class="text-center ma-2" flat>
                      <v-card-text class="pt-5 pb-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h1
                                :class="
                                total_profit > 0
                                  ? 'green--text'
                                  : 'warning--text'
                              "
                                class="red--text font-weight-black"
                                v-on="on"
                            >
                              {{
                                total_profit | short_number | currency_symbol
                              }}
                              <small
                                  :class="
                                  total_profit > 0
                                    ? 'green--text'
                                    : 'warning--text'
                                "
                                  class="text-small d-block"
                              >Total
                                {{
                                  total_profit > 0 ? "Profit" : "Loss"
                                }}</small
                              >
                            </h1>
                          </template>
                          <span>{{
                              total_profit | toMoney | currency_symbol
                            }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <apexchart
                    class="mt-10"
                    v-if="selectedMonth"
                    :type="chartType"
                    height="350"
                    :options="chartOptions"
                    :series="series"
                ></apexchart>

                <norecords-component
                    v-else
                    class="ma-4"
                    description="We could not find any transactions for the selected month."
                    title="No transactions found"
                ></norecords-component>
              </v-card-text>
            </v-card>
          </div>

          <!--          BY FORCE SKELETON LOADER-->
          <v-row v-else>
            <v-col cols="12">
              <v-skeleton-loader
                  class="my-1 py-1"
                  type="table-heading"
              ></v-skeleton-loader>
            </v-col>
            <v-row class="py-10 px-5">
              <v-col cols="4" class="mx-auto my-3">
                <v-skeleton-loader
                    type="image"
                    max-width="350"
                    class="mx-auto"
                    max-height="200"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="4" class="mx-auto my-3">
                <v-skeleton-loader
                    type="image"
                    max-width="350"
                    class="mx-auto"
                    max-height="200"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="4" class="mx-auto my-3">
                <v-skeleton-loader
                    type="image"
                    max-width="350"
                    class="mx-auto"
                    max-height="200"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-skeleton-loader
                  type="image, card-heading"
                  max-height="800"
              ></v-skeleton-loader>
            </v-col>
          </v-row>

          <expenses
              v-if="!get_started"
              :from="fromDate"
              :to="toDate"
          ></expenses>


        </v-col>
        <!--sidebar for summary -->
        <v-col cols="12" sm="4">
          <!--		CASH AND BANK CARD-->
          <profit-and-lost
              :show-header="false"
              :default-end-date="PNLEndDate"
              :default-start-date="PNLStartDate"
              :short-number="true"
              :show-more="true"
          ></profit-and-lost>

          <v-card flat class="mb-3 mt-3">
            <v-skeleton-loader
                type="chip"
                v-if="$store.state.loadingAssets"
            ></v-skeleton-loader>
            <v-card-title v-else>
              <h3
                  class="font-weight-black text-capitalize"
                  v-if="!$vuetify.breakpoint.mobile"
              >
                {{ $t("dashboard.cash") }} & {{ $t("dashboard.bank") }}
              </h3>
              <h6 class="font-weight-black text-capitalize" v-else>
                {{ $t("dashboard.cash") }} & {{ $t("dashboard.bank") }}
              </h6>
            </v-card-title>

            <cash-bank-accounts></cash-bank-accounts>

            <cashbank-component
                :total="dashboard.totalCashBank"
            ></cashbank-component>
          </v-card>

          <v-card class="mb-3" flat>
            <v-card-text>
              <v-list>
                <v-skeleton-loader
                    :loading="progress && isFirstRender"
                    transition="scale-transition"
                    type="list-item"
                >
                  <span>
                  <h3
                      class="font-weight-black d-none d-md-block text-capitalize"
                  >
                    Expected Inflows
                  </h3>
                  <h6
                      class="font-weight-black d-md-none d-sm-block text-capitalize"
                  >
                    Expected Inflows
                  </h6>

                    </span>
                </v-skeleton-loader>

                <v-skeleton-loader
                    :loading="progress && isFirstRender"
                    transition="scale-transition"
                    type="list-item-two-line"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="blue darken-4"
                      >mdi-checkbox-multiple-blank-outline
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title style="font-size: 0.9rem"
                      >{{ $t("dashboard.all_outstanding") }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-list-item-action v-on="on" class="fw-bold">
                          {{ all_invoices | short_number | currency_symbol }}
                        </v-list-item-action>
                      </template>
                      <span>{{
                          all_invoices | toMoney | currency_symbol
                        }}</span>
                    </v-tooltip>
                  </v-list-item>
                </v-skeleton-loader>

                <v-skeleton-loader
                    :loading="progress && isFirstRender"
                    transition="scale-transition"
                    type="list-item-two-line"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="yellow darken-3"
                      >mdi-checkbox-multiple-blank-outline
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title style="font-size: 0.9rem"
                      >{{ $t("dashboard.awaiting") }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-list-item-action v-on="on" class="fw-bold">
                          {{ awaiting | short_number | currency_symbol }}
                        </v-list-item-action>
                      </template>
                      <span>{{ awaiting | toMoney | currency_symbol }}</span>
                    </v-tooltip>
                  </v-list-item>
                </v-skeleton-loader>

                <v-skeleton-loader
                    :loading="progress && isFirstRender"
                    transition="scale-transition"
                    type="list-item-two-line"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="red"
                      >mdi-checkbox-multiple-blank-outline
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title style="font-size: 0.9rem"
                      >{{ $t("dashboard.overdue") }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-list-item-action v-on="on" class="fw-bold">
                          {{ overdue | short_number | currency_symbol }}
                        </v-list-item-action>
                      </template>
                      <span>{{ overdue | toMoney | currency_symbol }}</span>
                    </v-tooltip>
                  </v-list-item>
                </v-skeleton-loader>

                <v-skeleton-loader
                    :loading="progress && isFirstRender"
                    transition="scale-transition"
                    type="list-item"
                >
                  <v-list-item>
                    <v-btn to="/income/invoices" text block
                    >{{ $t("dashboard.view_invoices") }}
                    </v-btn>
                  </v-list-item>
                </v-skeleton-loader>
              </v-list>
            </v-card-text>
          </v-card>

          <v-card class="mb-3" flat>
            <v-card-text>
              <v-skeleton-loader
                  :loading="progress && isFirstRender"
                  transition="scale-transition"
                  type="card"
              >
                <span>

                <h3
                    class="font-weight-black d-none d-md-block p-0 m-0 text-capitalize"
                    :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
                >
                  Expected Outflows
                </h3>
                <h6
                    class="font-weight-black d-md-none d-sm-block text-capitalize"
                    :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
                >
                  Expected Outflows
                </h6>
                </span>

              </v-skeleton-loader>

              <v-skeleton-loader
                  :loading="progress && isFirstRender"
                  transition="scale-transition"
                  type="list-item-two-line"
              >
                <v-list-item class="mt-2">
                  <v-list-item-avatar>
                    <v-icon color="yellow darken-3"
                    >mdi-file-document-minus
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title style="font-size: 0.9rem">
                      {{ $t("dashboard.all_outstanding_bills") }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-list-item-action v-on="on" class="fw-bold">
                        {{ all_bills | short_number | currency_symbol }}
                      </v-list-item-action>
                    </template>
                    <span>{{ all_bills | toMoney | currency_symbol }}</span>
                  </v-tooltip>
                </v-list-item>
              </v-skeleton-loader>

              <v-skeleton-loader
                  :loading="progress && isFirstRender"
                  transition="scale-transition"
                  type="list-item-two-line"
              >
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon color="red">mdi-file-document-minus</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title style="font-size: 0.9rem"
                    >{{ $t("dashboard.overdue_bills") }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-list-item-action v-on="on" class="fw-bold">
                        {{
                          dashboard.overdue_bills
                              | short_number
                              | currency_symbol
                        }}
                      </v-list-item-action>
                    </template>
                    <span>{{
                        dashboard.overdue_bills | toMoney | currency_symbol
                      }}</span>
                  </v-tooltip>
                </v-list-item>
              </v-skeleton-loader>

              <v-skeleton-loader
                  :loading="progress && isFirstRender"
                  transition="scale-transition"
                  type="list-item"
              >
                <v-list-item>
                  <v-btn to="/expense/expenses" text block>View bills</v-btn>
                </v-list-item>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>

          <profit-and-lost
              :show-header="false"
              :default-end-date="PNLEndDate"
              :default-start-date="PNLStartDate"
              :short-number="true"
              :show-more="true"
              v-if="false"
          ></profit-and-lost>

        </v-col>


        <v-col cols="12">
          <!--	   INVENTORY, CREDITORS TABS-->


          <v-skeleton-loader
              :loading="progress"
              transition="scale-transition"
              type="card"
          >
            <v-card flat rounded>
              <v-card-title>
                <h3
                    class="font-weight-black d-none d-md-block"
                    style="text-transform: capitalize !important"
                >
                  {{ $t("dashboard.summary_of_credit_debit") }}
                </h3>
                <h6
                    class="font-weight-black d-md-none d-sm-block"
                    style="text-transform: capitalize !important"
                >
                  {{ $t("dashboard.summary_of_credit_debit") }}
                </h6>
              </v-card-title>
              <p class="text-muted ml-5"><strong>Note:</strong> The filters above do not affect what you see here</p>

              <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  grow
                  center-active
                  icons-and-text
                  show-arrows
              >
                <v-tab
                    v-for="item in dash_tabs"
                    :key="item.text"
                    active-class="text-primary"
                >
                  <h3
                      v-if="item.text === $t('dashboard.debtors')"
                      class="font-weight-bold text-success"
                  ></h3>
                  <h3
                      v-if="item.text === $t('dashboard.creditors')"
                      class="font-weight-bold text-danger"
                  ></h3>
                  <h3
                      v-if="item.text === $t('dashboard.inventory')"
                      class="font-weight-bold"
                  ></h3>
                  {{ item.text }}
                </v-tab>

                <v-tab-item :key="0">
                  <v-card flat>
                    <v-card-text class="fixed-height-card section">
                      <credit-debit-component
                          :total="dashboard.totalDebtors"
                          :customers="dashboard.customers"
                      ></credit-debit-component>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item key="Creditors">
                  <v-card flat>
                    <v-card-text class="fixed-height-card section">
                      <credit-debit-component
                          :total="dashboard.totalCreditors"
                          :suppliers="dashboard.suppliers"
                      ></credit-debit-component>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item key="Deposits">
                  <v-card flat>
                    <v-card-text class="fixed-height-card section">
                      <dahsboard-deposits-component></dahsboard-deposits-component>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item key="Inventory">
                  <v-card flat>
                    <v-card-text class="fixed-height-card section">
                      <InventoryComponent
                          :total="dashboard.totalInventory"
                          :inventory_items="dashboard.inventory"
                      ></InventoryComponent>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

              </v-tabs>


            </v-card>

          </v-skeleton-loader>


        </v-col>
      </v-row>

      <!-- getting started Component-->
      <v-row v-else>
        <v-col cols="12" sm="12">
          <getstartedComponent></getstartedComponent>
        </v-col>
      </v-row>
      <singup-survey></singup-survey>

    </div>
  </div>
</template>

<script>
import cashbankComponent from "./patials/cashbankComponent";
import InventoryComponent from "./patials/inventoryComponent";
import DebtorsComponent from "./patials/debtorsComponent";
import CreditorsComponent from "./patials/CreditorsComponent";
import {store} from "../store";
import moment from "moment";
import CashBankAccounts from "./dashboard/cashBankAccounts";
import CustomerSupplierInvite from "./patials/customerSuppliersInvite";
import Expenses from "./dashboard/expenses";
import KPIsComponent from "./dashboard/KPIsComponent";
import MobilePNL from "./dashboard/MobilePNL.vue";
import NotificationsComponent from "./patials/notificationsComponent";
import CompainCard from "./affiliate/CompainCard";
import BankReconIntro from "./intros/BankReconIntro";
import SLMigrateBanner from "./announcement/SLMigrateBanner";
import CreditDebitComponent from "./dashboard/creditDebitComponent";
import DahsboardDepositsComponent from "./dashboard/DahsboardDepositsComponent";
import axios from "axios";
import eventBus, {getAccessToken} from "../utils";
import NorecordsComponent from "./sync/norecordsComponent.vue";
import FortuneWheel from "./games/fortuneWheel.vue";
import ProfitAndLost from "./analytics/ProfitAndLost.vue";
import SingupSurvey from "./intros/SingupSurvey.vue";

const getstartedComponent = () => import("./patials/getstartedComponent");
const dashboardPermissionComponent = () =>
    import("./patials/dashboardPermissionComponent");

export default {
  name: "dashboardComponent",
  components: {
    SingupSurvey,
    ProfitAndLost,
    FortuneWheel,
    NorecordsComponent,
    DahsboardDepositsComponent,
    CreditDebitComponent,
    SLMigrateBanner,
    BankReconIntro,
    CompainCard,
    NotificationsComponent,
    KPIsComponent,
    Expenses,
    CustomerSupplierInvite,
    CashBankAccounts,
    DebtorsComponent,
    CreditorsComponent,
    InventoryComponent,
    cashbankComponent,
    getstartedComponent,
    dashboardPermissionComponent,
    MobilePNL,
  },
  data() {
    return {
      selectedYear: this.$store.state.user.user_infor.current_business.current_year,
      show_barner: true,
      PNLStartDate: moment().startOf("year").format('YYYY-MM-DD'),
      PNLEndDate: moment().endOf("year").format('YYYY-MM-DD'),
      chartType: "area",
      fromDate: "",
      toDate: "",
      series: [],
      chartOptions: {
        legend: {
          labels: {
            colors: this.labelColors,
          },
        },
        labels: [],
        chart: {
          events: {
            dataPointSelection(event, chartContext, config) {
              let params = {event, chartContext, config};
            },
          },
          type: "area",

          stroke: {
            curve: "smooth",
          },
          toolbar: {
            show: true,
            tools: {
              // zoom: false,
              zoomin: false,
              zoomout: false,
            },
          },
          zoom: {
            enabled: true,
          },
        },
        tooltip: {
          theme: !this.$vuetify.theme.dark ? "light" : "dark",
          y: {
            show: true,
            formatter: function (d) {
              if (d) {
                if (!isNaN(d)) {
                  return d < 0
                      ? store.state.user.user_infor.current_business.currency
                          .symbol +
                      "(" +
                      (Number(d) / -1)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
                      ")"
                      : store.state.user.user_infor.current_business.currency
                          .symbol +
                      Number(d)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                } else {
                  return d;
                }
              } else {
                return 0;
              }
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: ["#2196F3", "#F44336", "#4CAF50"],
          curve: "smooth",
          width: 1,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          show: this.$vuetify.breakpoint.mdAndUp,

          labels: {
            formatter: function (y) {
              return (
                  store.state.user.user_infor.current_business.currency.symbol +
                  y.toFixed(2)
              );
            },
          },
        },
        xaxis: {
          type: "month",
          categories: this.monthsList,
          labels: {
            rotate: -90,
          },
        },
      },
      no_show: false,
      unrealisedGains: {balance: 0},
      advert: false,
      canShowExpiry: false,
      expiryCount: 0,
      all_bills: 0,
      year_list: [],
      loadingGains: false,
      currentTab: "This year",
      cashbank: [],
      assetAccounts: {subtypes: []},
      expenseAccounts: [],
      kpiLoading: false,
      periods: ["This year", "Last three months", "This month"],
      incomeStatement: {income: {subtypes: []}, expense: {subtypes: []}},
      kpiTabs: [{tab: 0}, {tab: 1}, {tab: 2}],
      dash_tabs: [
        {
          text: this.$t("dashboard.debtors"),
          icon: "mdi-account-group-outline",
        },
        {
          text: this.$t("dashboard.creditors"),
          icon: "mdi-account-group-outline",
        },
        {
          text: this.$t("main.deposit") + "s",
          icon: "mdi-account-group-outline",
        },
        {
          text: this.$t("dashboard.inventory"),
          icon: "mdi-cube-outline",
        },
      ],
      paid: 0,
      tab: "Debtors",
      overdue: 0,
      awaiting: 0,
      all_invoices: 0,
      sales: 0,
      get_started: false,
      progress: false,
      dashboard: {
        asset_accounts: {
          subtypes: [],
        },
        customers: [],
        suppliers: [],
        inventory: [],
      },
      invoice_graph: [],
      graph: [],
      expense_graph: [],
      sales_graph: {},
      total_expenses: 0,
      total_income: 0,
      total_profit: 0,

      show_invite_customers: false,
      invite_customer_suppliers: false,

      show_suppliers_invite: false,
      show_customers_invite: false,
      monthsList: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      selectedMonth: null,
      monthlyDash: null,
      isFirstRender: false,
      // ableToPlay: true,
      playInfo: undefined,
    };
  },
  computed: {

    yearsList() {
      let yearsArr = [];

      for (let i = this.maxYear; i >= this.minYear; i--) {

        yearsArr.push({
          text: i,
          value: i
        });

      }


      return yearsArr;

    },

    minYear() {

      return this.$store.state.user.user_infor.current_business.min_year;
    },
    maxYear() {

      return this.$store.state.user.user_infor.current_business.max_year;
    },
    ableToPlay() {
      return this.playInfo === undefined;
    },
    labelColors() {
      return this.isDark ? ["white"] : ["black"];
    },
    isDark() {
      if (localStorage.isDark === "false") {
        return "light";
      }
      return "dark";
    },
    fullMonthText() {
      let month = this.selectedMonth;
      let res;
      switch (month) {
        case "Jan":
          res = "January";
          break;
        case "Feb":
          res = "February";
          break;
        case "Mar":
          res = "March";
          break;
        case "Apr":
          res = "April";
          break;
        case "May":
          res = "May";
          break;
        case "Jun":
          res = "June";
          break;
        case "Jul":
          res = "July";
          break;
        case "Aug":
          res = "August";
          break;
        case "Sep":
          res = "September";
          break;
        case "Oct":
          res = "October";
          break;
        case "Nov":
          res = "November";
          break;
        case "Dec":
          res = "December";
          break;
        default:
          break;
      }
      return res;
    },
    dropdownItems() {
      return [this.selectedYear, ...this.monthsList];
    },
    thisMonth() {
      return {
        text: this.monthsList[new Date().getMonth()],
        number: this.monthsList.findIndex((month) => {
          return month === this.monthsList[new Date().getMonth()];
        }),
      };
    },
    IS_ADMIN() {
      return this.$store.state.user.user_infor.is_admin === 1;
    },
    user() {
      return this.$store.state.user;
    },
    affiliate_visible() {
      let closed = Boolean(localStorage.hide_affiliate);
      return !closed;
    },
    is_affiliate() {
      return this.$store.state.user.user_infor.is_affiliate === 1;
    },
    net_text() {
      return this.$t("dashboard.net");
    },
    gross_text() {
      return this.$t("dashboard.gross");
    },
    margin_text() {
      return this.$t("dashboard.margin");
    },
    totalDebits() {
      return this.$store.state.user.countryInfo.assetAccounts.subtypes[1]
          .balance;
    },
    totalCredits() {
      return this.$store.state.user.countryInfo.liabilityAccounts.subtypes[1]
          .balance;
    },
    totalInventory() {
      return this.$store.state.user.countryInfo.assetAccounts.subtypes[0]
          .balance;
    },
    totalNonOperating() {
      const accounts = this.incomeStatement.income.subtypes;
      return accounts.reduce(
          (a, c) => (c.name !== "Revenue" ? a + parseFloat(c.balance) : a),
          0
      );
    },
    totalOperatingExpense() {
      const accounts = this.incomeStatement.expense.subtypes;
      return accounts.reduce(
          (a, c) =>
              c.name === "Operating Expenses" ? a + parseFloat(c.balance) : a,
          0
      );
    },
    totalRevenue() {
      const incomeAccounts = this.incomeStatement.income.subtypes;
      return incomeAccounts.reduce(
          (a, c) => (c.name === "Revenue" ? a + parseFloat(c.balance) : a),
          0
      );
    },
    totalCostSale() {
      const accounts = this.incomeStatement.expense.subtypes;
      let sum = 0;
      accounts.forEach((account) => {
        if (account.name === "Cost of Sale") {
          account.accounts.forEach((ac) => (sum += parseFloat(ac.balance)));
        }
      });
      return sum;
    },
    netProfit() {
      return (
          this.totalNonOperating +
          this.totalRevenue -
          this.totalCostSale -
          this.totalOperatingExpense
      );
    },
    totalExpense() {
      return this.totalCostSale + this.totalOperatingExpense;
    },
    totalExpenseForPeriod() {
      switch (this.currentTab) {
        case "This year":
          const currentMonth = new Date().getMonth() + 1;
          return (this.totalExpense / currentMonth).toFixed(2);
        case "Last three months":
          return (this.totalExpense / 3).toFixed(2);
        case "This month":
        default:
          return this.totalExpense.toFixed(2);
      }
    },
    totalGross() {
      return this.totalRevenue - this.totalCostSale;
    },
    totalGrossProfitPercent() {
      const totalGross = this.totalRevenue - this.totalCostSale;
      const grossPercent = ((totalGross / this.totalRevenue) * 100).toFixed(2);
      if (!isFinite(grossPercent)) return "N/A";
      return isNaN(grossPercent) ? 0 : parseFloat(grossPercent);
    },
    totalNetProfitPercent() {
      const netPercent =
          (this.netProfit / (this.totalRevenue + this.totalNonOperating)) * 100;
      if (!isFinite(netPercent)) return "N/A";
      return isNaN(netPercent) ? 0 : parseFloat(netPercent.toFixed(2));
    },
    totalCashRunaway() {
      const cashBank = this.assetAccounts.subtypes.find(
          (account) => account.name === "Cash & Bank"
      );
      const balance = cashBank && cashBank.balance ? cashBank.balance : 0;
      const months = parseFloat(balance) / this.totalExpenseForPeriod;
      if (!isFinite(months)) return "N/A";
      return isNaN(months) || months < 1
          ? `< 1 month`
          : `${Math.floor(months)} months`;
    },
    timeToGetPaid() {
      const receivables = this.assetAccounts.subtypes.find(
          (account) => account.name === "Receivables"
      );
      const balance =
          receivables && receivables.balance ? receivables.balance : 0;
      const time = parseFloat(balance) / this.totalRevenue;
      if (!isFinite(time)) return "N/A";
      return `${Math.floor(time * 365)} days`;
    },
    tooltipTheme() {
      // return this.$vuetify.theme.dark ? "dark" : "dark";
      return "dark";
    },
  },
  methods: {
    getAccessToken,
    handlePeriodChange() {

      /**
       * this will keep the dashboard functional if the user decides to move between years
       */

      if (!this.monthsList.includes(this.selectedMonth)) {

        this.selectedMonth = this.selectedYear;

      }

      if (this.selectedMonth != this.selectedYear) {

        this.get_monthly_dashboard();

      } else {
        this.get_yearly_dashboard();
      }
      eventBus.$emit("yearChanged");
    },
    getUnrealisedGains(year) {
      this.loadingGains = true;

      axios
          .get("/api/v2/dashboard/" + year + "/unrealised-gains")
          .then((res) => {
            this.unrealisedGains = res.data;

            const Balance = res.data.balance;

            this.total_income += Balance;
            this.total_profit += Balance;
            this.loadingGains = false;
          })
          .catch(() => {
            this.loadingGains = false;
          });
    },
    async get_monthly_dashboard() {
      this.progress = true;

      this.series = []; //REFRESH SERIES FOR NEW DATA
      this.chartOptions.labels = [];
      const year = this.selectedYear ? this.selectedYear : this.$store.state.user.user_infor.current_business.current_year;
      let month = this.monthsList.indexOf(this.selectedMonth) + 1;


      this.fromDate = moment(year + "-" + month, "YYYY-MM").startOf("month").format("YYYY-MM-DD")
      this.toDate = moment(year + "-" + month, "YYYY-MM").endOf("month").format("YYYY-MM-DD")


      eventBus.$emit("monthly", this.selectedMonth);

      const url =
          "/api/v2/dashboard/month/" + year + "/" + month;
      let monthly = await axios.get(url);

      // FOR RIGHT SIDEBAR
      let yearlyDash = await axios.get("/api/v2/dashboard/" + year);
      this.dashboard = yearlyDash.data;

      this.total_income = this.dashboard.total_income;
      this.total_expenses = this.dashboard.total_expense;
      this.total_profit = this.dashboard.total_profit;
      this.paid = this.dashboard.invoice_summary.paid;
      this.awaiting = this.dashboard.invoice_summary.awaiting;
      this.all_invoices = this.dashboard.invoice_summary.all_invoices;
      this.all_bills = this.dashboard.all_bills;
      this.overdue = this.dashboard.invoice_summary.overdue;
      this.sales = this.dashboard.invoice_summary.sales;
      this.get_started = !this.dashboard.has_data;

      let data = monthly.data;
      this.monthlyDash = data;
      let monthIncomeSeriesObject = {
        name: "Income",
        color: "#2196F3",
        data: data.data.map((item) => item.income),
      };
      let monthExpensesSeriesObject = {
        name: "Expenses",
        color: "#F44336",
        data: data.data.map((item) => item.expense),
      };
      let monthProfitSeriesObject = {
        name: "Profit",
        color: "#4CAF50",
        data: data.data.map((item) => item.profit),
      };
      this.chartOptions.labels = data.data.map((item) =>
          this.splitDate(item.entry_date)
      );
      this.series.push(
          monthIncomeSeriesObject,
          monthExpensesSeriesObject,
          monthProfitSeriesObject
      );

      this.isFirstRender = false;
      this.progress = false;
    },
    async get_yearly_dashboard() {
      this.progress = true;
      this.series = []; //REFRESH SERIES FOR NEW DATA
      this.chartOptions.labels = [];
      const year = this.selectedYear;



      eventBus.$emit("yearly", year);

      this.fromDate = moment(year, "YYYY").startOf("year").format("YYYY-MM-DD")
      this.toDate = moment(year, "YYYY").endOf("year").format("YYYY-MM-DD")


      let yearlyDash = await axios.get("/api/v2/dashboard/" + year);
      this.dashboard = yearlyDash.data;

      this.total_income = this.dashboard.total_income;
      this.total_expenses = this.dashboard.total_expense;
      this.total_profit = this.dashboard.total_profit;
      this.paid = this.dashboard.invoice_summary.paid;
      this.awaiting = this.dashboard.invoice_summary.awaiting;
      this.all_invoices = this.dashboard.invoice_summary.all_invoices;
      this.all_bills = this.dashboard.all_bills;
      this.overdue = this.dashboard.invoice_summary.overdue;
      this.sales = this.dashboard.invoice_summary.sales;

      this.prep_pl_graph(this.dashboard.profit_loss);
      this.progress = false;
      this.get_started = !this.dashboard.has_data;
      this.getUnrealisedGains(year);
    },
    kpiWord(kpi) {
      return kpi < 0 ? "Loss" : "Profit";
    },
    formatExpense(expense, sum) {
      const {account_name, balance} = expense;
      const expensePercent = ((100 / sum) * balance).toFixed(2);
      const label = `${account_name} (${expensePercent}%)`;
      return [label, balance.toFixed(2)];
    },
    formatOtherExpense(sum, otherSum) {
      const percent = ((100 / sum) * otherSum).toFixed(2);
      return `Others (${percent}%)`;
    },
    prep_pl_graph(data) {
      const totalIncome = Array(12)
          .fill(0)
          .map((_, i) => data[i].income)
          .reduce((a, c) => a + c, 0);
      const totalExpense = Array(12)
          .fill(0)
          .map((_, i) => data[i].expense)
          .reduce((a, c) => a + c, 0);
      const totalProfit = Array(12)
          .fill(0)
          .map((_, i) => data[i].net_profit)
          .reduce((a, c) => a + c, 0);
      // MONTH + 2 INCLUDES THIS MONTH'S DETAILS
      const thisMonth = new Date().getMonth() + 2;
      const thisYear = new Date().getFullYear();

      this.graph = [];

      const incomeData = {};
      const expenseData = {};
      const profitData = {};
      this.chartOptions.labels = [];
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      months.forEach(
          (month, index) => (incomeData[month] = data[index].income.toFixed(2))
      );
      months.forEach(
          (month, index) => (expenseData[month] = data[index].expense.toFixed(2))
      );
      months.forEach(
          (month, index) =>
              (profitData[month] = data[index].net_profit.toFixed(2))
      );
      this.chartOptions.labels = months;

      const income_graph = {
        name: this.$t("dashboard.income"),
        color: "#2196F3",
        data: [
          data[0].income.toFixed(2),
          data[1].income.toFixed(2),
          data[2].income.toFixed(2),
          data[3].income.toFixed(2),
          data[4].income.toFixed(2),
          data[5].income.toFixed(2),
          data[6].income.toFixed(2),
          data[7].income.toFixed(2),
          data[8].income.toFixed(2),
          data[9].income.toFixed(2),
          data[10].income.toFixed(2),
          data[11].income.toFixed(2),
        ],
      };
      this.series.push(income_graph);

      let expense_total =
          data[0].expense +
          data[1].expense +
          data[2].expense +
          data[3].expense +
          data[4].expense +
          data[5].expense +
          data[6].expense +
          data[7].expense +
          data[8].expense +
          data[9].expense +
          data[10].expense +
          data[11].expense;

      let expense_graph = {
        name: this.$t("dashboard.expense"),
        color: "#F44336",

        data: [
          data[0].expense.toFixed(2),
          data[1].expense.toFixed(2),
          data[2].expense.toFixed(2),
          data[3].expense.toFixed(2),
          data[4].expense.toFixed(2),
          data[5].expense.toFixed(2),
          data[6].expense.toFixed(2),
          data[7].expense.toFixed(2),
          data[8].expense.toFixed(2),
          data[9].expense.toFixed(2),
          data[10].expense.toFixed(2),
          data[11].expense.toFixed(2),
        ],
      };

      this.series.push(expense_graph);
      let profit_total =
          data[0].net_profit +
          data[1].net_profit +
          data[2].net_profit +
          data[3].net_profit +
          data[4].net_profit +
          data[5].net_profit +
          data[6].net_profit +
          data[7].net_profit +
          data[8].net_profit +
          data[9].net_profit +
          data[10].net_profit +
          data[11].net_profit;

      let profit_graph = {
        name: this.$t("dashboard.net") + " " + this.$t("dashboard.profit"),
        color: "#4CAF50",
        data: [
          data[0].net_profit.toFixed(2),
          data[1].net_profit.toFixed(2),
          data[2].net_profit.toFixed(2),
          data[3].net_profit.toFixed(2),
          data[4].net_profit.toFixed(2),
          data[5].net_profit.toFixed(2),
          data[6].net_profit.toFixed(2),
          data[7].net_profit.toFixed(2),
          data[8].net_profit.toFixed(2),
          data[9].net_profit.toFixed(2),
          data[10].net_profit.toFixed(2),
          data[11].net_profit.toFixed(2),
        ],
      };

      this.series.push(profit_graph);
    },
    splitDate(date) {
      let day = date.split("-")[2];
      let month = date.split("-")[1];
      let year = date.split("-")[0];
      return `${this.monthsList[Number(month) - 1]}-${day}`;
    },
  },
  created() {
    if (this.user && !this.user.user_infor.has_business) {
      this.$router.push({path: "/onboard"});
    } else {
      //generate financial years based on the business entries

      let min_year = Number(
          this.$store.state.user.user_infor.current_business.min_year
      );
      let max_year = Number(
          this.$store.state.user.user_infor.current_business.max_year
      );

      let first_year = {
        value: this.$store.state.current_year,
        text: this.$store.state.current_year,
      };
      this.year_list.push(first_year);
      if (max_year > min_year) {
        // eslint-disable-next-line no-self-assign
        for (min_year = min_year; min_year <= max_year; min_year++) {
          let year = {value: min_year, text: min_year};
          this.year_list.push(year);
        }
      } else {
        let year = {value: min_year, text: min_year};
        this.year_list.push(year);
      }
    }
  },
  async mounted() {
    this.isFirstRender = true;
    this.advert = localStorage.no_ad2 !== "true";
    this.startdate = moment().format("YYYY-MM-DD");
    this.startdate = this.startdate.substring(0, 8) + "01";
    this.enddate = moment().format("YYYY-MM-DD");
    this.selectedMonth = moment().format("MMM");


    await this.get_monthly_dashboard();


    /**
     * listen for business changes using socket protocol
     */

    let ev = `CurrentBusinessChanged_${this.$store.state.user.user_infor.id}`;
    this.sockets.subscribe(ev, () => {
      this.$store.state.global_error_message =
          "Your current business has changed.";
      this.$store.state.global_error = true;
    });


  },
};
</script>

<style scoped>
.gauge-container {
  width: 20rem;
}

.inner-text {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.inner-text > p {
  font-size: 10px;
}

.inner-text > h3 {
  font-weight: 800;
  font-size: 14px;
}

.inner-text--1 > h3 {
  font-size: 20px;
}

.inner-text--1 > p {
  font-size: 15px;
}

.fixed-height-card {
  max-height: 500px;
  overflow-y: auto;
}

.donut-chart {
  width: 100%;
  height: 400px;
}

.highcharts-background {
  background-color: transparent !important;
}

.highcharts-container {
  background-color: transparent !important;
}

.inline {
  .select-box {
    max-width: 50px;
  }
}
</style>
