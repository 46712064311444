<template>
  <span>

   <v-row dense v-if="state && !state.finished">
        <v-col cols="12" sm="4">
        <v-btn color="grey" small text @click="$emit('back')"><v-icon>mdi-arrow-left</v-icon>Back</v-btn>
        </v-col>
         </v-row>

  <v-row v-if="state && !state.finished">
    <v-col class="mx-auto" cols="12" sm="6">
      <v-alert class="text-center" color="green lighten-5 green--text" dense>
        <h2>All Set!</h2>
        <p>
          You are at the final step of the onboarding wizard, to apply all uploaded records to your business click the
          apply transaction button.
        </p>
      </v-alert>

      <v-list>

        <v-list-item v-if="state" class="border">
          <v-list-item-content>
            <v-list-item-title>
              <h2>Accounts</h2>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ state.trial_balance ? state.trial_balance.length : 0 }} Uploaded
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-if="state.trial_balance && state.trial_balance.length" color="green"
                    x-large>mdi-playlist-check</v-icon>
            <v-icon v-else color="red" x-large>mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>


      </v-list>

      <v-btn :loading="loading" block color="green" dark depressed rounded x-large @click="applyState">Apply Transactions <v-icon
          x-large>mdi-check</v-icon></v-btn>
    </v-col>
  </v-row>

    <v-row v-else>
      <v-col class="mx-auto" cols="12" sm="6">
        <v-alert color="success lighten-5 green--text text-center mt-3">
  <lottie-animation
      ref="finishAnim"
      :animationData="require('@/assets/animations/success.json')"
      :autoPlay="true"
      :loop="false"
      style="height: 200px"
  />

          <h1 v-if="!progress">Welcome to Built</h1>
          <h5 v-if="!progress">You have successfully completed the onboarding process</h5>

        </v-alert>

        <v-btn v-if="!progress" @click="finish" block color="green" dark rounded depressed x-large>Go to dashboard <v-icon>mdi-arrow-right</v-icon></v-btn>
      </v-col>

    </v-row>

  </span>

</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import axios from "axios";

export default {
  name: "accountantFinishedComponenet",
  props:{
    userType:{
      default:"accountant"
    }
  },
  components: {
    LottieAnimation
  },
  data() {
    return {
      state: null,
      loading: false,
      progress:false
    }
  },
  methods: {
    finish(){
      window.location.reload();
    },
    applyState() {
      this.loading = true;

      axios.get("/api/onboard/apply/" + this.userType)
          .then(res => {
            this.loading = false;
            this.getState();
          })
          .catch(error => {
            this.loading = false;
          })
    },

    getState() {
      this.progress=true;
      axios.get("/api/onboard/state")
          .then(res => {
            this.state = res.data;
            this.progress=false;
          })
    }

  },
  mounted() {

    this.getState();
    this.$refs.finishAnim.play()

  }
}
</script>

<style scoped>

</style>