<template>
    <v-alert color="red lighten-5 red--text" type="warning" dark>
        <h3>{{$t('main.item_deleted_warning')}}</h3>
        <h6>{{$t('main.item_deleted_warning_info')}}</h6>
    </v-alert>
</template>

<script>
    export default {
        name: "itemDeletedComponent"
    }
</script>
