<template>
    <div>
        <NewTaxesAnnouncement></NewTaxesAnnouncement>

        <v-alert v-if="!whatsNew"  color="green lighten-5">
            <v-row>
                <v-col cols="12" sm="8">
                    <h4 class="font-weight-medium green--text"><v-icon color="green">info</v-icon> We have made some changes to improve your experience</h4>
                    <h5 class="font-weight-light green--text">{{user.changes}}</h5>
                </v-col>
            <v-col cols="12" sm="4">
                <v-btn color="green" dark @click="hardReload" >Reload page</v-btn>
            </v-col>
            </v-row>

        </v-alert>
    </div>
</template>

<script>
    import NewTaxesAnnouncement from "./NewTaxesAnnouncement";
    export default {
        name: "AnnouncementBar",
        components: {NewTaxesAnnouncement},
        methods:{
            hardReload(){

                localStorage.setItem('versionHash',this.user.versionHash);

                caches.keys().then(function (mainkeys) {

                mainkeys.forEach(mainkey=>{

               caches.open(mainkey)

                .then(function (cache) {

                    cache.keys().then(function (keys) {

                        keys.forEach(function (k) {
                            cache.delete(k);
                        })

                    });


                    window.location.reload(true);

                });

                    })

                });





            }

        },
        computed:{
            user(){
                return this.$store.state.user.user_infor;
            },
            whatsNew(){
                return true

            }
        }

    }
</script>

<style scoped>

</style>