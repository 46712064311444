<template>
 <span>

   <v-row dense>

     <v-col cols="12" sm="6" class="mx-auto">


       <v-card>
         <v-card-text>
         </v-card-text>
       </v-card>

     </v-col>
   </v-row>

   <v-dialog persistent v-model="showDialog" width="800">
     <v-card>
       <v-card-text class="pa-1">

       <v-row>
         <v-col class="6 green lighten-5 pa-5">

           <h2 class="font-weight-black ma-5">What you get:</h2>
           <h1 class="ma-5">Free <span class="green pa-1 font-weight-black white--text"
                                       style="display: inline; border-radius: 10px;">{{plan_name}}</span> subscription for 1 Month.</h1>

           <h5 class="ma-5 text-center">
             With this package you get free access to all features to accelerate your business towards 2023!
           </h5>
           <v-list class="transparent">

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                 Unlimited Invoicing
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                 Unlimited Expenses
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                 Financial Reports
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                Multiple Users
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                Online Payments
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                Online Quotes
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                Inventory Management
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>


             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                Multiple Currency
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                 File Attachments
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

             <v-list-item class="border-bottom">
               <v-list-item-content>
               <v-list-item-title>
                 POS
               </v-list-item-title>
               </v-list-item-content>
               <v-list-item-icon class="green lighten-4 pa-1" style="border-radius: 20px;">
                 <v-icon color="green darken-4">mdi-check</v-icon>
               </v-list-item-icon>
             </v-list-item>

           </v-list>
         </v-col>
         <v-col cols="6" class="black text-center">
           <v-img
               src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/Screenshot%202022-11-30%20at%203.43.57%20PM.png"></v-img>

           <center>
             <h2 style="border: 1px solid yellow; margin-top: 340px"
                 class="pa-2 display-1 font-weight-black white--text mt-4">Congratulation!!</h2>
             <p class="white--text ma-3">You just grabbed the</p>
           <v-img
               src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/logo_white.png"
               width="200"
           ></v-img>
             <h1 class="display-2 white--text mt-2 font-weight-light">
               <span class="white font-weight-black black--text"
                     style="display: inline; border-radius: 10px;">Black</span> Friday Christmas Goodie</h1>
           </center>

           <v-btn
               :loading="progress"
               @click="apply"
               class="mt-6"
               block
               x-large
               depressed
               rounded
               color="orange"
               dark
           >
             Take me along <v-icon>mdi-arrow-right</v-icon>
           </v-btn>

         </v-col>
       </v-row>
       </v-card-text>

     </v-card>
   </v-dialog>

    <v-snackbar v-model="error_message" color="red" :timeout=-1>

      {{ error_msg }}

    </v-snackbar>
 </span>
</template>

<script>
import moment from "moment";

export default {
  name: "blackFridayComponent",
  data() {
    return {
      afterTime: false,
      beforeTime: false,
      beforeBlackFriday: false,
      afterBlackFriday: false,
      showDialog: false,
      progress: false,
      success_message: false,
      success_msg: '',
      error_msg: '',
      error_message: false,

    }
  },
  computed: {
    business() {
      return this.$store.state.user.user_infor.current_business;
    },
    plan_name(){
      if (['gh','ke','sl'].includes(this.business.country.code.toLowerCase())){
        return "Enterprise"
      }

      return "Premium"

    }
  },
  methods: {
    apply() {

      if (this.business.subscription.name.toLowerCase() === "basic") {

        this.progress = true;

        let formData = new FormData();
        formData.append('startdate', moment().format('Y-M-D'));
        formData.append('enddate', moment().add(1, 'months').format('Y-M-D'));
        formData.append('amount', 0);
        formData.append('plan', this.plan_name.toLowerCase());
        formData.append('coupon_applied', false);
        formData.append('coupon_code', null);
        formData.append('billing_cycle', 1);
        axios.post('/api/appsubcription', formData)
            .then(res => {
              this.progress = false;
              this.complete = true;
              this.selected = false;
              this.$router.push({path: "/"});


              location.reload();
            })
            .catch(error => {
              this.progress = false;
              // this.error_msg = "Something went wrong, could not save your subscription, please try again";
              // this.error_message = true;
            });


      } else {

        this.$router.push({path: "/"});


        location.reload();

      }


    }
  },
  mounted() {


    // if (this.business.subscription.name.toLowerCase() !== "basic") {
    //
    //   this.$router.push({path: "/"});
    //   location.reload();
    //
    // }

    const code = this.$route.params.code;

    if (code !== "blkf-22") {

      this.$router.push({path: "/"});

    }

    if (this.$store.state.user_initialised && this.$store.state.user.user_infor) {

      this.afterTime = moment().isAfter('2022-11-30');
      this.beforeTime = moment().isBefore('2023-01-01');

      if (this.afterTime && this.beforeTime) {

        this.showDialog = true;

      } else {

        this.$router.push({path: "/"});
        location.reload();

      }


    } else {

      localStorage.setItem("promo_reserved", code);

    }

  }
}
</script>

<style scoped>

</style>