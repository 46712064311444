<template>
  <div>
    <v-menu
      v-model="menu"
      offset-x
      transition="slide-x-transition"
      max-width="400px"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="m-0"
          @click="
            notCount = 0;
            getNotifications();
            readAll();
          "
        >
          <v-badge :content="notCount" :value="notCount" color="red" overlap>
            <v-icon class="m-0">mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card style="max-height: 50vh; overflow: auto; width: 500px">
        <v-card-title>
          Notifications <v-spacer></v-spacer>
          <v-btn @click="menu = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text style="max-height: 100%; overflow: auto" v-if="!empty">
          <v-skeleton-loader
            v-if="progress"
            type="list-item@15"
          ></v-skeleton-loader>

          <!-- <v-list shaped v-if="!progress && notifications.all.length > 0">
                        <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-star-outline</v-icon>
                        </v-list-item-icon>
                      <v-list-item-content>
                            <v-list-item-title class="font-weight-bolder">General Notifications</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>

                        <v-list-item :class="notification.seen===0 ? 'seen':''" :to="notification.link" two-line v-for="notification in notifications.all" :key="notification.id">
                            <v-list-item-content>

                            <v-list-item-title>{{notification.title}}</v-list-item-title>
                           <v-list-item-subtitle>
                               {{notification.message}}
                           </v-list-item-subtitle>
                                <v-list-item-action-text>
                                  <v-icon x-small>mdi-clock-outline</v-icon> On {{notification.created_at}}
                                </v-list-item-action-text>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>
                        </v-list-item>


                    </v-list> -->

          <!-- <v-list shaped v-if="!progress && notifications.country.length > 0">
                        <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-flag-outline</v-icon>
                        </v-list-item-icon>
                      <v-list-item-content>
                            <v-list-item-title class="font-weight-bolder">Country Notifications</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>

                        <v-list-item :class="notification.seen===0 ? 'seen':''" :to="notification.link" two-line v-for="notification in notifications.country" :key="notification.id">
                            <v-list-item-content>

                            <v-list-item-title>{{notification.title}}</v-list-item-title>
                           <v-list-item-subtitle>
                               {{notification.message.substr(0,100)+"..."}}
                           </v-list-item-subtitle>
                                <v-list-item-action-text>
                                  <v-icon x-small>mdi-clock-outline</v-icon> On {{notification.created_at}}
                                </v-list-item-action-text>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>

                        </v-list-item>


                    </v-list> -->

          <!-- <v-list shaped v-if="!progress && notifications.user.length > 0">
                        <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-account-outline</v-icon>
                        </v-list-item-icon>
                      <v-list-item-content>
                            <v-list-item-title class="font-weight-bolder">Notifications for {{user.first_name}}</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>

                        <v-list-item :class="notification.seen===0 ? 'seen':''" :to="notification.link" two-line v-for="notification in notifications.user" :key="notification.id">
                            <v-list-item-content>

                            <v-list-item-title>{{notification.title}}</v-list-item-title>
                           <v-list-item-subtitle>
                               {{notification.message}}
                           </v-list-item-subtitle>
                                <v-list-item-action-text>
                                  <v-icon x-small>mdi-clock-outline</v-icon> On {{notification.created_at}}
                                </v-list-item-action-text>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>

                        </v-list-item>


                    </v-list> -->

          <v-list shaped v-if="!progress && notifications.length > 0">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-briefcase-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder"
                  >Notifications for {{ business.name }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :class="notification.seen === 0 ? 'seen' : ''"
              :to="notification.link"
              two-line
              v-for="notification in notifications"
              :key="notification.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ notification.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ notification.message }}
                </v-list-item-subtitle>
                <v-list-item-action-text>
                  <v-icon x-small>mdi-clock-outline</v-icon> On
                  {{ notification.created_at }}
                </v-list-item-action-text>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action> </v-list-item
            ><br />
            <v-btn
              x-small
              @click.prevent="loadMoreNotifs"
              block
              v-if="notificationsMeta.next_page_url"
              text
              color="blue"
              :loading="loadingMore"
              >Load more</v-btn
            >
          </v-list>
        </v-card-text>

        <v-card-text v-else>
          <center>
            <v-icon class="text--disabled" x-large>mdi-bell-off</v-icon>
            <h2>You have no notifications yet</h2>
          </center>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "notificationsComponent",
  data() {
    return {
      menu: false,
      notifications: null,
      notificationsMeta: null,
      progress: true,
      notCount: 0,
      loadingMore: false,
    };
  },
  computed: {
    business() {
      return this.$store.state.user.user_infor.current_business;
    },

    user() {
      return this.$store.state.user.user_infor;
    },
    empty() {
      return this.notifications && this.notifications.length === 0;
      // return this.notifications && (this.notifications.all.length ==0 &&
      //     this.notifications.user.length===0 &&
      //     this.notifications.business.length===0
      //     && this.notifications.country.length===0);
    },
  },
  methods: {
    readAll() {
      axios.get("/api/notifications/see/all").then((res) => {});
    },

    getNotifications() {
      this.progress = true;
      axios.get("/api/notifications").then((res) => {
        this.notifications = res.data.data;
        this.notificationsMeta = {
          next_page_url: res.data.next_page_url,
          current_page: res.data.current_page,
        };
        this.progress = false;

        // this.notCount = this.notifications.all.length;
        // this.notCount+=this.notifications.country.length;
        // this.notCount+=this.notifications.business.length;
        // this.notCount+=this.notifications.user.length;

        // let itemStockNotifications = this.notifications.business.filter(notif => notif.title === 'Item stock limit');
        // itemStockNotifications.forEach(notif => this.openNotification('top-right', 'primary', notif.title, notif.message));
      });
    },
    loadMoreNotifs() {
      this.loadingMore = true;
      axios.get(this.notificationsMeta.next_page_url).then((res) => {
        //this.notifications.join(res.data.data);

        res.data.data.forEach((data) => this.notifications.push(data));
        this.notificationsMeta = {
          next_page_url: res.data.next_page_url,
          current_page: res.data.current_page,
        };
        this.loadingMore = false;
      });
    },
    openNotification(position = null, color, title, text) {
      const noti = this.$vs.notification({
        sticky: true,
        color,
        position,
        title,
        text,
        duration: 6000,
        progress: "auto",
      });
    },
    getCount() {
      axios.get("/api/notifications/unread/count").then((res) => {
        this.notCount = res.data.unread_count;
      });
    },
  },

  mounted() {
    if (this.user.has_business) {
      let eventName = "ItemStockChannel_" + this.business.id;

      this.sockets.subscribe(eventName, (data) => {
        this.notifications.business.unshift(data);
        this.notCount += 1;
        this.openNotification(
          "top-right",
          "primary",
          data.data.title,
          data.message
        );
      });
      this.getCount();
    }
  },
};
</script>

<style scoped>
.seen {
  background-color: rgba(15, 16, 255, 0.02);
}
.v-card {
  display: flex !important;
  flex-direction: column;
}
.scroll {
  overflow: scroll;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
