<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attr }">
        <!--CHECK LEAVE AN INDICATOR FOR BUSINESSES-->
        <v-list-item
          v-on="on"
          v-bind="attr"
          class="border-bottom"
          @click="activateDialog = true"
        >
          <v-list-item-avatar>
            <v-img :src="business.logo"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ business.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ business.address }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <span>Click to activate</span>
    </v-tooltip>

    <v-dialog v-model="activateDialog" width="450px">
      <v-card>
        <v-card-title class="font-weight-light">Confirm Switch</v-card-title>
        <v-card-text class="p-3 text-dark"
          >This will switch your business to the one you have just
          selected.</v-card-text
        >
        <v-card-actions>
          <v-btn
            :loading="isActivateLoading"
            rounded
            block
            large
            depressed
            color="blue darken-4"
            dark
            @click="activateBusiness"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="hasSuccessMsg">
      {{ successMsg }}
      <v-btn color="success" text @click="success_message = false" timeout="360"
        >Close</v-btn
      >
    </v-snackbar>
    <v-snackbar v-model="hasErrorMsg" color="error">
      {{ errorMsg }}
      <v-btn color="light" text @click="hasErrorMsg = false" timeout="360"
        >Close</v-btn
      >
    </v-snackbar>
  </span>
</template>

<script>
export default {
  props: ["business"],
  name: "BusinessItem",
  data() {
    return {
      switchDialog: false,
      activateDialog: false,
      isActivateLoading: false,
      hasErrorMessage: false,
      errorMsg: "",
      hasSuccessMsg: false,
      successMsg: "",
      hasErrorMsg: false,
    };
  },
  methods: {
    async activateBusiness() {
      this.isActivateLoading = true;

      axios
        .get(`/api/activatebusiness/${this.business.id}`)
        .then((res) => {
          this.hasSuccessMsg = true;
          this.successMsg = `${this.business.name} was activated successfully`;
          location.reload();
          //this.$store.commit("init_user");
        })
        .catch((error) => {
          // this.hasErrorMessage = true;
          this.activateDialog = false;
          this.isActivateLoading = false;
          // this.errorMsg = "Something went wrong, could not activate business";
        });
    },
  },
};
</script>

<style scoped></style>
