<template>
  <span>
    <v-dialog
      persistent
      width="400"
      v-model="showDIalog"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <center>
            <v-img height="200" src="/img/hello.svg"></v-img>
            <h4>
              To better understand your needs and support you, could you tell us
              why you signed up.
            </h4>
          </center>
          <v-radio-group v-model="answer">
            <v-radio
              v-for="(q, i) in questions"
              :value="q"
              :key="i"
              :label="q"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions v-if="answer">
          <v-btn
            @click="answerQuestion"
            color="blue darken-4"
            dark
            rounded
            large
            block
            depressed
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success"
      >Your answer was sent successfully, thank you!</v-snackbar
    >
  </span>
</template>

<script>
import axios from "axios";

export default {
  name: "SingupSurvey",
  data() {
    return {
      success: false,
      showDIalog: false,
      answer: "",
      progress: false,
      questions: [
        "Just curious",
        "Finding an accountant",
        "Not happy with existing accounting software",
        "Just started a business",
      ],
    };
  },
  computed: {
    promoPlan() {
      const plan = localStorage.getItem("package");
      return plan;
    },
  },
  methods: {
    getStatus() {
      axios.get("/api/signup-survey/check").then((res) => {
        if (!this.promoPlan) {
          this.showDIalog = !res.data.answered;
        }
      });
    },
    answerQuestion() {
      this.showDIalog = false;

      axios
        .post("/api/signup-survey/answer", { answer: this.answer })
        .then((res) => {
          this.success = true;
        });
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>
