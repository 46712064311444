<template>
    <span>
        <extra-headers-component
            v-if="edit"
            :default-headers="headers"
            :show-headers="true"
            :editing="true"
            @done="headerDone"
        ></extra-headers-component>
        <v-simple-table
            class="table-sm"
            v-else-if="headers"
        >
            <tbody>
            <tr
                v-for="header in defaultHeaders"
                :key="header.id"
            >

                <td style="opacity: 0.7" class="text-right">
                  <strong>
                    {{ header.label }}
</strong>
                    {{ header.value ? header.value : '........................' }}
                </td>

            </tr>
            <tr v-if="show_edit">
                <td>
                    <v-btn
                        block
                        x-small
                        color="blue"
                        rounded
                        text
                        @click="edit=true"

                    >Change<v-icon x-small>mdi-pencil</v-icon></v-btn>
                </td>
            </tr>
            </tbody>
        </v-simple-table>

        <v-snackbar
            v-model="success"
            color="green"
            dark
        >{{ success_message }}</v-snackbar>
        <v-snackbar
            v-model="error"
            color="error"
            dark
        >{{ error_message }}</v-snackbar>

    </span>

</template>

<script>
import ExtraHeadersComponent from "./extraHeadersComponent";

export default {
  components: {ExtraHeadersComponent},
  props: ['headers', "documentType"],
  name: "headersView",
  data() {
    return {
      invoice_id: null,
      edit: false,
      defaultHeaders: [],
      show_edit: true,
      success: false,
      error: false,
      error_message: "",
      success_message: ""
    }
  },
  methods: {
    headerDone(headers) {

      this.defaultHeaders = headers.headers;
      this.edit = false;
      this.updateHeaders();
    },
    updateHeaders() {
      const formData = new FormData();

      formData.append('new_headers', JSON.stringify(this.defaultHeaders));

      const URL = this.documentType == "quote" ? '/api/quote/headers/' : '/api/invoice/headers/';

      axios.post(URL + this.invoice_id, formData)
          .then(res => {
            this.success_message = "Info updated successfully";
            this.success = true;
          })
          .catch(error => {
            // this.error_message = "Something went wrong, could not update info.";
            // this.error = true;

          })


    }
  },
  mounted() {


    if (this.headers.length) {

      if (this.documentType == "quote") {

        this.invoice_id = this.headers[0].quote_id;

      } else {
        this.invoice_id = this.headers[0].invoice_id;

      }

      this.defaultHeaders = this.headers;
    } else {
      this.show_edit = false
    }


  }
}
</script>

<style scoped>

</style>
