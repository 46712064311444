<template>

  <v-row>
    <v-col
        cols="12"
        sm="3"
      v-for="item in items"
        :key="item.id"
    >
      <tag-component
          :tag="item"
          :hidedelete="true"
          outlined
          @close="$emit('close')"
      ></tag-component>

    </v-col>
  </v-row>
</template>

<script>
import TagComponent from "../tags/tagComponent";
export default {
  components: {TagComponent},
  props:['items'],
  name: "SearchTags"
}
</script>

<style scoped>

</style>