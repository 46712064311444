<template>

  <div style="height: 50px;"
       v-touch="{
            up:()=>sheet=true
        }"
  >


    <center>
      <small class="text-muted">{{ $t('main.shortcut_hint') }}</small>
    </center>

    <v-bottom-sheet v-model="sheet" scrollable>
      <v-card>
        <v-card-title
            v-touch="{
                            down:()=>sheet=false
                        }"
            @click="sheet=false"
        >
          <div class="col-md-12 text-center">
            {{ $t('main.shortcut_menu') }}<br>
            <v-icon color="grey" large>mdi-chevron-down</v-icon>
          </div>

        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col
                cols="6"
                v-for="(link,index) in items"
                :key="index"
            >
              <v-card
                  rounded
                  flat
                  class="blue lighten-5 text-center"
                  :to="link.route"
                  @click="sheet=false"

              >
                <v-card-text>
                  <v-icon x-large color="blue">{{ link.icon }}</v-icon>
                  <h3 class="blue--text">{{ link.title }}</h3>
                </v-card-text>
              </v-card>

            </v-col>


            <v-col
                cols="6"
                v-if="this.currentBusiness && CAN_USE_WALLET"
            >
              <v-card
                  rounded
                  flat
                  class="blue lighten-5 text-center"
                  to="/wallet"
                  @click="sheet=false"

              >
                <v-card-text>
                  <v-icon x-large color="blue">mdi-bootstrap</v-icon>
                  <h3 class="blue--text">Wallet</h3>
                </v-card-text>
              </v-card>

            </v-col>

          </v-row>

          <v-subheader>Built Apps</v-subheader>
          <apps-card-component></apps-card-component>

        </v-card-text>
      </v-card>
    </v-bottom-sheet>

    <v-btn
        v-if="$vuetify.breakpoint.mobile && $route.path=='/'"
        fixed
        bottom
        left
        light
        @click="sheet=true"
        fab
        color="blue"
        dark

    >
      <v-icon> mdi-dots-horizontal</v-icon>

    </v-btn>
  </div>

</template>

<script>
import AppsCardComponent from "./AppsCardComponent";

export default {
  name: "shortcutMenuComponent",
  components: {AppsCardComponent},
  data() {
    return {
      sheet: false,

      items: [
        {
          icon: 'mdi-cash-multiple',
          title: this.$t('main.side_bar.income'),
          route: "/income",

        },
        {
          icon: 'mdi-cash-minus',
          title: this.$t('main.side_bar.expenses'),
          route: "/expense",


        },
        {
          icon: 'mdi-scale-balance',
          title: this.$t('main.side_bar.accounting'),
          route: "/accounting",

        },
        {
          icon: 'mdi-cloud-sync-outline',
          title: "Sync",
          route: "/accounting/sync",


        }, {
          icon: 'mdi-account-multiple-outline',
          title: "Payroll",
          route: "/payroll",


        },

      ]
    }
  },
  computed: {
    currentBusiness() {
      return this.$store.state.user.user_infor.current_business;
    },
    user() {
      return this.$store.state.user.user_infor;
    },
    CAN_USE_WALLET() {
      return this.user.is_admin && (this.currentBusiness.country_id === 81 || this.currentBusiness.country_id === 113) && (this.currentBusiness.currency.code === 'GHS' || this.currentBusiness.currency.code === 'KES')
    }
  }

}
</script>

<style scoped>

</style>
