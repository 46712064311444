<template>
  <v-row>
    <v-col cols="12" sm="12" class="mx-auto">
      <div class="upgrade-card" style="background-color: #0D2E57">
        <v-row dense align="center">
          <v-col cols="12" sm="12" class="upgrade-card__text">

            <div class="d-block">
              <h2 class="font-weight-black white--text" style="font-size: 2rem; font-weight: bolder">{{message.msgTitle}}</h2>
              <p v-if="$vuetify.breakpoint.mobile" class="font-weight-black white--text">{{message.msgBody}}</p>
              <p v-else class="font-weight-light white--text">{{message.msgBody}}</p>
              <v-btn
                  dark
                  color="green"
                  to="/plans"
                  depressed
                  rounded
                  large
                  :block="$vuetify.breakpoint.mobile"

              >Upgrade plan <v-icon>mdi-arrow-right</v-icon></v-btn>
            </div>
          </v-col>

        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "upgradeComponent",
  data() {
    return {
      defaultMessages: [
        {
          type: "bank_reconciliation",
          msgTitle: "Upgrade your plan to create a new reconciliation period!",
          msgBody:
            "Upgrade your subscription plan to create and manage your reconciliation periods on your bank accounts."
        }, {
          type: "billAttachment",
          msgTitle: "Upgrade your plan to manage attachments",
          msgBody:
            "Upgrade your subscription plan to attach files to this document"
        },
        {
          type: "customer_import",
          msgTitle: "Upgrade your plan to import customers from excel files ",
          msgBody:
            "Upgrade your subscription plan to easily import customers from excel files "
        },
        {
          type: "items_import",
          msgTitle: "Upgrade your plan to import items from excel files ",
          msgBody:
            "Upgrade your subscription plan to easily import items from excel files "
        },
        {
          type: "user",
          msgTitle: "Upgrade your plan to conveniently invite other users ",
          msgBody:
            "Upgrade your subscription plan to easily invite colleagues, accountants and other workmates"
        },
        {
          type: "journal",
          msgTitle: "Upgrade your plan to manage journals easily",
          msgBody:
            "Upgrading your plan will help you to create and easily manage journals and more"
        },
        {
          type: "account",
          msgTitle: "Upgrade your plan to manage and customise your accounts",
          msgBody:
            "Upgrade now and manage your assets, equity, expenses,income and liability accounts or create new accounts."
        },
        {
          type: "quote",
          msgTitle: "Upgrade your plan to create quotes/estimates",
          msgBody:
            "To create and send profesional quotes/estimates, please upgrade your plan"
        },
        {
          type: "expense_attachment",
          msgTitle: "Upgrade your plan to add attachments to your expenses",
          msgBody:
            "To attach reciepts and other files to expenses, pleasse upgrade your plan"
        },
        {
          type: "payroll",
          msgTitle: "Upgrade your plan to manage and pay your employees",
          msgBody:
            "To create employees, payruns and even pay your employees online, please upgrade your plan"
        },
        {
          type: "POS",
          msgTitle: "Upgrade your plan to access Built POS",
          msgBody:
            "Record your sales as quick as possible with our easy-to-use POS , please upgrade your plan"
        },
        {
          type: "unrealised_gains",
          msgTitle: "Upgrade your plan to view insights on your exchange rates",
          msgBody:
            "View your gains and losses on invoice in different currencies , please upgrade your plan"
        },
        {
          type: "fx",
          msgTitle: "Upgrade your plan to create invoices in different currencies",
          msgBody:
            "Create invoice in over 100 currencies , please upgrade your plan"
        } ,
        {
          type: "tags",
          msgTitle: "Upgrade your plan to manage your transaction tags",
          msgBody:
            "Tags are used to further group transactions for easy filtering and labeling, please upgrade your plan"
        } ,
        {
          type: "default",
          msgTitle: "Upgrade your plan to do more!",
          msgBody:
            "You are on the default plan, upgrade your account to do more"
        }
      ]
    };
  },
  props: {
    msgTitle: {
      type: String,
      default: null
    },
    msgBody: {
      type: String,
      default: null
    },
    upgradeType: String
  },
  computed: {
    message() {
      if (this.upgradeType) {
        return this.defaultMessages.find(msg => msg.type === this.upgradeType);
      }
      return {
        msgTitle: this.msgTitle,
        msgBody: this.msgBody
      };
    }
  }
};
</script>

<style scoped>
.upgrade-card {
  padding: 10px 20px;
  border-radius: 10px;

}
.upgrade-card__text {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
}
.text-heading {
  font-size: 16px;
}
.upgrade-icon {
  width: 6rem;
  height: 6rem;
  margin-right: 2rem;
  padding: 0.2rem;
  border-radius: 12px;
  background-color: rgba(0, 123, 255, 0.2);
}
@media (max-width: 900px) {
  .upgrade-card__text {
    padding: 1rem;
  }
}
</style>
