<template>
    <v-card-text class="section" style="max-height: 200px; overflow: auto;" >
        <v-skeleton-loader type="list-item@5" v-if="$store.state.loadingAssets"></v-skeleton-loader>
        <v-list v-else>

            <v-list-item
                    v-for="(account,index) in BankCashaccounts"
                    :key="account.id"
                    v-if="Number(account.balance) !==0"
                    @click="testaccount=account; showaccountdialog = true"
                    :id="`DboardAccount-${index}`"
                    class="border-bottom"
            >

              <v-list-item-avatar size="45" class="pa-2">
                <v-avatar size="65" color="blue lighten-5 blue--text" class="ma-3">
                  <v-icon class="ma-1" color="blue">mdi-bank-plus</v-icon>
                </v-avatar>
              </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-light" style="font-size: 0.9rem">{{account.account_name}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action-text class="font-weight-bold" style="font-size: 0.9rem">
                  {{account.balance | absolute | currency_symbol}}
                </v-list-item-action-text>


            </v-list-item>

        </v-list>

        <v-dialog
                v-model="preview_dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable
                persistent
        >
            <v-card flat>
                <v-toolbar
                        flat
                        class="d-print-none"
                >
                    <v-btn
                            icon
                            @click="preview_dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{$store.state.account.account_name}} - {{$t('accounts.transaction_statement')}}

                    <v-toolbar-items>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="invoice_temp" >
                    <accountpreviewComponent></accountpreviewComponent>
                </v-card-text>
            </v-card>
        </v-dialog>

        <account-dialog v-if="showaccountdialog" @dialogClosed="showaccountdialog = false" :account="testaccount"></account-dialog>
    </v-card-text>
</template>

<script>
    import accountpreviewComponent from "../patials/accountpreviewComponent";
    import accountDialog from '../patials/accountDialog';
    import LinkBankBTN from "../patials/LinkBankBTN.vue";
    export default {
        props: ['accounts'],
        name: "cashBankAccounts",
        components: {LinkBankBTN, accountpreviewComponent, accountDialog},
        data() {
            return {
                preview_dialog: false,
                showaccountdialog: false,
                testaccount: '',
              BankCashaccounts:[]
            }
        },
        methods: {
            reload_balance() {
                this.$store.state.loadingAssets = true;
                axios.get('/api/analytics/cash-bank/accounts')
                    .then(res => {

                        this.BankCashaccounts = res.data;
                        this.$store.state.loadingAssets = false;


                    })
                    .catch(() => {

                    });
            },
            show_preview(account) {

                this.$store.state.account = account;
                this.$store.state.totalBalance = account.balance;
                this.$store.state.accountId = account.id;
                this.$store.commit('get_account_transactions');
                this.$store.state.accountType = 'Assets';
                this.preview_dialog = true;

            }
        },
        mounted() {
            this.reload_balance();
        }
    }
</script>

<style>
    .section:hover::-webkit-scrollbar-thumb{
        display: block;

    }
    .section::-webkit-scrollbar-thumb{
        background-color: rgba(170, 171, 172, 0.8) !important;
        outline: 0;
        display: none;
        transition: 0.5s ease-in-out;


    }
    .section::-webkit-scrollbar{
        width: 0.3em;
    }
    .section::-webkit-scrollbar-thumb:hover{
        background-color: rgb(2, 29, 219) !important;
    }



</style>
