<template>
    <div class="m-5 p-5">

        <center>
            <h1 class="font-weight-bolder text-muted">{{header}}</h1>
            <small class="text-muted">{{details}}</small>
        </center>
    </div>
</template>

<script>
    export default {
        name: "cannotviewComponent",
        data(){
            return{
                header:'UNAUTHORISED!',
                details:'Your user role is not allowed to view this page'
            }
        }
    }
</script>

<style scoped>

</style>