<template>
  <span>
    <v-container v-for="alert in alerts" :key="alert.id" fluid>
      <v-alert
        v-if="alert.showing"
        prominent
        text
        :icon="typeMap[alert.type].icon"
        :style="typeMap[alert.type].style"
        :color="typeMap[alert.type].color"
      >
        <p v-html="alert.message"> </p>
      </v-alert>
    </v-container>
  </span>
</template>

<script>
import axios from "axios";

export default {
  name: "AlertBanner",
  props: ["currentRoute"],
  data() {
    return {
      alerts: [],
      showing: false,
      cachedMessages: {},
      typeMap: {
        information: {
          style: "border: 1px solid skyblue",
          icon: "mdi-information",
          color: "info",
        },
        error: {
          style: "border: 1px solid #fccfcf",
          icon: "mdi-alert-circle",
          color: "error",
        },
        warning: {
          style: "border: 1px solid #fcddc1",
          icon: "mdi-alert",
          color: "orange",
        },
        tutorial: {
          style: "border: 1px solid #A4D9D4",
          icon: "mdi-school",
          color: "teal",
        },
      },
    };
  },
  methods: {
    getRouteAlert(country) {
      if (this.currentRoute) {
        // if (this.cachedMessages && this.cachedMessages[this.currentRoute]) {
        //   this.alerts = this.cachedMessages[this.currentRoute];
        // } else {
          axios
            .get(
              `/api/app-alerts?name=${this.currentRoute.toLowerCase()}&country=${
                country ? country.country_id : null
              }`
            )
            .then((res) => {
              this.alerts = res.data;
              localStorage.setItem(
                "alertMessages",
                JSON.stringify({
                  ...JSON.parse(localStorage.getItem("alertMessages")),
                  [this.currentRoute]: res.data,
                })
              );
            })
            .catch((error) => {
              // console.log(error)
            });
        //}
      }
    },
  },
  computed: {
    currentBusiness() {
      return this.$store.state.user.user_infor.current_business;
    },
  },
  mounted() {
    this.getRouteAlert();
  },
  watch: {
    currentRoute() {
      this.alerts = [];
      this.cachedMessages = JSON.parse(localStorage.getItem("alertMessages"));
      this.getRouteAlert(this.currentBusiness);
    },
    currentBusiness() {
      this.alerts = [];
      this.cachedMessages = JSON.parse(localStorage.getItem("alertMessages"));
      this.getRouteAlert(this.currentBusiness);
    },
  },
};
</script>
