<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6" xs="12">
        <v-card flat class="mt-3 py-3 mr-2" height="450">
          <v-card-title>
            <h3
                class="font-weight-black d-none d-md-block text-capitalize"
                :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'"
            >
              Top Sales
            </h3>
            <h6
                class="font-weight-black d-md-none d-sm-block text-capitalize"
                :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'"
            >
              Top Sales
            </h6>
          </v-card-title>
          <v-card-text class="pa-0 text-center">

            <v-skeleton-loader
                type="card"
                v-if="!topSales || progress"
            ></v-skeleton-loader>

            <div v-else-if="topSales.length < 1">
              <norecords-component
                  title="No sales yet!"
                  description="Your top 5 sales would show here."
              ></norecords-component>
              <v-btn
                  @click.native="$store.state.newcashexpense = true"
                  to="/income/invoices/new"
                  color="blue darken-4"
                  rounded
                  x-small
                  text
              >Record a sale
              </v-btn>
            </div>
            <span v-else>

            <v-list-item link v-for="(item, index) in topSales" :key="index"  class=" text-left" style="border-bottom: 1px solid rgba(211,211,211,0.29)">
              <v-list-item-avatar rounded>
                <v-img :src="item.photo"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                    <v-list-item-title
                        class="font-weight-black"
                        style="font-size: 0.9rem;"
                    >
                      {{ item.name }}
                    </v-list-item-title>
                <v-list-item-subtitle :title="item.sales_amount">
                        {{
                    Number(item.sales_amount)
                        | short_number
                        | currency_symbol
                  }}
                      ({{ calculatePercentage(item) }}%)

                </v-list-item-subtitle>

                  <v-list-item-subtitle>
                      <v-progress-linear
                          rounded
                          color="blue"
                          :value="calculatePercentage(item)"
                          style="padding: 0 !important; margin: 0 !important"
                      ></v-progress-linear>
                  </v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>
            </span>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" xs="12">
        <v-card flat class="mt-3 py-3 ml-1" height="450">
          <v-card-title class="font-weight-light">
            <h3 class="font-weight-black d-none d-md-block text-capitalize">
              {{ $t("dashboard.expense_title") }}
            </h3>
            <h6 class="font-weight-black d-md-none d-sm-block text-capitalize">
              {{ $t("dashboard.expense_title") }}
            </h6>
          </v-card-title>
          <v-skeleton-loader
              type="card"
              v-if="!series.length && progress"
          ></v-skeleton-loader>
          <v-card-text class="pa-0 text-center">
            <apexchart
                v-if="!progress && series.length"
                type="donut"
                height="350"
                :options="chartOptions"
                :series="series"
            ></apexchart>

            <div v-else-if="series.length < 1 && !progress">
              <norecords-component
                  :title="$t('dashboard.no_expense')"
                  description="Your top expenses would show here"
              ></norecords-component>
              <v-btn
                  @click="$store.state.newcashexpense = true"
                  to="/expense/paidexpenses"
                  color="blue darken-4"
                  rounded
                  x-small
                  text
              >{{ $t("dashboard.record_first_expense") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NorecordsComponent from "../sync/norecordsComponent";
import eventBus from "../../utils";
import moment from "moment/moment";

export default {
  props: {
    from: {
      type: String
    },
    to: {
      type: String
    }
  },
  name: "expenses",
  components: {NorecordsComponent},
  data() {
    return {
      progress: false,
      expenseAccounts: [],
      series: [],
      chartLabels: [],
      chartOptions: {
        labels: [],
        fill: {
          type: "gradient",
        },
        chart: {
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        tooltip: {
          enabled: true,
          y: {
            show: true,
            formatter: (val) => {
              val = this.$options.filters.currency_symbol(
                  this.$options.filters.toMoney(val)
              );
              return val;
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      analyticsData: null,
    };
  },
  computed: {
    topSales() {
      let data = this.analyticsData;
      if (data) {
        let results = data.filter((item) => {
          return item.sales_amount > 0;
        });
        return results;
      }
    },
    current_year() {
      return this.$store.state.current_year;
    },
    labels() {
      return this.chartLabels;
    },
    expense_graph() {
      const expens = this.expenseAccounts;

      const limit = 5;
      let totalExpenses = 0;
      expens.forEach((item) => {
        if (item.balance) {
          totalExpenses += item.balance;
        }
      });

      const sortedExpenses = expens
          .filter((e) => Number(e.balance) != 0)
          .sort((a, b) => Number(b.balance) - Number(a.balance));
      const topExpenses = [];
      let otherExpenseTotal = 0;
      sortedExpenses.forEach((expense, i) => {
        if (expense.account_name) {
          if (i < limit) {
            topExpenses.push(
                this.formatExpense(expense, Number(totalExpenses))
            );
          } else {
            otherExpenseTotal += Number(expense.balance);
          }
        }
      });
      const otherExpense = [
        this.formatOtherExpense(totalExpenses, otherExpenseTotal),
        otherExpenseTotal.toFixed(2),
      ];
      return otherExpenseTotal > 0
          ? [...topExpenses, otherExpense]
          : [...topExpenses];
    },
    business() {
      return this.$store.state.user.user_infor.current_business;
    },
    TOTAL_SALE() {
      let sum = 0;
      if (this.analyticsData) {
        this.analyticsData.forEach((item) => {
          sum += Number(item.sales_amount);
        });
        return sum;
      }
      return sum;
    },
  },
  watch: {
    from() {
      if (this.from && this.to) {
        this.get_expense();
        this.getTopSales();
      }
    }

  },
  methods: {
    calculatePercentage(item) {
      let perc = (item.sales_amount / this.TOTAL_SALE) * 100;
      return Math.ceil(Number(perc));
    },
    formatExpense(expense, sum) {
      const {account_name, balance} = expense;
      const expensePercent = ((100 / sum) * balance).toFixed(2);
      const label = `${account_name} (${expensePercent}%)`;
      return [label, balance.toFixed(2)];
    },

    formatOtherExpense(sum, otherSum) {
      const percent = ((100 / sum) * otherSum).toFixed(2);
      return `Others (${percent}%)`;
    },

    cache_accounts() {
      localStorage.setItem(
          "accounts." + this.$store.state.user.user_infor.current_business.id,
          JSON.stringify(this.$store.state.user.countryInfo)
      );
    },
    async get_expense() {
      this.progress = true;
      let expenseByRange = await axios.get(
          `/api/v2/dashboard-expenses-by-range?from=${this.from}&to=${this.to}`
      );
      this.expenseAccounts = expenseByRange.data;
      this.series = this.expense_graph.map((list) => Number(list[1]));
      this.chartOptions.labels = this.expense_graph.map(
          (list) => list[0].split(" ")[0]
      );
      this.progress = false;
    },
    getTopSales() {
      this.progress = true;
      axios
          .get(`/api/analytics/top-sales/${this.from}/${this.to}`)
          .then((res) => {
            this.analyticsData = res.data;
            this.progress = false;
          })

    },
    transformProducts(products) {
      return products.map((product) => {
        return {
          name: product.name,
          purchases: product.purchase_amount,
          quantity_purchased: product.purchase_quantity,
          sales_amount: product.invoice_amount + product.sales_amount,
          quantity_sold:
              Number(product.sales_quantity) + Number(product.invoice_quantity),
          margin: (
              product.invoice_amount +
              product.sales_amount -
              product.purchase_amount
          ).toFixed(2),
        };
      });
    },
  },

};
</script>

<style>
rect {
  fill: none !important;
}
</style>
