<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          :to="'/payroll/payrun/'+item.id"
          class="border"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/payroll/payrun/'+item.id)"
      >
        <v-list-item-content>
          <v-list-item-title class="font-weight-black">{{item.total_net | toMoney | currency_symbol}} </v-list-item-title>

          <v-list-item-subtitle>
            Created At:{{item.created_at | humanDate}}
          </v-list-item-subtitle>

            <v-list-item-subtitle>
            Run Date At:{{item.run_date | humanDate}}
          </v-list-item-subtitle>


        </v-list-item-content>
      </v-list-item>

    </v-col>
    <v-col cols="12" sm="4">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
              large
              text
              rounded
              color="blue"
              to="/payroll/employees"
              block
              @click="$emit('close'); $router.push('/payroll/payruns')"
          >Go to Payruns <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>

</template>

<script>
export default {
  props:['items'],
  name: "PayrunSearch"
}
</script>

<style scoped>

</style>