<template>
  <span>
    <v-skeleton-loader type="avatar" v-if="progress"></v-skeleton-loader>
    <vs-avatar-group max="2" v-else id="userTeamAvarters">
      <span v-for="user in teamMembers" :key="user.id">
        <vs-avatar
          pointer
          :color="randomColor()"
          :title="user.first_name + ' ' + user.last_name"
          v-if="user.photo"
          @click="showDetails = true"
        >
          <img :src="user.photo" :alt="user.first_name" />
        </vs-avatar>

        <vs-avatar
          pointer
          :color="randomColor()"
          :title="user.first_name + ' ' + user.last_name"
          v-else
          @click="showDetails = true"
        >
          <template #text>
            {{ user.first_name }}
          </template>
        </vs-avatar>
      </span>
    </vs-avatar-group>

    <v-dialog
      v-if="business"
      scrollable
      :fullscreen="$vuetify.breakpoint.mobile"
      v-model="showDetails"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          Team members
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="red lighten-5 red--text"
            @click="showDetails = false"
            rounded
            small
            >close<v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row
            dense
            :class="
              $vuetify.theme.isDark ? 'black white--text' : 'grey lighten-5'
            "
          >
            <v-col cols="12">
              <strong>Team:</strong>
            </v-col>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="4">
                  <v-img :src="`${baseUrl}${business.logo}`"></v-img>
                </v-col>
                <v-col cols="8">
                  <h2>{{ business.name }}</h2>
                  <p class="mt-0 mb-0">{{ business.address }}</p>
                  <p class="mt-0 mb-0">{{ business.email }}</p>
                  <p class="mt-0 mb-0">{{ business.phone_number }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            dense
            class="mt-4"
            :class="
              $vuetify.theme.isDark ? 'black white--text' : 'grey lighten-5'
            "
          >
            <v-col cols="12">
              <strong>Members:</strong>
            </v-col>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="6" v-for="user in teamMembers" :key="user.id">
                  <vs-card
                    class="card-text-full-with"
                    type="4"
                    :class="$vuetify.theme.isDark ? 'dark_card_bg' : ''"
                  >
                    <template #title>
                      <h3>{{ user.first_name }} {{ user.last_name }}</h3>
                    </template>
                    <template #img>
                      <img
                        v-if="user.photo"
                        :src="baseUrl + '' + user.photo"
                        :alt="user.first_name"
                      />
                      <img v-else src="/img/user.png" :alt="user.first_name" />
                    </template>
                    <template #text>
                      <p>Email:{{ user.email }}</p>
                      <p>Phone:{{ user.phone_number }}</p>
                    </template>
                    <template #interactions>
                      <vs-button
                        v-if="user.invite && user.invite.accountant_id"
                        class="btn-chat"
                        success
                      >
                        <span class="span"> Accountant </span>
                      </vs-button>

                      <vs-button
                        v-if="user.invite && !user.invite.accountant_id"
                        class="btn-chat"
                        warn
                      >
                        <span class="span"> User </span>
                      </vs-button>
                    </template>
                  </vs-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--    <tour-component-->
    <!--      v-if="teamMembers.length && !$vuetify.breakpoint.mobile"-->
    <!--      target="#userTeamAvarters"-->
    <!--      index="userTeamAvarters_index"-->
    <!--      description="Find your team members here, hover to see their names and  click for more details"-->
    <!--    ></tour-component>-->
  </span>
</template>

<script>
import TourComponent from "../tour/TourComponent";

export default {
  name: "teamAvarters",
  components: { TourComponent },
  data() {
    return {
      teamMembers: [],
      progress: false,
      showDetails: false,
    };
  },
  computed: {
    baseUrl() {
      return this.$store.state.baseURL;
    },
    business() {
      return this.$store.state.user.user_infor.current_business;
    },
  },
  methods: {
    randomColor() {
      const colors = ["primary", "success", "danger", "warn", "dark"];
      const index = Math.floor(Math.random() * 4);

      if (index < 5 && index >= 0) {
        return colors[index];
      }
      return "dark";
    },

    getTeam() {
      this.progress = true;
      axios.get("/api/user/team").then((res) => {
        this.teamMembers = res.data;
        this.progress = false;
      });
    },
  },
  mounted() {
    this.getTeam();
  },
};
</script>

<style>
.dark_card_bg div.vs-card {
  background-color: #000000 !important;
  max-width: 100% !important;
}

.dark_card_bg div.vs-card__text {
  background-color: #0000008b !important;
  max-width: 100% !important;
  color: #ffffff;
}

.card-text-full-with div.vs-card__text {
  width: 100% !important;
}
</style>
