<template>
  <div class="card col-md-9 mx-auto" :class="$vuetify.theme.isDark ? 'bg-black' : 'bg-white'" v-if="reminder_plan">

    <v-card flat class="border-bottom" v-for="reminder in reminder_plan" :key="reminder.id">
      <v-card-text>
        <v-card-text>
          <v-list-item class="mb-3 border">
            <v-list-item-icon>
              <v-chip
                  v-if="reminder.active"
                  color="green lighten-5"
                  class="green--text"
              >
                {{ $t('invoice.active') }}
                <v-icon>mdi-check</v-icon>
              </v-chip>

              <v-chip
                  v-else
                  color="red lighten-5"
                  class="red--text"
              >
                {{ $t('invoice.inactive') }}
                <v-icon>mdi-close</v-icon>
              </v-chip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><h2 class="font-weight-light">{{ $t('invoice.reminder_info') }}:
                {{ getReminderType(reminder) }}</h2></v-list-item-title>
              <v-list-item-subtitle>
                <strong
                    v-if="reminder.reminder_type === 'beforedue' || reminder.reminder_type === 'lateoverdue'">{{ $t('invoice.frequency') }}: </strong>
                <span v-if="reminder.reminder_type === 'beforedue' || reminder.reminder_type === 'lateoverdue'">
                          {{ $t('invoice.every') }} {{ reminder.frequency }} {{ reminder.frequency_type }} &nbsp; | &nbsp;</span>
                <span v-if="reminder.reminder_type === 'beforedue' || reminder.reminder_type === 'lateoverdue'">{{
                    reminder.limit > 0 ? reminder.limit : $t('invoice.unlimited')
                  }} {{ $t('invoice.times') }} &nbsp; | &nbsp;</span><br>
                <strong>{{ $t('invoice.date_scheduled') }}: </strong> {{
                  reminder.created_at | humanDate
                }}
                &nbsp; | &nbsp; <br>
                <strong
                  v-if="reminder.next_reminder_date">{{ $t('invoice.next_reminder_date') }}: </strong>
                {{ reminder.next_reminder_date | humanDate }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action v-if="reminder.active">
              <v-btn
                  depressed
                  rounded
                  x-large
                  @click="deactivate_dialog=true; selected_reminder = reminder"
                  color="error"
                  dark
              >{{ $t('invoice.deactivate') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <!-- <v-alert  :color="$store.state.selected_invoice.invoice_color" dark>

              <h2 class="font-weight-bold">{{$t('main.note')}}:</h2>
              <li>{{$t('invoice.recurring_note1')}}</li>
              <li>{{$t('invoice.recurring_note2')}}</li>
              <li>{{$t('invoice.recurring_note3')}}</li>
          </v-alert> -->


        </v-card-text>
      </v-card-text>
    </v-card>
    <v-dialog v-model="deactivate_dialog" width="500">
      <v-card>
        <v-card-title class="font-weight-light">
          {{ $t('invoice.confirm_reminder_deactivate') }}
        </v-card-title>
        <v-card-text>
          <h3 class="font-weight-light">{{ $t('invoice.reminder_warning') }} </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deactivate_reminder" :loading="deactivating" dark rounded block depressed x-large
                 color="error">{{ $t('invoice.deactivate_reminder') }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'reminderViewComponent',
  data() {
    return {
      deactivate_dialog: false,
      deactivating: false,
      selected_reminder: '',
    }
  },

  computed: {
    reminder_plan() {
      return this.$store.state.selected_invoice.reminder_plan;
    }
  },
  methods: {
    getReminderType(reminder) {
      if (reminder.reminder_type === 'beforedue') return 'Before Due';
      if (reminder.reminder_type === 'whendue') return 'When Due';
      if (reminder.reminder_type === 'earlyoverdue') return 'Early Overdue';
      if (reminder.reminder_type === 'lateoverdue') return 'Late Overdue';
    },
    deactivate_reminder() {
      this.deactivating = true;
      axios.post(`/api/deactivate-reminder`, {
        invoice_id: this.selected_reminder.invoice_id,
        reminder_type: this.selected_reminder.reminder_type
      })
          .then(res => {
            axios.get(`/api/invoice/${this.$store.state.selected_invoice.id}`)
                .then(res => {

                  this.$store.state.selected_invoice = res.data;
                  this.deactivating = false;
                  this.deactivate_dialog = false;
                })
                .catch(err => {
                  this.deactivating = false;
                });
          })
    },
  }
}
</script>
