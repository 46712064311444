<template>
  <v-card>
    <v-alert type="info" color="blue lighten-5 blue--text">
      To guarantee delivery, ensure To select the right country code.<br />
      Do not add a leading 0 to the phone number eg. enter something like
      <strong>23350xxxxxxx</strong> not <strong>233050xxxxxxx</strong>
    </v-alert>
    <phone-number-input
      :isEmailLogin="true"
      @input="(n) => (user_number = n)"
      :key="invoice.uuid"
      :default_number="phone_number"
    ></phone-number-input>

    <v-list>
      <v-list-item class="border">
        <v-list-item-icon>
          <v-icon color="blue" x-large>mdi-message-text-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Send via SMS</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-checkbox v-model="smsChecked" color="green"></v-checkbox>
        </v-list-item-action>
      </v-list-item>

      <v-list-item class="border mt-2" disabled>
        <v-list-item-icon>
          <v-icon color="green" x-large>mdi-whatsapp</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Send via WhatsApp</v-list-item-title>
          <v-list-item-subtitle class="red--text"
            ><v-icon>mdi-info-circle</v-icon>Currently
            Unavailable</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action>
          <v-checkbox v-model="whatsappChecked" color="green"></v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-card-actions>
      <v-btn
        x-large
        :disabled="!smsChecked && !whatsappChecked"
        :loading="sending"
        @click="send"
        color="blue darken-4"
        :dark="smsChecked || whatsappChecked"
        rounded
        block
        depressed
      >
        {{ $t("main.send") + " " + $t("main.invoice") }}
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="success">{{ success_message }}</v-snackbar>
    <v-snackbar v-model="error" color="red" dark>{{
      error_message
    }}</v-snackbar>
  </v-card>
</template>

<script>
import PhoneNumberInput from "../agents/PhoneNumberInput";
export default {
  components: { PhoneNumberInput },
  props: ["invoice"],
  name: "SendSMSCOmponent",
  data() {
    return {
      smsChecked: true,
      phone_number: "",
      sending: false,
      user_number: "",
      success_message: "",
      success: false,
      error: false,
      error_message: "",
      whatsappChecked: false,
    };
  },
  computed: {
    invoiceID() {
      return this.invoice.id;
    },
  },
  watch: {
    invoiceID() {
      this.phone_number = this.invoice.customer.business_phone;
    },
  },
  methods: {
    send() {
      this.sending = true;

      const formdata = new FormData();
      formdata.append("recipient_number", this.user_number);
      formdata.append("invoice_id", this.invoice.uuid);

      let url = "/api/send/invoice/sms";

      url = url + "?wb=" + this.whatsappChecked + "&sms=" + this.smsChecked;

      axios
        .post(url, formdata)
        .then((res) => {
          this.success_message = "Invoice sent to " + this.user_number;
          this.success = true;
          this.sending = false;
          this.$emit("closed");
        })
        .catch((error) => {
          // this.error_message =
          //   "Something went wrong, we could not send the invoice, please try again";
          // this.error = true;
          this.sending = false;
        });
    },
  },
  mounted() {
    if (
      this.invoice.customer.business_phone &&
      this.invoice.customer.business_phone.length > 9
    ) {
      this.phone_number = this.invoice.customer.business_phone.substr(-9);
    } else {
      this.phone_number = this.invoice.customer.business_phone;
    }
  },
};
</script>

<style scoped>
</style>