<template>
  <div>
    <el-tiptap
      v-model:content="content"
      :extensions="extensions"
      :placeholder="placeholder"
      @blur="emitBlur"
    />
    <p class="caption grey--text ma-2" v-if="hint && !tiptapError">
      {{ hint }}
    </p>
    <p class="caption red--text ma-2" v-if="tiptapError">
      This field is required
    </p>
  </div>
</template>

<script type="text/javascript">
import {
  Bold,
  BulletList,
  Doc,
  ElementTiptap,
  Heading,
  Italic,
  ListItem,
  OrderedList,
  Paragraph,
  Text,
  TextAlign,
  TextColor,
  Underline,
} from "element-tiptap";
import { debounce } from "lodash";

export default {
  name: "RichTextEditor",
  props: {
    value: {
      type: String,
      default: "",
    },
    tiptapError: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default: "Enter section content",
    },
    hint: {
      type: String,
      default: "Press Enter to add a new line",
    },
  },
  components: {
    "el-tiptap": ElementTiptap,
  },
  data() {
    return {
      content: "",
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5, bubble: true }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new ListItem({ bubble: true }),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TextColor({ bubble: true }),
        new TextAlign({ bubble: true }),
      ],
    };
  },
  methods: {
    emitBlur() {
      this.$emit("blur");
    },
  },
  watch: {
    value(newValue) {
      if (newValue !== this.content) {
        this.content = newValue;
      }
    },
    content(newValue) {
      this.$emit("input", newValue);
      if (this.content !== this.value) {
        this.debouncedEmitChange();
      }
    },
  },
  created() {
    this.debouncedEmitChange = debounce(() => {
      this.$emit("change", this.content);
    }, 2000);
  },
  mounted() {
    this.content = this.value;
  },
};
</script>

<style>
.el-tiptap-editor__footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.el-tiptap-editor__menu-bar {
  display: none !important;
}
.theme--dark .el-tiptap-editor__footer {
  background-color: #1e1e1e !important;
}
.theme--dark .el-tiptap-editor__content {
  background-color: #1e1e1e !important;
  color: white !important;
}
.el-tiptap-editor__content {
  border-bottom: 1px solid #ebeef5 !important;
  border-top: 1px solid #ebeef5 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.theme--dark .el-tiptap-editor__menu-bar {
  background-color: #1e1e1e !important;
}
.theme--dark .el-tiptap-editor__command-button {
  color: white !important;
}
.theme--dark .el-tiptap-editor__command-button:hover {
  color: black !important;
}
.theme--dark .el-tiptap-editor__menu-bubble {
  background-color: #1e1e1e !important;
}

.theme--dark .el-tiptap-editor__characters {
  color: white !important;
}
</style>
