<template>
  <v-row>
    <v-overlay v-if="!$store.state.accountTransactions">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>

    <v-col v-else class="mb-5" cols="12" sm="12">
      <v-card flat style="width: 100%; margin-bottom: 100px">
        <v-card-title class="font-weight-light">
          <v-text-field
            v-model="search"
            :placeholder="$t('main.search')"
            clearable
            hide-details
            filled
            flat
            rounded
            prepend-inner-icon="search"
            @input="debounceSearch"
            @click:clear="
              show_preview();
              searchOn = false;
            "
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290px"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDate"
                :disabled="filter"
                :label="$t('main.from')"
                class="mt-3"
                filled
                rounded
                flat
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            ref="menu1"
            v-model="menu2"
            :close-on-content-click="false"
            max-width="290px"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="toDate"
                :disabled="filter"
                :label="$t('main.to')"
                class="ml-3 mt-3"
                filled
                rounded
                flat
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>

          <v-btn
            v-if="!filter"
            :loading="$store.state.loading"
            class="ml-2"
            color="blue darken-4"
            dark
            x-large
            rounded
            depressed
            @click="filterTransactions"
          >
            {{ $t("main.filter") }}
          </v-btn>

          <v-btn
            v-else
            class="ml-2"
            color="red"
            dark
            depressed
            rounded
            x-large
            @click="
              show_preview();
              filter = false;
            "
          >
            Clear filter
          </v-btn>

          <v-menu
            v-if="filter"
            v-model="exportMenu"
            :close-on-content-click="false"
            max-width="290px"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn class="ml-3" color="blue" text v-on="on">
                {{ $t("main.export") }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :href="printURL"
                class="border-bottom"
                target="_blank"
              >
                <v-list-item-title> Print </v-list-item-title>
              </v-list-item>

              <v-list-item :href="PDFURL" class="border-bottom">
                <v-list-item-title> PDF </v-list-item-title>
              </v-list-item>

              <v-list-item :href="XLXURL">
                <v-list-item-title> Excel </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>

        <v-card-text v-if="!hideBalances">
          <div
            :class="
              $vuetify.theme.isDark ? 'bg-dark p-4' : 'grey lighten-5 p-4'
            "
            style="border-radius: 15px"
          >
            <account-summaries-component
              :account_id="$store.state.accountId"
              :from-date="summariesFromDate"
              :filter="filter"
              :to-date="summariesToDate"
            ></account-summaries-component>
          </div>
        </v-card-text>

        <v-data-table
          ref="supplier_list"
          :fixed-header="true"
          :headers="headers"
          :items="$store.state.accountTransactions.data"
          :items-per-page="-1"
          :loading="$store.state.loading"
          :loading-text="$t('accounts.loading_text')"
          class="table-striped"
          hide-default-footer
          fixed-header
          height="90vh"
        >
          <template v-slot:item.credit_amount="{ item }">
            <span v-if="Number(item.credit_amount) === 0"> - </span>
            <span v-else>
              <span v-if="Number(item.rate) !== 1">
                <h4 class="mb-0 pb-0">
                  {{ item.fx_currency }}{{ item.fx_amount | toMoney
                  }}<sup
                    ><transaction-anchor-component
                      :transaction="item"
                    ></transaction-anchor-component
                  ></sup>
                </h4>

                <small class="d-block"
                  >{{ item.credit_amount | toMoney | currency_symbol }} |
                  Rate:{{ item.rate }}</small
                >
              </span>
              <span v-else>
                <h4 class="mb-0 pb-0">
                  {{ item.credit_amount | toMoney | currency_symbol
                  }}<sup
                    ><transaction-anchor-component
                      :transaction="item"
                    ></transaction-anchor-component
                  ></sup>
                </h4>
              </span>
            </span>
          </template>

          <template v-slot:item.debit_amount="{ item }">
            <span v-if="Number(item.debit_amount) === 0"> - </span>
            <span v-else>
              <span v-if="Number(item.rate) !== 1">
                <h4 class="mb-0 pb-0">
                  {{ item.fx_currency }}{{ item.fx_amount | toMoney
                  }}<sup
                    ><transaction-anchor-component
                      :transaction="item"
                    ></transaction-anchor-component
                  ></sup>
                </h4>

                <small class="d-block"
                  >{{ item.debit_amount | toMoney | currency_symbol }} | Rate:{{
                    item.rate
                  }}</small
                >
              </span>
              <span v-else>
                <h4 class="mb-0 pb-0">
                  {{ item.debit_amount | toMoney | currency_symbol
                  }}<sup
                    ><transaction-anchor-component
                      :transaction="item"
                    ></transaction-anchor-component
                  ></sup>
                </h4>
              </span>
            </span>
          </template>

          <template v-slot:item.balance="{ item }">
            <h4 class="mb-0 pb-0">
              {{ item.balance | absolute | currency_symbol }}
            </h4>
          </template>

          <template v-slot:item.entry_date="{ item }">
            <ResolveLedgerComponent :ledger="item"></ResolveLedgerComponent>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | humanDateTime }}
          </template>
        </v-data-table>

        <v-card-actions v-if="!searchOn">
          <v-container>
            <v-row justify="center">
              <v-col cols="3">
                <small
                  >{{ $tc("main.page", 1) }}
                  {{ $store.state.accountTransactions.current_page }}
                  of
                  {{ $store.state.accountTransactions.last_page }}</small
                >
              </v-col>
              <v-col cols="1">
                <v-btn
                  :disabled="
                    $store.state.accountTransactions.prev_page_url == null
                  "
                  icon
                  small
                  @click="prev_page()"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="$store.state.accountTransactions.current_page"
                  :disabled="$store.state.loading"
                  :items="pages"
                  :loading="$store.state.loading"
                  item-text="text"
                  item-value="value"
                  @change="
                    go_Topage($store.state.accountTransactions.current_page)
                  "
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-btn
                  :disabled="
                    $store.state.accountTransactions.next_page_url == null
                  "
                  icon
                  small
                  @click="next_page()"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ResolveLedgerComponent from "./ResolveLedgerComponent";
import moment from "moment";
import TransactionAnchorComponent from "./transactionAnchorComponent";
import AccountSummariesComponent from "./AccountSummariesComponent";

export default {
  name: "accountpreviewComponent",
  props: ["from", "to", "hideBalances"],
  components: {
    AccountSummariesComponent,
    TransactionAnchorComponent,
    ResolveLedgerComponent,
  },
  data() {
    return {
      exportMenu: false,
      menu1: false,
      menu2: false,
      filter: false,
      fromDate: "",
      toDate: "",
      error_message: false,
      error_msg: "",
      success_message: false,
      toast_msg: "",
      range_dialog: false,
      progress: false,
      delete_dialog: false,
      leisure_id: null,
      description: "",
      searchOn: false,
      search: "",
    };
  },
  computed: {
    businessCurrency() {
      return this.$store.state.user.user_infor.current_business.currency;
    },
    summariesFromDate() {
      return this.filter ? this.fromDate : null;
    },
    summariesToDate() {
      return this.filter ? this.toDate : null;
    },

    accountID() {
      return this.$store.state.accountId;
    },

    business() {
      return this.$store.state.user.user_infor.current_business;
    },

    headers() {
      return [
        {
          text: this.$t("main.date"),
          sortable: true,
          value: "entry_date",
        },
        {
          text: this.debitHeader,
          value: "debit_amount",
          align: "right",
        },
        {
          text: this.creditHeader,
          value: "credit_amount",
          align: "right",
        },
        {
          text: this.$t("main.balance"),
          value: "balance",
          align: "right",
        },
        {
          text: this.$t("main.description"),
          value: "description",
        },
        {
          text: "Created At",
          value: "created_at",
        },
      ];
    },

    is_BankCash() {
      let accounts =
        this.$store.state.user.countryInfo.assetAccounts.subtypes[2].accounts;

      const isBank = accounts.find((account) => {
        return account.id === this.$store.state.accountId;
      });

      return Boolean(isBank);
    },

    creditHeader() {
      return this.is_BankCash ? "Outflows" : this.$tc("journal.credit");
    },

    debitHeader() {
      return this.is_BankCash ? "Inflows" : this.$tc("journal.debit");
    },

    printURL() {
      return (
        this.$store.state.baseURL +
        "/" +
        "viewaccounttranactions?bid=" +
        this.business.enc_id +
        "&ac_id=" +
        this.$store.state.accountId +
        "&page=" +
        this.$store.state.accountTransactions.current_page +
        "&from=" +
        this.fromDate +
        "&to=" +
        this.toDate
      );
    },
    /**
     * @return {string}
     */
    PDFURL() {
      return (
        this.$store.state.baseURL +
        "/" +
        "downloadstatementPDF?bid=" +
        this.business.enc_id +
        "&ac_id=" +
        this.$store.state.accountId +
        "&page=" +
        this.$store.state.accountTransactions.current_page +
        "&from=" +
        this.fromDate +
        "&to=" +
        this.toDate
      );
    },

    /**
     * @return {string}
     */
    XLXURL() {
      return (
        this.$store.state.baseURL +
        "/" +
        "downloadtransactionsspreadsheat?bid=" +
        this.business.enc_id +
        "&ac_id=" +
        this.$store.state.accountId +
        "&page=" +
        this.$store.state.accountTransactions.current_page +
        "&from=" +
        this.fromDate +
        "&to=" +
        this.toDate
      );
    },

    balance() {
      if (
        this.$store.state.accountType === "Asset" ||
        this.$store.state.accountType === "Assets" ||
        this.$store.state.accountType === "Expense"
      ) {
        return this.TotalDebits - this.TotalCredits;
      }

      return this.TotalCredits - this.TotalDebits;
    },
    /**
     * @return {number}
     */
    TotalCredits() {
      if (!this.$store.state.accountTransactions) {
        return 0;
      }

      let sum = 0;

      this.$store.state.accountTransactions.data.forEach((ledger) => {
        if (ledger.credit_amount) {
          sum += Number(ledger.fx_credit);
        }
      });

      return sum.toFixed(2);
    },

    /**
     * @return {number}
     */
    TotalDebits() {
      if (!this.$store.state.accountTransactions) {
        return 0;
      }

      let sum = 0;

      this.$store.state.accountTransactions.data.forEach((ledger) => {
        if (ledger.debit_amount) {
          sum += Number(ledger.fx_debit);
        }
      });

      return sum.toFixed(2);
    },

    pages() {
      const p = [];
      for (
        let i = 1;
        i <= this.$store.state.accountTransactions.last_page;
        i++
      ) {
        p.push({
          value: i,
          text: "Page " + i,
        });
      }
      return p;
    },
  },
  watch: {
    accountID() {
      if (this.from && this.to) {
        this.fromDate = this.from;
        this.toDate = this.to;

        this.filterTransactions();
      }
    },
  },
  mounted() {
    this.fromDate = moment().format("YYYY-MM-DD");
    this.toDate = moment().format("YYYY-MM-DD");

    if (this.from && this.to) {
      this.fromDate = this.from;
      this.toDate = this.to;

      this.filterTransactions();
    }
  },
  methods: {
    show_preview() {
      this.$store.commit("get_account_transactions");
    },
    filterTransactions() {
      this.$store.state.loading = true;
      axios
        .get(
          "/api/transactions/filter/" +
            this.$store.state.accountId +
            "?from=" +
            this.fromDate +
            "&to=" +
            this.toDate
        )
        .then((res) => {
          this.$store.state.accountTransactions = res.data;
          this.$store.state.loading = false;
          this.filter = true;
        });
    },

    debounceSearch() {
      if (!this.search) {
        this.searchOn = false;
        this.show_preview();
        return false;
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchTransactions();
      }, 1000);
    },
    searchTransactions() {
      this.$store.state.loading = true;
      axios
        .get(
          "/api/transactions/search/" +
            this.$store.state.accountId +
            "?keyword=" +
            this.search
        )
        .then((res) => {
          this.$store.state.accountTransactions.data = res.data;
          this.$store.state.loading = false;
          this.searchOn = true;
        });
    },

    next_page() {
      this.$store.state.loading = true;
      let url = this.filter
        ? this.$store.state.accountTransactions.next_page_url +
          "&from=" +
          this.fromDate +
          "&to=" +
          this.toDate
        : this.$store.state.accountTransactions.next_page_url;
      axios.get(url).then((res) => {
        this.$store.state.accountTransactions = res.data;

        this.$store.state.loading = false;
      });
    },
    prev_page() {
      this.$store.state.loading = true;
      let url = this.filter
        ? this.$store.state.accountTransactions.prev_page_url +
          "&from=" +
          this.fromDate +
          "&to=" +
          this.toDate
        : this.$store.state.accountTransactions.prev_page_url;
      axios.get(url).then((res) => {
        this.$store.state.accountTransactions = res.data;

        this.$store.state.loading = false;
      });
    },

    go_Topage(page) {
      this.$store.state.loading = true;
      let url = this.filter
        ? this.$store.state.accountTransactions.path +
          "?page=" +
          page +
          "?from=" +
          this.fromDate +
          "&to=" +
          this.toDate
        : this.$store.state.accountTransactions.path + "?page=" + page;
      axios.get(url).then((res) => {
        this.$store.state.accountTransactions = res.data;

        this.$store.state.loading = false;
      });
    },

    view_transactions() {
      window.open(
        this.$store.state.baseURL +
          "/" +
          "viewaccounttranactions?bid=" +
          this.$store.state.user.user_infor.current_business.enc_id +
          "&ac_id=" +
          this.$store.state.accountId +
          "&from=" +
          this.fromDate +
          "&to=" +
          this.toDate
      );
    },
  },
};
</script>

<style scoped></style>
