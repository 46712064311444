<template>
	<v-list>
		<v-subheader>Other Apps</v-subheader>

		<v-list-item :href="posURL" style="text-decoration: none" target="_blank">
			<v-list-item-icon>
				<span class="pa-3 orange lighten-5" style="border-radius: 10px">
					<v-icon class="orange--text" x-large>mdi-cart-outline</v-icon>
				</span>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title class="font-weight-bold">
					POS<sup><v-icon x-small>mdi-arrow-right-top</v-icon></sup>
				</v-list-item-title>
				<v-list-item-subtitle
					>Sell and receive instant payments on store
					front</v-list-item-subtitle
				>
			</v-list-item-content>
		</v-list-item>

		<v-list-item
			style="text-decoration: none"
			target="_blank"
			href="https://payroll.built.africa"
		>
			<v-list-item-icon>
				<span class="pa-3 green lighten-5" style="border-radius: 10px">
					<v-icon class="green--text" x-large
						>mdi-account-multiple-outline</v-icon
					>
				</span>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title class="font-weight-bold">
					Employee Payroll<sup
						><v-icon x-small>mdi-arrow-right-top</v-icon></sup
					>
				</v-list-item-title>
				<v-list-item-subtitle>Employee payroll dashboard</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script>
import { getAccessToken } from "../../utils";
export default {
	name: "AppsCardComponent",
	computed: {
		posURL() {
			return "https://pos.built.africa/?s=" + getAccessToken();
		},
	},
};
</script>

<style scoped></style>
