<template>
    
        <v-tour
         name="SideDrawerTour" 
         :steps="steps"
         >
        

            <template slot-scope="tour">
                
                <transition name="fade">
                    
                    <v-step
                            v-if="tour.steps[tour.currentStep]"
                            :key="tour.currentStep"
                            :step="tour.steps[tour.currentStep]"
                            :previous-step="tour.previousStep"
                            :next-step="tour.nextStep"
                            :stop="tour.stop"
                            :skip="tour.skip"
                            :is-first="tour.isFirst"
                            :is-last="tour.isLast"
                            :labels="tour.labels"
                             class="blue font-weight-light"
                    >
                    
                        <template>
                            <div slot="actions">
                                <v-btn @click="tour.stop(); turnOff() " text x-small dark>Close, Don't SHow Again</v-btn>
                              <v-btn v-if="!tour.isLast" @click="tour.nextStep" depressed dark text rounded>Next<v-icon>mdi-arrow-right</v-icon></v-btn>

                            </div>
                        </template>

                         <template>
                            <div slot="header">
                               <h5 class="font-weight-bold">{{tour.steps[tour.currentStep].header.title}}</h5>
                               </div>
                        </template>
                    </v-step>
                </transition>
            </template>

        </v-tour>
</template>
<script>
export default {
    name:"SideDrawerTour",
     data () {
            return {


                steps: [
                    {
                        target: '#incomeSidemenu',

                         header: {
                                title: 'Income Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        },
                        content: `Manage income related records like invoices, quick sales, etc.`
                    },
                    {
                        target: '#expenseSidemenu',
                        content: 'Manage expense related records like paid expenses, bills, tax payments, etc ',
                           header: {
                                title: 'Expense Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        },
                    },
                        {
                        target: '#productsSidemenu',
                        content: 'Manage the products and services that you buy and sell',
                           header: {
                                title: 'Products and Services Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        },
                    },
                    {
                        target: '#accountingSidemenu',
                        content: 'Perform account related actions such as bank transfers, chart of accounts and manual journals',
                           header: {
                                title: 'Accounting Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        },
                    },

                  {
                        target: '#financeSidemenu',
                        content: 'Easily apply for credit for your business',
                           header: {
                                title: 'Financial Service Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        },
                    },
                        {
                        target: '#ReportsSidemenu',
                        content: 'View and export your reports such as Income statements, cashflow and other financial reports',
                           header: {
                                title: 'Reports Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        }
                    },
                      {
                        target: '#SyncSidemenu',
                        content: 'Move transactions from other e-Banking platforms into your account.',
                           header: {
                                title: 'Sync Transactions Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        }
                    }
                    ,
                      {
                        target: '#SettingsSidemenu',
                        content: 'Manage your business settings.',
                           header: {
                                title: 'Settings Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        }
                    }
                        ,
                      {
                        target: '#AffiliateSidemenu',
                        content: 'Join our affiliate program, get paid when you refer your freinds.',
                           header: {
                                title: 'Affiliate Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        }
                    },
                      {
                        target: '#HelpSidemenu',
                        content: 'Need help or clarifications, talk to us.',
                           header: {
                                title: 'Help Menu',
                            },
                             params: {
                            placement: 'right',
                            highlight: true
                        }
                    }


                ]
            }
        },
        methods:{
            turnOff(){
                localStorage.setItem('SideDrawerTour',true);
            }
        },
        mounted () {
            if (!localStorage.SideDrawerTour) {
                
                this.$tours['SideDrawerTour'].start()
                }
            }

}
</script>
<style>
    .v-step__arrow--dark{
        background-color:#2196F3 !important ;
    }

</style>