<template>

<v-menu  transition="scale-transition" max-width="400">
<template v-slot:activator="{on}">

  <v-btn
      icon
    v-on="on"
  >
    <v-icon>apps</v-icon>
  </v-btn>
</template>
<apps-card-component></apps-card-component>

</v-menu>


</template>

<script>
import AppsCardComponent from "./AppsCardComponent";
export default {
  name: "AppsMenu",
  components: {AppsCardComponent},

}
</script>

<style scoped>

</style>