<template>
<v-row>
  <v-col cols="12" sm="4" v-for="item in items" :key="item.id">
  <proccessed-list-item-component @close="$emit('close')" :item="item"></proccessed-list-item-component>
  </v-col>
</v-row>

</template>

<script>

import ProccessedListItemComponent from "../sync/proccessedListItemComponent";
export default {
  components: {ProccessedListItemComponent},
  props:['items'],
  name: "SearchSyncIncome"
}
</script>

<style scoped>

</style>