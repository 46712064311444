<template>
  <span>

  <v-row v-if="state && !state.finished">
    <v-col class="mx-auto" cols="12" sm="6">
      <v-alert class="text-center" color="green lighten-5 green--text" dense>
        <h2>All Set!</h2>
        <p>
          You are at the final step of the onboarding wizard, to apply all uploaded records to your business click the
          apply transaction button.
        </p>
      </v-alert>

      <v-list>

        <v-list-item v-if="state" class="border">
          <v-list-item-content>
            <v-list-item-title>
              <h2>Customers</h2>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ state.customers ? state.customers.length : 0 }} Uploaded
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-if="state.customers && state.customers.length" color="green"
                    x-large>mdi-account-multiple-check</v-icon>
            <v-icon v-else color="red" x-large>mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>

            <v-list-item v-if="state" class="border">
          <v-list-item-content>
            <v-list-item-title>
              <h2>Suppliers</h2>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ state.suppliers ? state.suppliers.length : 0 }} Uploaded
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-if="state.suppliers && state.suppliers.length" color="green"
                    x-large>mdi-account-multiple-check</v-icon>
            <v-icon v-else color="red" x-large>mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>

       <v-list-item v-if="state" class="border">
          <v-list-item-content>
            <v-list-item-title>
              <h2>Products & Services</h2>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ state.items ? state.items.length : 0 }} Uploaded
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-if="state.items && state.items.length" color="green" x-large>mdi-basket-check</v-icon>
            <v-icon v-else color="red" x-large>mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>


       <v-list-item v-if="state" class="border">
          <v-list-item-content>
            <v-list-item-title>
              <h2>Cash & Bank Accounts</h2>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ state.cash_bank ? state.cash_bank.length : 0 }} Uploaded
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-if="state.cash_bank && state.cash_bank.length" color="green" x-large>mdi-bank-check</v-icon>
            <v-icon v-else color="red" x-large>mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>


      </v-list>

      <v-btn :loading="loading" block color="blue darken-4" dark depressed rounded x-large @click="applyState">Apply Transactions <v-icon
          x-large>mdi-check</v-icon></v-btn>
    </v-col>
  </v-row>

    <v-row v-else>
      <v-col class="mx-auto" cols="12" sm="6">
        <v-alert color="success lighten-5 green--text text-center mt-3">
  <lottie-animation
      ref="anim"
      :animationData="require('@/assets/animations/success.json')"
      :autoPlay="true"
      :loop="false"
      style="height: 200px"
  />

          <h1 v-if="!progress">Welcome to Built</h1>
          <h5 v-if="!progress">You have successfully completed the onboarding process</h5>

                  <v-btn class="mt-5" v-if="!progress" @click="finish" block color="green" dark rounded depressed x-large>Go to dashboard <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-alert>

      </v-col>

    </v-row>

  </span>

</template>

<script>
import axios from "axios";
import LottieAnimation from 'lottie-web-vue'

export default {

  props:{
    userType:{
      default:"business_owner"
    }
  },
  name: "BusinessOwnerFinalStep",
  components: {
    LottieAnimation
  },
  data() {
    return {
      state: null,
      loading: false,
      progress:false
    }
  },
  methods: {
    finish(){
      window.location.assign("/");
    },
    applyState() {
      this.loading = true;

      axios.get("/api/onboard/apply/" + this.userType)
          .then(res => {
            this.loading = false;
            this.getState();
          })
          .catch(error => {
            this.loading = false;
          //  window.location.reload();


          })

    },
    getState() {
      this.progress=true;
      axios.get("/api/onboard/state")
          .then(res => {
            this.state = res.data;
            this.progress=false;
          })
    }
  },
  mounted() {
    this.getState();
    this.$refs.anim.play()

  }
}
</script>

<style scoped>

</style>