<template>
  <div>
    <v-select
      @change="persist_language()"
      flat
      filled
      v-model="lang"
      :items="langs"
      class="mb-0"
      dense
      rounded
      label="Language"
    >
      <template v-slot:item="{ item }">
        {{ full_languages[item] }}
        <sup class="text-success ml-1 text-small">{{
          item !== "en" ? "BETA" : ""
        }}</sup>
      </template>

      <template v-slot:selection>
        {{ full_languages[lang] }}
        <sup class="text-success ml-1 text-small">{{
          lang !== "en" ? "BETA" : ""
        }}</sup></template
      >
    </v-select>
  </div>
</template>

<script>
import { languages } from "../../i18n";

export default {
  name: "LanguagesComponents",
  data() {
    return {
      langs: languages,
      full_languages: {
        en: "English",
        fr: "French",
        ar: "Arabic",
      },
    };
  },
  methods: {
    get_flag(lang) {
      return require("@/assets/flags/" + lang + ".svg");
    },
    persist_language() {
      localStorage.setItem("lang", this.lang);
      location.reload();
    },
  },
  computed: {
    lang: {
      get: function () {
        return this.$store.state.locale;
      },
      set: function (newVal) {
        this.$store.dispatch("CHANGE_LOCALE", newVal);
      },
    },
    flag() {
      return require("@/assets/flags/" + this.lang + ".svg");
    },
  },
};
</script>

<style scoped></style>