<template>
  <v-card flat  class="mt-3" style="overflow: auto; height: 570px">

    <v-card-title class=" py-4">
      <h3 class="font-weight-black d-none d-md-block">{{ $t('dashboard.kpi_title') }}</h3>
      <v-spacer></v-spacer>
      <v-btn to="/analytics" depressed outlined color="blue darken-4" rounded dark large>Go to Analytics
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>

    </v-card-title>
    <v-card-text  class="pb-13">


      <v-row dense class="px-2">
        <v-col cols="12" md="6" lg="6">
          <v-select
              v-model="currentTab"
              :items="[$t('dashboard.kpi_this_year'), $t('dashboard.kpi_last_three_months'), $t('dashboard.kpi_this_month') ]"
              filled
              rounded
              :loading="loadingKPIs"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-skeleton-loader
          transition="scale-transition"
          type="card"
          :loading="loadingKPIs"
      >
        <v-row class="px-2">
          <v-col cols="12" md="6" lg="6" v-if="incomeStatement!=null">
            <div>
              <h3 class="my-5 text-center">{{ $t('dashboard.gross') }} {{ kpiWord(totalGrossProfitPercent) }}
                {{ margin_text }}</h3>
              <VueSvgGauge
                  :start-angle="-110"
                  :end-angle="110"
                  :min="0"
                  :max="100"
                  :value="Number(totalGrossProfitPercent)"
                  :separator-step="0"
                  :scale-interval="0"
                  :inner-radius="80"
              >
                <div class="inner-text">
                  <p class="m-0">{{ $t('dashboard.gross') }} {{ kpiWord(totalGross) }}</p>
                  <h3 class="m-0 mb-2">{{ Math.abs(totalGross) | short_number | currency_symbol }}</h3>
                  <p class="m-0">{{ $t('dashboard.gross') }} {{ kpiWord(totalGross) }} {{ $t('dashboard.margin') }}</p>
                  <p class="m-0">{{ totalGrossProfitPercent }} %</p>
                </div>
              </VueSvgGauge>
            </div>
          </v-col>

          <v-col cols="12" md="6" lg="6" v-if="incomeStatement!=null">
            <div>
              <h3 class="my-5 text-center">{{ net_text }} {{ kpiWord(totalNetProfitPercent) }}
                {{ $t('dashboard.margin') }}</h3>
              <VueSvgGauge
                  :start-angle="-110"
                  :min="0"
                  :max="100"
                  :end-angle="110"
                  :value="totalNetProfitPercent"
                  :separator-step="20"
                  :scale-interval="25"
                  :inner-radius="80"
                  :gauge-color="[{ offset: 0, color: '#FFD200DB'}, { offset: 100, color: '#8CDFAD'}]"
              >
                <div class="inner-text">
                  <p class="m-0">{{ net_text }} {{ kpiWord(netProfit) }}</p>
                  <h3 class="m-0 mb-2">{{ Math.abs(netProfit) | short_number | currency_symbol }}</h3>
                  <p class="m-0">{{ net_text }} {{ kpiWord(totalNetProfitPercent) }} {{ margin_text }}</p>
                  <p class="m-0">{{ totalNetProfitPercent }} %</p>
                </div>
              </VueSvgGauge>
            </div>
          </v-col>

          <v-col cols="12" md="6" lg="6" class="mt-5" v-if="currentTab !== 'This month'">
            <div v-if="incomeStatement!=null">
              <h3 class="my-5 text-center">{{ $t('dashboard.average_monthly_expense') }}</h3>
              <div class="inner-text inner-text--1">
                <h3 class="m-0 mt-1">{{ totalExpenseForPeriod | short_number | currency_symbol }}</h3>
                <p class="m-0">{{ $t('dashboard.per_month') }}</p>
              </div>
            </div>
          </v-col>


          <v-col cols="12" md="6" lg="6" class="mt-5" v-if="currentTab !== 'This month'">
            <div v-if="incomeStatement!=null">
              <h3 class="my-5 text-center">{{ $t('dashboard.cash') }} {{ $t('dashboard.runway') }}</h3>
              <div class="inner-text inner-text--1">
                <h3 class="m-0 mt-1">{{ totalCashRunaway }}</h3>
              </div>
            </div>
          </v-col>

        </v-row>

      </v-skeleton-loader>
    </v-card-text>

  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "KPIsComponent",
  data() {
    return {
      progress: false,
      expenseAccounts: null,
      incomeStatement: null,
      startdate: null,
      enddate: null,
      currentTab: "This month",
      loadingKPIs: false,
      bankAccounts:[]
    }
  },
  computed: {
    BankAndCashBalance(){

      let balance = 0;
      this.bankAccounts.forEach(acc => {
        balance += Number(acc.balance);
      });
      return balance;
    },
    net_text() {
      return this.$t('dashboard.net');
    },
    gross_text() {
      return this.$t('dashboard.gross');
    },

    margin_text() {
      return this.$t('dashboard.margin');

    },
    totalDebits() {
    //  return this.$store.state.user.countryInfo.assetAccounts.subtypes[1].balance;
      return 0
    },
    totalCredits() {
     // return this.$store.state.user.countryInfo.liabilityAccounts.subtypes[1].balance;
      return 0

    },
    assetAccounts() {
      return this.$store.state.user.countryInfo.assetAccounts;
    },
    totalInventory() {
     // return this.$store.state.user.countryInfo.assetAccounts.subtypes[0].balance;
      return 0

    },
    totalNonOperating() {
      const accounts = this.incomeStatement ? this.incomeStatement.income.subtypes : [];
      return accounts.reduce((a, c) => c.name !== "Revenue" ? a + Number(c.balance) : a, 0)
    },
    totalOperatingExpense() {
      const accounts =  this.incomeStatement ? this.incomeStatement.expense.subtypes : []
      return accounts.reduce((a, c) => c.name === "Operating Expenses" ? a + Number(c.balance) : a, 0)
    },
    totalRevenue() {
      const incomeAccounts =this.incomeStatement ? this.incomeStatement.income.subtypes : []
      return incomeAccounts.reduce((a, c) => c.name === "Revenue" ? a + Number(c.balance) : a, 0)
    },
    totalCostSale() {
      const accounts = this.incomeStatement ? this.incomeStatement.expense.subtypes : []
      let sum = 0;
      accounts.forEach(account => {
        if (account.name === "Cost of Sale") {
          account.accounts.forEach(ac => sum += Number(ac.balance))
        }
      })
      return sum
    },
    netProfit() {
      return this.totalNonOperating + this.totalRevenue - this.totalCostSale - this.totalOperatingExpense
    },
    totalExpense() {
      return this.totalCostSale + this.totalOperatingExpense
    },
    totalExpenseForPeriod() {
      switch (this.currentTab) {
        case "This year":
          const currentMonth = new Date().getMonth() + 1;
          return (this.totalExpense / currentMonth).toFixed(2)
        case "Last three months":
          return (this.totalExpense / 3).toFixed(2)
        case "This month":
        default:
          return (this.totalExpense).toFixed(2)
      }
    },
    totalGross() {
      return this.totalRevenue - this.totalCostSale
    },
    totalGrossProfitPercent() {
      const totalGross = this.totalRevenue - this.totalCostSale
      const grossPercent = ((totalGross / this.totalRevenue) * 100).toFixed(2)
      if (!isFinite(grossPercent)) return "N/A"
      return isNaN(grossPercent) ? 0 : parseFloat(grossPercent)
    },
    totalNetProfitPercent() {
      const netPercent = (this.netProfit / (this.totalRevenue + this.totalNonOperating)) * 100
      if (!isFinite(netPercent)) return "N/A"
      return isNaN(netPercent) ? 0 : parseFloat(netPercent.toFixed(2))
    },
    totalCashRunaway() {


      const months = parseFloat(this.BankAndCashBalance) / this.totalExpenseForPeriod
      if (!isFinite(months)) return "N/A"
      return isNaN(months) || months < 1 ? `< 1 month` : `${Math.floor(months)} months`

    },
    timeToGetPaid() {
      const receivables = this.assetAccounts.subtypes.find(account => account.name === "Receivables")
      const balance = receivables && receivables.balance ? receivables.balance : 0
      const time = parseFloat(balance) / this.totalRevenue
      if (!isFinite(time)) return "N/A"
      return `${Math.floor(time * 365)} days`
    },
    periodWord() {
      switch (this.currentTab) {
        case "This year":
          return "per annum"
        case "Last three months":
          return "last three months"
        case "This month":
        case "default":
          return "month"
      }
    },
    dateRange() {
      let start = 0;
      let end = 0;

      const date = new Date();
      const month = date.getMonth() + 1;
      const year = this.$store.state.current_year ? this.$store.state.current_year : date.getFullYear();

      switch (this.currentTab) {
        case "This year":
          //date covered so far for the year
          start = moment(`${year}-01-01`).format('YYYY-MM-DD');
          end = moment().format('YYYY-MM-DD')
          break
        case "Last three months":
          //last three months
          start = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
          end = moment().format('YYYY-MM-DD')
          break
        case "This month":
        case "default":
          //begining of month to end of month
          start = moment(`${year}-${month}-01`).format('YYYY-MM-DD');
          end = moment().format('YYYY-MM-DD')
          break
      }
      return {start, end}
    }

  },
  watch: {
    currentTab() {
      const range = this.dateRange;
      this.startdate = range.start;
      this.enddate = range.end;
      this.get_incomestatement();
    }
  },
  mounted() {
    const year = this.$store.state.current_year;

    if (year) this.startdate = moment(`${year}-01-01`).format('YYYY-MM-DD');
    this.enddate = moment().format('YYYY-MM-DD');

    this.get_incomestatement();


  },
  created() {

    this.getCashAndBank();

  },
  methods: {

    getCashAndBank() {
      this.loadingKPIs = true;


      axios.get('/api/analytics/cash-bank/accounts')
          .then(res => {
            this.bankAccounts = res.data;
            this.loadingKPIs = false;
          })
          .catch(() => {

          });

    },
    kpiWord(kpi) {
      return kpi < 0 ? "Loss" : "Profit"
    },
    get_incomestatement() {
      const range = this.dateRange;
      this.loadingKPIs = true;
      axios.get('/api/getincomestatement/?startdate=' + range.start + "&enddate=" + range.end)
          .then(res => {
            this.expenseAccounts = res.data;
            this.incomeStatement = res.data;
            this.loadingKPIs = false;
          })
    }
  }
}
</script>

<style scoped>
.gauge-container {
  width: 20rem;
}

.inner-text {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.inner-text > p {
  font-size: 10px;
}

.inner-text > h3 {
  font-weight: 800;
  font-size: 14px;
}

.inner-text--1 > h3 {
  font-size: 20px;
}

.inner-text--1 > p {
  font-size: 15px;
}

.fixed-height-card {
  max-height: 500px;
  overflow-y: auto;

}
</style>
