<template>
<span>

    <v-stepper v-model="step" class="pa-0 ma-0" style="box-shadow: none!important;">

    <v-stepper-header>
      <v-stepper-step :color="step>1 ? 'green' : 'blue'" :complete="step>1" complete-icon="mdi-check" step="1">
        Import Trial Balance
      </v-stepper-step>

            <v-divider></v-divider>

  <v-stepper-step :color="finished ? 'green' : 'blue'" :complete="finished" complete-icon="mdi-check" step="2">
        Finish
      </v-stepper-step>

    </v-stepper-header>


      <v-stepper-items>
        <v-stepper-content class="pa-0 pt-4" step="1">

          <v-row>

              <v-col cols="12" sm="6">
                <h4 class="font-weight-bold">
                  <v-btn :color="$vuetify.theme.isDark ? 'white':'#0D2E57'" class="mr-3"
                         outlined
                         rounded @click="$emit('back')"><v-icon>mdi-arrow-left</v-icon>Change option</v-btn>Import trial balance from Xero</h4>
                <v-progress-linear :value="APPLIED_PROGRESS" class="mb-3" color="green" height="10"
                                   rounded></v-progress-linear>
              </v-col>
<v-col class="text-right" cols="12" sm="6">

        <v-btn v-if="startIndex !==null" :color="$vuetify.theme.isDark ? 'white' : '#0D2E57'" outlined
               rounded @click="startIndex=null">Reset Assignment</v-btn>

                <v-btn v-if="trialBalance && trialBalance.length" class="ma-2" color="red" outlined rounded
                       @click="clearAcounts=true">{{
                    $t("main.delete")
                  }} All <v-icon small>mdi-delete-outline</v-icon></v-btn>
              </v-col>
            </v-row>

          <v-simple-table class="table-bordered">
                 <thead>
        <tr class="bg-built white--text" style="color: white !important;">
                <th class="white--text" style="width: 25em">Account Name</th>
                <th class="white--text" style="width: 17em">Account Type</th>
                <th class="white--text" style="width: 17em">Account Sub-Type</th>
                <th class="white--text" style="width: 17em">Debit</th>
                <th class="white--text" style="width: 17em">Credit</th>
                <th class="white--text text-center" style="width:2em">--</th>

              </tr>
      </thead>
          </v-simple-table>

          <v-form ref="accountsForm">

    <v-simple-table id="mainTable" class="table-hover" style="max-height: 80vh!important; overflow: auto">

      <tbody>
  <v-virtual-scroll
      v-if="trialBalance && trialBalance.length"
      :bench="20"
      :items="trialBalance"
      height="70vh"
      item-height="60"
      width="93em"
  >
              <template v-slot:default="{ item,index }">
      <tr :key="index">
        <td class="pa-3" style="width: 23.5em">
          <v-text-field v-model="item.account_name" :rules="[rules.required]" dense></v-text-field>
        </td>

          <td class="pa-3" style="width: 15em">
          <v-select
              v-model="item.type_id"
              :items="ACCOUNT_TYPES"
              :rules="[rules.required]"
              dense
              item-text="name"
              item-value="id"
          >

          </v-select>
        </td>

            <td class="pa-3">
          <v-select
              v-model="item.subtype_id"
              :items="getSubTypes(item.type_id)"
              :rules="[rules.required]"
              dense
              item-text="name"
              item-value="id"
              style="width: 15em !important;"
          >
            <template v-slot:append-outer>
              <v-btn v-if="startIndex==null || index==startIndex" v-show="item.subtype_id"
                     color="green" outlined rounded x-small
                     @click="startIndex=index; massAssignSubtypeID =item.subtype_id ">Start<v-icon x-small>mdi-play-outline</v-icon></v-btn>

              <v-btn v-else color="red" outlined rounded x-small @click="endIndex=index; massAssign()">End<v-icon
                  x-small>mdi-stop</v-icon></v-btn>

            </template>

          </v-select>
        </td>


        <td class="pa-3" style="width: 15em">
          <v-text-field v-model="item.debit" :prefix="businessCurrency" type="number"></v-text-field>
        </td>
          <td class="pa-3" style="width: 15em">
          <v-text-field v-model="item.credit" :prefix="businessCurrency" type="number"></v-text-field>
        </td>

        <td class="pa-3" style="width: 1em">
          <v-btn color="red" icon @click="removeAccount(index)"><v-icon>mdi-delete-outline</v-icon></v-btn>
        </td>

      </tr>
              </template>
  </v-virtual-scroll>

      <tr id="bottomElement">

      </tr>
      </tbody>
    </v-simple-table>

     </v-form>


          <v-row class="mt-3 mb-3">
            <v-col cols="12" sm="6">
     </v-col>

          <v-col cols="12" sm="3">
              <v-btn :color="$vuetify.theme.isDark ? 'white' : '#0D2E57'" :loading="loading" block class="mt-3" outlined
                     rounded x-large
                     @click="addAccount"><v-icon>mdi-plus</v-icon> Row</v-btn>
            </v-col>

              <v-col v-if="trialBalance && trialBalance.length" cols="12" sm="3">
              <v-btn :loading="loading" class="mt-3" color="#0D2E57" dark depressed rounded x-large
                     @click="saveTriabalance">Save & Continue <v-icon>mdi-arrow-right</v-icon></v-btn>

                 <v-alert v-if="HasErrors" class="mt-3" color="red--text" dense
                          style="background-color: rgba(255,82,82,0.27)" type="error">
                  An error was detected in the trial balance, please check and fix all errors to continue.
                </v-alert>
            </v-col>


          </v-row>

           <v-simple-table v-if="trialBalance && trialBalance.length" class="table-bordered">
                 <thead>
        <tr class=" white--text" style="color: white;">
                <th :class="$vuetify.theme.isDark ? 'white--text' : 'text-built'"
                    class="text-right"
                ><h1>Totals for {{ trialBalance.length }} Accounts:</h1></th>
                <th class="white--text bg-built"
                    style="min-width: 17em"><h3>{{ TOTAL_DEBIT | toMoney | currency_symbol }}</h3></th>
                <th class="white--text bg-built"
                    style="min-width: 17em"><h3>{{ TOTAL_CREDIT | toMoney | currency_symbol }}</h3></th>
                <th class="white--text text-center bg-built" style="min-width:2em">--</th>
              </tr>

        <tr class=" white--text" style="color: white;">
                <th :class="$vuetify.theme.isDark ? 'white--text' : 'text-built'"
                    class="text-right"
                    style="min-width: 59em;"><h1>Difference:</h1></th>
                <th class="white--text bg-built text-center" colspan="3"
                    style="min-width: 17em"><h3>{{ DIFFERENCE | toMoney | currency_symbol }}</h3></th>

              </tr>
      </thead>
          </v-simple-table>

              <div class="pa-6 text-center border mt-3">
              <v-alert class="text-left" color="blue lighten-5 blue--text" prominent>
                <h4>1.Your uploaded data will be shown here for correction and final submission</h4>
                <h4>2.Download your current trial balance from your Xero account and upload it to continue.</h4>
                <h4>3.if you do not want to use the import file you can add empty rows and fill them manually</h4>
              </v-alert>

                <p class="text--disabled">import customers from csv or excel file</p>


                <v-btn :loading="loading" class="ma-3" color="#0D2E57" dark depressed rounded x-large
                       @click="triggerFileSelect">Upload file <v-icon>mdi-arrow-up</v-icon></v-btn>

              <v-file-input
                  id="xero_trial_balance_selector"
                  v-model="trialBalanceFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="d-none" @change="fileSelected"></v-file-input>

            </div>

            </v-stepper-content>

        <v-stepper-content step="2">

          <accountant-finished-componenet
              v-if="step==2"
              user-type="xero"
              @back="step=1"
          ></accountant-finished-componenet>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>




    <v-dialog v-model="clearAcounts" width="500">
    <v-card>
      <v-card-title>{{ $t("main.confirm") }}</v-card-title>
      <v-card-text>
        <h3 class="font-weight-light">Do you want delete accounts from the list?</h3>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="error" dark depressed rounded x-large
               @click="trialBalance=[]; clearAcounts=false;">Yes,Delete all</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>



  <v-snackbar v-model="error" color="error" dark>
    {{ error_message }}
  </v-snackbar>

</span>
</template>

<script>
import axios from "axios";
import AccountantFinishedComponenet from "../accountantFinishedComponenet";

export default {
  props: ['finished', 'defaultTrialBalance'],
  name: "XeroWizard",
  components: {AccountantFinishedComponenet},
  data() {
    return {
      showHelp: false,
      userType: "xero",
      error: false,
      error_message: "",
      step: 1,
      trialBalanceFile: null,
      loading: false,
      trialBalance: null,
      clearAcounts: false,
      startIndex: null,
      endIndex: null,
      massAssignSubtypeID: null,
      HasErrors: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
        },
      },
    }
  },
  computed: {

    DIFFERENCE() {
      const diff = this.TOTAL_DEBIT - this.TOTAL_CREDIT;
      return Number(Math.abs(diff).toFixed(2));

    },


    APPLIED_PROGRESS() {

      if (!this.trialBalance) {
        return 0;
      }

      const applied = this.trialBalance.filter(account => {
        return account.subtype_id;

      });

      const totalLength = this.trialBalance.length;
      const appliedLength = applied.length;


      return (Number(appliedLength) / Number(totalLength)) * 100;


    },

    ACCOUNT_TYPES() {
      let list = [];
      const all = this.$store.state.user.countryInfo;
      list.push({
        name: all.assetAccounts.name,
        id: all.assetAccounts.id
      });

      list.push({
        name: all.equityAccounts.name,
        id: all.equityAccounts.id
      });

      list.push({
        name: all.incomeAccounts.name,
        id: all.incomeAccounts.id
      });

      list.push({
        name: all.liabilityAccounts.name,
        id: all.liabilityAccounts.id
      });

      list.push({
        name: all.expenseAccounts.name,
        id: all.expenseAccounts.id
      });

      return list.sort((a, b) => a.name.localeCompare(b.name));

    },

    TOTAL_DEBIT() {
      let sum = 0;

      this.trialBalance.forEach(account => {
        sum += Number(account.debit);
      });

      return sum;

    },


    TOTAL_CREDIT() {
      let sum = 0;

      this.trialBalance.forEach(account => {
        sum += Number(account.credit);
      });

      return sum;

    },


    businessCurrency() {

      return this.$store.state.user.user_infor.current_business ? this.$store.state.user.user_infor.current_business.currency.code : "";

    },
  },
  methods: {

    back() {
      this.step = 1;
    },

    saveTriabalance() {
      if (this.$refs.accountsForm.validate()) {

        this.HasErrors = false;


        if (this.DIFFERENCE > 0) {

          this.error_message = "The trial balance is not balanced, check and fixed issues to continue";
          this.error = true;
          return;
        }

        this.loading = true;

        axios.post("/api/onboard/" + this.userType + "/trial-balance", {
          trialbalance: this.trialBalance

        })
            .then(res => {
              this.step = 2;
              this.loading = false;

            })
            .catch(error => {
              // this.error_message = "Something went wrong, could not save trial-balance";
              // this.error = true;
              this.loading = false;
            })


      } else {

        this.HasErrors = true;

      }

    },

    massAssign() {

      if (this.endIndex < this.startIndex) {

        for (let i = this.endIndex; i <= this.startIndex; i++) {

          this.trialBalance[i].subtype_id = this.massAssignSubtypeID;

        }

      } else {

        for (let i = this.startIndex; i <= this.endIndex; i++) {

          this.trialBalance[i].subtype_id = this.massAssignSubtypeID;

        }
      }


      this.startIndex = null;

    },

    getSubTypes(type_id) {

      const all = this.$store.state.user.countryInfo;

      if (all.assetAccounts.id == type_id) {

        let subtypes = all.assetAccounts.subtypes;
        subtypes.accounts = null;
        return subtypes;
      }


      if (all.equityAccounts.id == type_id) {

        let subtypes = all.equityAccounts.subtypes;
        subtypes.accounts = null;
        return subtypes;
      }


      if (all.incomeAccounts.id == type_id) {

        let subtypes = all.incomeAccounts.subtypes;
        subtypes.accounts = null;
        return subtypes;
      }


      if (all.liabilityAccounts.id == type_id) {

        let subtypes = all.liabilityAccounts.subtypes;
        subtypes.accounts = null;
        return subtypes;
      }


      if (all.expenseAccounts.id == type_id) {

        let subtypes = all.expenseAccounts.subtypes;
        subtypes.accounts = null;
        return subtypes;
      }

      return [];


    },


    addAccount() {

      if (!this.trialBalance) {
        this.trialBalance = [];
      }

      this.trialBalance.push({
        account_name: "",
        type_id: null,
        subtype_id: null,
        debit: 0,
        credit: 0
      });

      this.$vuetify.goTo("#bottomElement", {offset: 0, duration: 200});

    },
    removeAccount(index) {
      this.trialBalance.splice(index, 1);

    },
    triggerFileSelect() {
      document.getElementById('xero_trial_balance_selector').click();
    },

    fileSelected() {
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.trialBalanceFile);

      axios.post("/api/onboard/decode/xero-trialbalance", formData)
          .then(res => {

            this.loading = false;


            this.trialBalance = res.data;
            this.trialBalanceFile = null;
            this.$refs.accountsForm.validate();

          })
          .catch(error => {
            this.loading = false;

          })


    }
  },
  mounted() {
    this.trialBalance = this.defaultTrialBalance;

    if (this.finished) {
      this.step = 2;
    }

  }
}
</script>

<style scoped>

</style>