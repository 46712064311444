<template>
  <span>

  <v-dialog persistent :fullscreen="$vuetify.breakpoint.mobile" v-model="ableToPlay" width="800" scrollable
            color="white">
    <v-card>
      <v-card-title>

        <v-img src="/img/customer_service.png" height="160" style="border-radius:15px"></v-img>

      </v-card-title>
<v-card-text>
     <center>
       <p class="pa-2 font-weight-light mb-0">It is customer service week and we are grateful to have you as a customer, Spin the fortune wheel below let's see how lucky you are!🤞 </p>
   <svg viewBox="0 0 960 300">
          <symbol id="s-text">
            <text text-anchor="middle" x="50%" y="80%">Try your luck</text>
          </symbol>

          <g class="g-ants">
            <use xlink:href="#s-text" class="text-copy"></use>
            <use xlink:href="#s-text" class="text-copy"></use>
            <use xlink:href="#s-text" class="text-copy"></use>
            <use xlink:href="#s-text" class="text-copy"></use>
            <use xlink:href="#s-text" class="text-copy"></use>
          </g>
        </svg>



      <iframe
          :src="`https://built-wheel-of-fortune.vercel.app/?token=${token}`"
          width="500"
          height="500"
          class="ma-0"
          title="Built Wheel of Fortune"
          style="border: 0; margin: 0; border: none!important;"
      ></iframe>
      </center>
</v-card-text>

      <v-card-actions>
        <v-btn @click="ableToPlay=false" color="green" rounded dark block x-large>Done</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
  </span>

</template>

<script>
import {defineComponent} from 'vue'
import {getAccessToken} from "../../utils";

export default defineComponent({
  name: "fortuneWheel",
  data() {
    return {
      ableToPlay: false
    }
  },
  computed: {
    token() {
      return getAccessToken();
    },
    user() {
      return this.$store.state.user.user_infor;
    }
  },
  mounted() {
    if (this.user.is_admin && this.user.current_business.country.code.toLowerCase() === 'gh') {

      axios.get("/api/fortune-spins")

          .then(res => {

            if (Object.keys(res.data).length==0) {

              this.ableToPlay = true;

            }

          });
    }
  }
})
</script>

<style scoped>

svg {
  display: block;
  font: 3rem "Montserrat";
  width: auto;
  height: 50px;
  margin: 0 auto;
}

.text-copy {
  fill: none;
  stroke: white;
  stroke-dasharray: 6% 29%;
  stroke-width: 5px;
  stroke-dashoffset: 0%;
  animation: stroke-offset 6s infinite linear;
}

.text-copy:nth-child(1) {
  stroke: #4d163d;
  animation-delay: -1;
}

.text-copy:nth-child(2) {
  stroke: #840037;
  animation-delay: -2s;
}

.text-copy:nth-child(3) {
  stroke: #bd0034;
  animation-delay: -3s;
}

.text-copy:nth-child(4) {
  stroke: #bd0034;
  animation-delay: -4s;
}

.text-copy:nth-child(5) {
  stroke: #fdb731;
  animation-delay: -5s;
}

@keyframes stroke-offset {
  100% {
    stroke-dashoffset: -35%;
  }
}

</style>