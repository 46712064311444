import { render, staticRenderFns } from "./debtorsComponent.vue?vue&type=template&id=a8b210dc&scoped=true"
import script from "./debtorsComponent.vue?vue&type=script&lang=js"
export * from "./debtorsComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b210dc",
  null
  
)

export default component.exports