<template>
  <v-avatar
      :color="color"
      class="lighten-5 pt-2"
      :size="size ? size : '48'"

  >
<h4 class="text-capitalize font-weight-light white--text">{{initials}}</h4>

  </v-avatar>
</template>

<script>
export default {
  props:["label",'size'],
  name: "avatarComponent",
  computed:{
    color(){

      let maxVal = 0xFFFFFF; // 16777215
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`

    },
    initials(){

      const arr = this.label.split(" ");


      const first = arr[0].split("")[0];

      let second = "";



      if (arr.length>1){

        second = arr[1].replace(' ','').split("")[0];


      }

      if (second){

        return (first+second).toUpperCase();

      }
      return (first).toUpperCase();

    }
  }
}
</script>

<style scoped>

</style>