<template>
    <v-btn :to="to" class="mr-auto" outlined rounded>{{$t('analytics.view_all')}}</v-btn>

</template>

<script>
    export default {
        props:{
            to:{
                type:String
            }
        },
        name: "viewButton"
    }
</script>

<style scoped>

</style>