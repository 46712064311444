export const PREVIEW_URL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAWlBMVEWKhob19fX5+fmGgoKDfn6+vLzh4OD7+/u5t7fm5ubx8fGsqqrb2trQz8/09PSUkJCfnJzr6+uXk5OkoaGOi4uqp6fGxMSzsbHV1NR/enrMysqVkZGbmJjIxsaFllE0AAAEb0lEQVR4nO3Z23aiMBSAYUminFUUFK19/9cczA6QoNiuWSPTmfV/V5Ud02wScsDVCgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPi36N7vFtDGRs1sBe9VH+52s3G9Op6KTZpuyvPhaQ6mbj6LNE2L03H1LK7r5mrj17w2f6zZ36fX8d1m5n/rel3FSqkoUiqOiuNDMb37zIYC1fohR3O4jvFteVu+H/W6++eRmslQN9U93FOqnBQw521QoDqGKZgkjEenxVN8maFZ+82zTUzroMB1UkCp3K/JlPG0gmLpkfoqQ7OO+3YrO9BsL3kp6qtfwP25H3tJl0/ixcK9+CJDfZT2qaxNzufPVE1bqM+uQFUm56TMpEA2VKUTuRJX1y5+dQM+XnigvurDStpf1jLb55JCfO5bWG+lwGl1L2DqUuLrPv4RBfFV4kbqftEU5zN0PaBOfUh/ZNJJroHuIVRJX8C09kLlPhvJWJ2HCvbxXxinL/rQdqEf0Y1rsmuh62LvqzIOb/KhlnyuY9w92Gr/jkzmzGaojzLm/CFlCq+w6+Ott1kwbZRl2yjXY81RFky+ctc+l+zE+QxPtoGp3xidx2OjZUyGs79skCQndzvKYPU4yX/7GRlKAye3W0bZ0RaogiH7WLN9alUe3CKZnrd/rPnfMP8cVuHEaRnJKrlf3NkM4ltfkcdeOMhjegsqnXxpCbMZ1tKWcFIwG1vargc3Wd36evJRY1PcS4aTWuW+NQsO09kMd3axiw/BRffs2TVbMogvLhLFA7tcuJlKXcJqU/ul/Adl+BFcfJFhFg3SfyNDN57Cs0IwSuUWuMXgIcN+lNZf1/pe8zNNOk4qg0s2Tj9u0jhKxGzH3bVkuIuerO4y/UzG/nt9tVqUQYayTYlsq9286nahurHSMUPp1fAW9Qvq+/J5NJ+h25LoJ9fkg912qtR9U17FVF6G7WPNuv1BK36/GvgLolvkW9m1uV2q/0zd4jFD11/BSUJGbpz8jAz1xnXY0BzZcw3zhBuG3r7uIocL161GHkSvwy7Fw1b2/foMLw97kr6P0tp97o9TG7cA6MQdhtw90P07AZehdieJto+7VwL+aWOxDKP0nHhkUyVb5+6In+y0MfW+UJPZUcuxX1V5bQu0yl8tOpWLN11c1417SxCeNpbK0L2KEf2z587wUZylm02lHl5C6H30pMCYoVv0pxUsuWXzMvT154Uugz44tD72lw+dD1/uC2R+H/YvcoIK1gu/bHuVYZdiFUaVCl8j6SZ4Hdp18H0yGjLsbkE0qSCaPW29SzcdqKlxhdCrq9dEpdLpS29dF8rrx+SSd9WNGYbxrkCx/Etvfe4ekYnUe1LM4TON7jchjrMif/LDhNlfK2ULZG3X/Fv3/TY89JYurqp2yf3o2ALzKByJq0PTza/5vp75ccmsbnlX4CiryvTrQzy/1X/r16evvfxt7TsFvqwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAID/3S/uLCwOFk6YQgAAAABJRU5ErkJggg==";

export const CURRENCY = [
  { cc: "GBP", symbol: "£" },
  { cc: "EUR", symbol: "€" },
  { cc: "USD", symbol: "$" },
  { cc: "GHS", symbol: "GH¢" },
  { cc: "NGN", symbol: "₦" },
  { cc: "BTC", symbol: "฿" },
  { cc: "ETH", symbol: "Eth" },
  { cc: "AED", symbol: "د.إ;" },
  { cc: "AFN", symbol: "؋" },
  { cc: "THB", symbol: "฿" },
  { cc: "PAB", symbol: "B/." },
  { cc: "VEF", symbol: "Bs" },
  { cc: "BOB", symbol: "$b" },
  { cc: "CRC", symbol: "₡" },
  { cc: "SVC", symbol: "$" },
  { cc: "BAM", symbol: "KM" },
  { cc: "NIO", symbol: "C$" },
  { cc: "MKD", symbol: "ден" },
  { cc: "RSD", symbol: "Дин." },
  { cc: "AUD", symbol: "$" },
  { cc: "BSD", symbol: "$" },
  { cc: "BBD", symbol: "$" },
  { cc: "BZD", symbol: "BZ$" },
  { cc: "BMD", symbol: "$" },
  { cc: "BND", symbol: "$" },
  { cc: "CAD", symbol: "$" },
  { cc: "KYD", symbol: "$" },
  { cc: "XCD", symbol: "$" },
  { cc: "FJD", symbol: "$" },
  { cc: "GYD", symbol: "$" },
  { cc: "HKD", symbol: "$" },
  { cc: "JMD", symbol: "J$" },
  { cc: "LRD", symbol: "$" },
  { cc: "NAD", symbol: "N$" },
  { cc: "NZD", symbol: "$" },
  { cc: "SBD", symbol: "$" },
  { cc: "SRD", symbol: "$" },
  { cc: "TTD", symbol: "TT$" },
  { cc: "TVD", symbol: "$" },
  { cc: "VND", symbol: "₫" },
  { cc: "ETB", symbol: "br" },
  { cc: "HUF", symbol: "Ft" },
  { cc: "CHF", symbol: "CHF" },
  { cc: "PYG", symbol: "Gs" },
  { cc: "AWG", symbol: "ƒ" },
  { cc: "ANG", symbol: "ƒ" },
  { cc: "UAH", symbol: "₴" },
  { cc: "KES", symbol: "KSh" },
  { cc: "LAK", symbol: "₭" },
  { cc: "CZK", symbol: "Kč" },
  { cc: "NOK", symbol: "kr" },
  { cc: "DKK", symbol: "kr" },
  { cc: "SEK", symbol: "kr" },
  { cc: "ISK", symbol: "kr" },
  { cc: "HRK", symbol: "kn" },
  { cc: "LVL", symbol: "Ls" },
  { cc: "ALL", symbol: "Lek" },
  { cc: "HNL", symbol: "L" },
  { cc: "BGN", symbol: "лв" },
  { cc: "TRY", symbol: "TL" },
  { cc: "TRL", symbol: "£" },
  { cc: "LTL", symbol: "Lt" },
  { cc: "MWK", symbol: "K" },
  { cc: "MZN", symbol: "MT" },
  { cc: "TWD", symbol: "NT$" },
  { cc: "RON", symbol: "lei" },
  { cc: "AZN", symbol: "ман" },
  { cc: "ILS", symbol: "₪" },
  { cc: "PEN", symbol: "S/." },
  { cc: "ARS", symbol: "$" },
  { cc: "CLP", symbol: "$" },
  { cc: "COP", symbol: "$" },
  { cc: "CUP", symbol: "₱" },
  { cc: "DOP ", symbol: "RD$" },
  { cc: "MXN", symbol: "$" },
  { cc: "PHP", symbol: "Php" },
  { cc: "UYU", symbol: "$U" },
  { cc: "EGP", symbol: "£" },
  { cc: "FKP", symbol: "£" },
  { cc: "GIP", symbol: "£" },
  { cc: "GGP", symbol: "£" },
  { cc: "IMP", symbol: "£" },
  { cc: "JEP", symbol: "£" },
  { cc: "LBP", symbol: "£" },
  { cc: "SHP", symbol: "£" },
  { cc: "SYP", symbol: "£" },
  { cc: "BWP", symbol: "P" },
  { cc: "GTQ", symbol: "Q" },
  { cc: "ZAR", symbol: "R" },
  { cc: "BRL", symbol: "R$" },
  { cc: "IRR", symbol: "﷼" },
  { cc: "OMR", symbol: "﷼" },
  { cc: "QAR", symbol: "﷼" },
  { cc: "YER", symbol: "﷼" },
  { cc: "KHR", symbol: "៛" },
  { cc: "MYR", symbol: "RM" },
  { cc: "SAR", symbol: "﷼" },
  { cc: "BYR", symbol: "p." },
  { cc: "RUB", symbol: "руб" },
  { cc: "INR", symbol: "Rp" },
  { cc: "MUR", symbol: "₨" },
  { cc: "NPR", symbol: "₨" },
  { cc: "PKR", symbol: "₨" },
  { cc: "SCR", symbol: "₨" },
  { cc: "LKR", symbol: "₨" },
  { cc: "IDR", symbol: "Rp" },
  { cc: "RWF", symbol: "RWF" },
  { cc: "SOS", symbol: "S" },
  { cc: "Le", symbol: "Le" },
  { cc: "SGD", symbol: "$" },
  { cc: "KGS", symbol: "лв" },
  { cc: "UZS", symbol: "лв" },
  { cc: "KZT", symbol: "лв" },
  { cc: "MNT", symbol: "₮" },
  { cc: "UGX", symbol: "USh" },
  { cc: "KPW", symbol: "₩" },
  { cc: "KRW", symbol: "₩" },
  { cc: "JPY", symbol: "¥" },
  { cc: "CNY", symbol: "¥" },
  { cc: "ZMW", symbol: "K" },
  { cc: "ZWD", symbol: "Z$" },
  { cc: "PLN", symbol: "zł" },
  { cc: "AMD", symbol: "AMD" },
  { cc: "AOA", symbol: "Kz" },
  { cc: "BDT", symbol: "৳" },
  { cc: "BHD", symbol: ".د.ب" },
  { cc: "BIF", symbol: "FBu" },
  { cc: "BTN", symbol: "Nu." },
  { cc: "CDF", symbol: "F" },
  { cc: "CUC", symbol: "$" },
  { cc: "CVE", symbol: "Esc" },
  { cc: "DJF", symbol: "Fdj" },
  { cc: "DOP", symbol: "RD$" },
  { cc: "DZD", symbol: "د.ج" },
  { cc: "EEK", symbol: "KR" },
  { cc: "ERN", symbol: "Nfa" },
  { cc: "GEL", symbol: "GEL" },
  { cc: "GMD", symbol: "D" },
  { cc: "GNF", symbol: "FG" },
  { cc: "GQE", symbol: "CFA" },
  { cc: "HTG", symbol: "G" },
  { cc: "IQD", symbol: "د.ع" },
  { cc: "JOD", symbol: "JOD" },
  { cc: "KMF", symbol: "KMF" },
  { cc: "KWD", symbol: "KWD" },
  { cc: "LSL", symbol: "M" },
  { cc: "LYD", symbol: "LD" },
  { cc: "MAD", symbol: "MAD" },
  { cc: "MDL", symbol: "MDL" },
  { cc: "MGA", symbol: "FMG" },
  { cc: "MMK", symbol: "K" },
  { cc: "MOP", symbol: "P" },
  { cc: "MRO", symbol: "UM" },
  { cc: "MVR", symbol: "Rf" },
  { cc: "MZM", symbol: "MTn" },
  { cc: "PGK", symbol: "K" },
  { cc: "SDG", symbol: "SDG" },
  { cc: "SLL", symbol: "Le" },
  { cc: "SLE", symbol: "NLe" },
  { cc: "SZL", symbol: "E" },
  { cc: "TJS", symbol: "TJS" },
  { cc: "TMT", symbol: "m" },
  { cc: "TND", symbol: "DT" },
  { cc: "TZS", symbol: "TZS" },
  { cc: "VEB", symbol: "Bs" },
  { cc: "VUV", symbol: "VT" },
  { cc: "WST", symbol: "WS$" },
  { cc: "XAF", symbol: "CFA" },
  { cc: "XDR", symbol: "SDR" },
  { cc: "XOF", symbol: "CFA" },
  { cc: "XPF", symbol: "F" },
  { cc: "ZMK", symbol: "ZK" },
  { cc: "ZWR", symbol: "Z$" },

];
