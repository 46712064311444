<template>
  <v-row>
    <v-col cols="12" sm="12">

      <vs-card type="3">
        <template #title>
          <h3 class="font-weight-black">Catch-up & Clean-up Service:</h3>
        </template>
        <template #img>
          <img width="100px" height="100px" src="/img/team.svg" alt="Catch-up & Clean-up Service:">
        </template>
        <template #text>
          <p>
            Get matched with an accountant to clean up your past books to make your transition to Built a breeze.
          </p>
          <vs-button id="compaing_button" @click="show_more=true">See More...</vs-button>
        </template>

      </vs-card>

    </v-col>

    <vs-dialog scroll
               overflow-hidden
               width="60%"
               blur
               v-model="show_more">
      <template #header>
        <h3 class="font-weight-black">Catch-up & Clean-up Service:</h3>
      </template>
      <div class="con-content">

        <p>Get matched with an accountant to clean up your past books to make your transition to Built a breeze. The clean
        up starts with a scheduled meeting during which your accountant will request for all supporting documentation.
        Once your accountant receives all the necessary documentation, they’ll typically complete your cleanup within 30
        days. In some cases, your cleanup may take longer depending on timeliness of documentation and the complexity of
        your books.
        </p>
        <p><h2 class="font-weight-black">Request for a quote: maryanne@builtgh.com</h2></p>

      </div>
    </vs-dialog>


  </v-row>
</template>

<script>
export default {
  name: "CompainCard",
  data() {
    return {
      show_more: false
    }
  }
}
</script>

<style scoped>

.con-content p {
  margin: 20px 0px;
  position: relative;
  display: block;
  font-size: .9rem;

}

</style>