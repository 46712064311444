<template>
  <span>
    <v-card flat color="blue lighten-5 blue--text" v-if="showStart">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-img src="/img/migrate_export.svg"></v-img>
          </v-col>

          <v-col cols="12" sm="8">
            <h2 class="font-weight-black blue--text">Let's help you move!</h2>
            <p class="blue--text">
              Export all your important data in the new currency so you can
              easily move to a new account
            </p>
            <v-btn
              @click="showDialog = true"
              depressed
              color="blue darken-4"
              dark
              large
              rounded
              block
              >Get Started<v-icon>mdi-arrow-right</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-else>
      <v-card-title class="font-weight-light"
        >Export data <v-spacer></v-spacer
        ><v-btn
          color="red lighten-5 red--text"
          rounded
          depressed
          small
          @click="$emit('closed')"
          >close<v-icon small>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <v-alert dense type="success" color="green lighten-5 green--text">
          <h4>
            In order to easily move to a new account with the new currency, you
            would need to download these files
          </h4>
        </v-alert>

        <v-card outlined>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="2">
                <v-icon x-large>mdi-cube-outline</v-icon>
              </v-col>
              <v-col cols="12" sm="10">
                <h3>Download products and services</h3>
                <p>Download your products and services in excel format</p>
                <v-btn
                  :href="
                    $store.state.baseURL +
                    '/' +
                    'download/sl/items/' +
                    business.enc_id
                  "
                  text
                  small
                  color="blue"
                  >Download<v-icon>mdi-download-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-2">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="2">
                <v-icon x-large>mdi-account-multiple-outline</v-icon>
              </v-col>
              <v-col cols="12" sm="10">
                <h3>Download customers</h3>
                <p>Download a list of your customers in excel format</p>
                <v-btn
                  :href="
                    $store.state.baseURL +
                    '/' +
                    'download/sl/customers/' +
                    business.enc_id
                  "
                  text
                  small
                  color="blue"
                  >Download<v-icon>mdi-download-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-2">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="2">
                <v-icon x-large>mdi-account-multiple-outline</v-icon>
              </v-col>
              <v-col cols="12" sm="10">
                <h3>Download suppliers</h3>
                <p>Download a list of your suppliers in excel format</p>
                <v-btn
                  :href="
                    $store.state.baseURL +
                    '/' +
                    'download/sl/suppliers/' +
                    business.enc_id
                  "
                  text
                  small
                  color="blue"
                  >Download<v-icon>mdi-download-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined class="mt-2">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="2">
                <v-icon x-large>mdi-sitemap</v-icon>
              </v-col>
              <v-col cols="12" sm="10">
                <h3>Download Other Accounts</h3>
                <p>Download your chart of accounts in excel format</p>
                <v-btn
                  :href="
                    $store.state.baseURL +
                    '/' +
                    'download/sl/accounts/' +
                    business.enc_id
                  "
                  text
                  small
                  color="blue"
                  >Download<v-icon>mdi-download-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <h2 class="black--text mt-3">
          Done?,
          <v-btn @click="createBusiness" text color="blue"
            >Create a new business to continue</v-btn
          >
        </h2>
      </v-card-text>
    </v-card>

    <v-dialog
      scrollable
      v-model="showDialog"
      width="500"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title class="font-weight-light">Export data</v-card-title>
        <v-card-text>
          <v-alert dense type="success" color="green lighten-5 green--text">
            <h4>
              In other to easily move to a new account with the new currency,
              you would need to download these files
            </h4>
          </v-alert>

          <v-card outlined>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <v-icon x-large>mdi-cube-outline</v-icon>
                </v-col>
                <v-col cols="12" sm="10">
                  <h3>Download products and services</h3>
                  <p>Download your products and services in excel format</p>
                  <v-btn
                    :href="
                      $store.state.baseURL +
                      '/' +
                      'download/sl/items/' +
                      business.enc_id
                    "
                    text
                    small
                    color="blue"
                    >Download<v-icon>mdi-download-outline</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card outlined class="mt-2">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <v-icon x-large>mdi-account-multiple-outline</v-icon>
                </v-col>
                <v-col cols="12" sm="10">
                  <h3>Download customers</h3>
                  <p>Download a list of your customers in excel format</p>
                  <v-btn
                    :href="
                      $store.state.baseURL +
                      '/' +
                      'download/sl/customers/' +
                      business.enc_id
                    "
                    text
                    small
                    color="blue"
                    >Download<v-icon>mdi-download-outline</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card outlined class="mt-2">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <v-icon x-large>mdi-account-multiple-outline</v-icon>
                </v-col>
                <v-col cols="12" sm="10">
                  <h3>Download suppliers</h3>
                  <p>Download a list of your suppliers in excel format</p>
                  <v-btn
                    :href="
                      $store.state.baseURL +
                      '/' +
                      'download/sl/suppliers/' +
                      business.enc_id
                    "
                    text
                    small
                    color="blue"
                    >Download<v-icon>mdi-download-outline</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card outlined class="mt-2">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <v-icon x-large>mdi-sitemap</v-icon>
                </v-col>
                <v-col cols="12" sm="10">
                  <h3>Download Other Accounts</h3>
                  <p>Download your chart of accounts in excel format</p>
                  <v-btn
                    :href="
                      $store.state.baseURL +
                      '/' +
                      'download/sl/accounts/' +
                      business.enc_id
                    "
                    text
                    small
                    color="blue"
                    >Download<v-icon>mdi-download-outline</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <h2 class="black--text mt-3">
            Done?,
            <v-btn @click="createBusiness" text color="blue"
              >Create a new business to continue</v-btn
            >
          </h2>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: "MoveOut",
  props: {
    showStart: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    business() {
      return this.$store.state.user.user_infor.current_business;
    },
  },
  methods: {
    createBusiness() {
      this.$emit("closed");
      window.location =
        "/usersettings?new_business=true&country=" +
        this.business.country.code +
        "&industry=" +
        this.business.industry_id;
    },
  },
};
</script>

<style scoped></style>
