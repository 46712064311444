<template>
    <span>
        <v-btn rounded @click="getTransactions" text :color="$vuetify.theme.dark ? 'white' : 'blue'" small class="p-1" exact>{{account.account_name}}</v-btn>

            <v-dialog
                    v-model="preview_dialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                    scrollable
                    persistent
            >
            <v-card flat>
                <v-toolbar
                        flat
                        class="d-print-none"
                >
                    <v-btn
                            icon
                            @click="preview_dialog = false; $emit('dialogClosed')"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{$store.state.account.account_name}} - {{$t('accounts.transaction_statement')}}

                    <v-toolbar-items>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="invoice_temp" >
          <accountpreviewComponent></accountpreviewComponent>
                </v-card-text>
            </v-card>
        </v-dialog>

    </span>

</template>

<script>
const accountpreviewComponent = () => import("./accountpreviewComponent");

    export default {
        components: {accountpreviewComponent},
        props:['account'],
        name: "accountButton",
        data(){
            return{
                preview_dialog:false
            }
        },
        methods:{
            getTransactions(){
                this.$store.state.account = this.account;
                this.$store.state.totalBalance = this.account.balance;
                this.$store.state.accountId = this.account.id;
                this.preview_dialog=true;
                this.$store.commit('get_account_transactions');

            }
        }

    }
</script>

<style scoped>

</style>
