<template>

        <v-tour name="navbarTour" :steps="steps">

            <template slot-scope="tour">
                <transition name="fade">
                    <v-step
                            v-if="tour.steps[tour.currentStep]"
                            :key="tour.currentStep"
                            :step="tour.steps[tour.currentStep]"
                            :previous-step="tour.previousStep"
                            :next-step="tour.nextStep"
                            :stop="tour.stop"
                            :skip="tour.skip"
                            :is-first="tour.isFirst"
                            :is-last="tour.isLast"
                            :labels="tour.labels"
                            class="blue"
                    >
                        <template>
                            <div slot="actions">
                                <v-btn v-if="!tour.isLast" @click="tour.nextStep" text dark>Okay, Got It</v-btn>
                                <v-btn @click="tour.stop(); turnOff() " text small dark>finish</v-btn>
                            </div>
                        </template>
                    </v-step>
                </transition>
            </template>

        </v-tour>
</template>

<script>
    export default {
        name: "appTour",
        data () {
            return {


                steps: [
                    {
                        target: '#addShortcustBtn',

                        content: `Get things done faster through the shortcut menu `
                    },
                    {
                        target: '#userAvatar',
                        content: 'Manage your profile'
                    },

                ]
            }
        },
        methods:{
            turnOff(){
                localStorage.setItem('appbarTour',true);
            }
        },
        mounted () {
        if(this.$route.path!=='/auth/login' ){
            if (!localStorage.appbarTour) {
                
                this.$tours['navbarTour'].start()
                }
            }

            }

    }
</script>

<style scoped>

</style>