<template>
<v-card>
  <v-card-title>
    <h3>How to download trial Balance from wave</h3> <v-spacer></v-spacer>
    <v-btn @click="$emit('close')" color="red lighten-5 red--text" rounded depressed small>close <v-icon>mdi-close</v-icon></v-btn>
  </v-card-title>
  <v-card-text>

    <v-row dense class="border-bottom">
      <v-col cols="12" sm="5">
          <v-img
              src="https://built-storage.ams3.cdn.digitaloceanspaces.com/wave_logo.jpeg"
              contain
          ></v-img>
      </v-col>
      <v-col cols="12" sm="7">
        <h3 class="font-weight-bold" :class="$vuetify.theme.isDark ? 'white--text':'text-built'"><span class="blue lighten-5 blue--text pa-2 ma-2" style="width: 80px; height: 20px!important; border-radius: 30%">1</span>Log on to your Wave Account</h3>
        <p class="text-muted">
          Visit the wave web app to log in with your email and password
        </p>
        <v-btn block text color="blue" small href="https://next.waveapps.com/" target="_blank">Login <v-icon x-small>mdi-arrow-top-right</v-icon></v-btn>
      </v-col>

    </v-row>

    <v-row dense class="border-bottom pt-3">
      <v-col cols="12" sm="5">
          <v-img
              contain
              src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Screenshot%202023-03-20%20at%202.25.40%20AM.png"
          ></v-img>
      </v-col>
      <v-col cols="12" sm="7">
        <h3 class="font-weight-bold" :class="$vuetify.theme.isDark ? 'white--text':'text-built'"><span class="blue lighten-5 blue--text pa-2 ma-2" style="width: 80px; height: 20px!important; border-radius: 30%">2</span>Go to reports</h3>
        <p class="text-muted">
          Locate and visit the reports page on the main side menu
        </p>
      </v-col>

    </v-row>


   <v-row dense class="border-bottom pt-3">
      <v-col cols="12" sm="5">
          <v-img
              contain
              src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Screenshot%202023-03-20%20at%202.31.53%20AM.png"
          ></v-img>
      </v-col>
      <v-col cols="12" sm="7">
        <h3 class="font-weight-bold" :class="$vuetify.theme.isDark ? 'white--text':'text-built'"><span class="blue lighten-5 blue--text pa-2 ma-2" style="width: 80px; height: 20px!important; border-radius: 30%">3</span>Select trial balance</h3>
        <p class="text-muted">
          Select trial balance from the detailed reporting sections on the reports page.
        </p>
      </v-col>

    </v-row>

   <v-row dense class="border-bottom pt-3">
      <v-col cols="12" sm="5">
          <v-img
              contain
              src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Screenshot%202023-03-20%20at%202.34.27%20AM.png"
          ></v-img>
      </v-col>
      <v-col cols="12" sm="7">
        <h3 class="font-weight-bold" :class="$vuetify.theme.isDark ? 'white--text':'text-built'"><span class="blue lighten-5 blue--text pa-2 ma-2" style="width: 80px; height: 20px!important; border-radius: 30%">4</span>Export trial balance</h3>
        <p class="text-muted">
         Click on the export menu and select CSV to download trial balance
        </p>
      </v-col>

    </v-row>

  </v-card-text>
</v-card>
</template>

<script>
export default {
  name: "waveHelpComponent"
}
</script>

<style scoped>

</style>