<template>
  <v-row>
    <v-col cols="12" sm="10" class="mx-auto">

      <v-card flat class="pa-5" style="border:1px solid red">
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12" sm="8">


              <h1 class="font-weight-light red--text">
                Subscription Ended
              </h1>

              <h3 class="text-h6 font-weight-light">
                Your subscription for
                <strong class="fw-bold">{{
                  $store.state.user.user_infor.current_business.name
                }}</strong>
                has ended.
              </h3>


              <v-alert v-if="
                $store.state.user.user_infor.current_business.subscription
                  .name !== 'none'
              " type="warning" prominent text>
                <div>
                  <h3>
                    Only businesses active on <strong>Starter</strong>,
                    <strong>Enterprise</strong>, <strong>Venture</strong>,
                    <strong>Pro</strong> and <strong>Plus</strong>
                    are allowed to have multiple user access.
                  </h3>
                  <strong>Subscribe to a plan to continue or Contact the owner of this business to upgrade their
                    plan.</strong>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn color="green" dark block x-large rounded depressed @click="showPlans = true">Subscribe
              </v-btn>
            </v-col>
          </v-row>



        </v-card-text>
      </v-card>

    </v-col>

    <v-container fluid>
      <plans-component v-if="showPlans"></plans-component>
    </v-container>

    <v-dialog width="400" v-model="logoutDialog">
      <v-card>
        <v-card-title class="font-weight-light">{{ $t("main.confirm") }}
        </v-card-title>
        <v-card-text>
          <h3>{{ $t("main.logout_message") }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="logout" :loading="logoutLoading" block large color="red" dark rounded depressed>
            {{ $t("main.logout") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BusinessItem from "./BusinessItem";
import NewBusinessComponent from "./NewBusinessComponent";
import PlansComponent from "../plansComponent";
import axios from "axios";

export default {
  name: "BusinessExpiredGuard",
  components: { PlansComponent, NewBusinessComponent, BusinessItem },
  data() {
    return {
      mybusinesses: [],
      progress: false,
      logoutDialog: false,
      loging_out: false,
      step: 1,
      invitedBusinesses: [],
      logoutLoading: false,
      showBusinesses: false,
      showPlans: false,
    };
  },
  methods: {
    logout() {
      this.logoutLoading = true;
      axios
        .get("/api/auth/logout")
        .then((res) => {
          this.logoutLoading = false;
          window.reload();
        })
        .catch((error) => { });
    },
    getInvitedBusinesses() {
      this.progress = true;

      axios.get("/api/business/invited").then((res) => {
        this.invitedBusinesses = res.data;
        this.progress = false;
      });
    },

    GetMyBusinesses() {
      this.progress = false;
      axios.get("/api/getbusinesses").then((res) => {
        this.mybusinesses = res.data;
        this.progress = false;
      });
    },
  },
  mounted() {
    this.GetMyBusinesses();
    this.getInvitedBusinesses();
  },
};
</script>

<style scoped></style>
