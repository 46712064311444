<template>
    <div>
    <v-card class="menu-card" flat :outlined="outlined" style="overflow: hidden">
        <v-card-text style="overflow: hidden">
            <v-btn
                text
                color="blue"
                class="font-weight-bolder"
                :to="'/accounting/tag/'+tag.name"
                @click="$emit('close')"
            >#{{tag.name}}</v-btn>
           <br> <small class="block">{{$t('main.created_on')}}: {{tag.created_at | humanDate}}</small>
        </v-card-text>
        <v-card-actions v-if="!hidedelete">
            <v-btn x-small text color="red" @click="delete_tag(tag)">{{$t('main.delete')}} <v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>
    </v-card>
        <v-dialog :persistent="progress" width="400" v-model="delete_dialog">
            <v-card>
                <v-card-title class="font-weight-light">{{$t('main.confirm')}} {{$t('main.delete')}}</v-card-title>
                <v-card-text>
                    {{$t('tags.delete_warning')}} {{name}} ?
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="do_delete()" :loading="progress" text color="red">{{$t('main.delete')}}</v-btn>
                    <v-btn :disabled="progress" @click="delete_dialog=false" text >{{$t('main.cancel')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="error" color="red" dark>
            {{error_message}}
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        name: "tagComponent",
        props:['tag','outlined','hidedelete'],
        data(){
            return{
                delete_dialog:false,
                id:null,
                name:"",
                progress:false,
                error:false,
                error_message:""

            }
        },
        methods:{
            delete_tag(tag){
                this.id = tag.id;
                this.name = tag.name;
                this.delete_dialog=true;
            },
            do_delete(){
                this.progress=true;
                axios.delete('/api/tag/'+this.id)
                    .then(res=>{

                        this.$emit('deleted',this.tag);
                    })
                    .catch(error=>{
                        // this.error_message = error.response.status === 302 ? error.response.data : this.$t('tags.delete_failed');
                        // this.error=true;
                        this.progress=false;

                    })

            }
        }

    }
</script>

<style>
.menu-card{
  transition: 0.3s ease-in-out !important;
  cursor: pointer;

}
.menu-card:hover{
  transform: scale(1.1);
  border: 2px solid #2196F3 !important;
}

</style>
