<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          to="/"
          text
          :color="$vuetify.theme.dark ? 'white' : 'blue'"
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("main.back") }}
        </v-btn>

        <span class="display-1 font-weight-light">Expenses</span>

      </v-col>

      <v-col cols="12" sm="3" v-for="(menu, index) in menus" :key="index">
        <menue-card
          :title="menu.title"
          :desription="menu.description"
          :icon="menu.icon"
          :route="menu.route"
        ></menue-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MenueCard from "./MenueCard.vue";

export default {
  name: "ExpenseMenu",

  components: { MenueCard },

  data() {
    return {
      menus: [
        {
          title: "Paid Expenses",
          description: "Record and manage already paid expenses.",
          icon: "mdi-calculator-variant",
          route: "/expense/paidexpenses",
        },
        {
          title: "Bills",
          description: "Create and manage bills received from your suppliers.",
          icon: "mdi-billboard",
          route: "/expense/expenses",
        },
        {
          title: "Sales Tax Payments",
          description: "Record and manage sales tax payments.",
          icon: "mdi-cash-register",
          route: "/expense/taxpayment/sales",
        },
        {
          title: "Withholding Tax Payments",
          description: "Record and manage withholding tax payments.",
          icon: "mdi-cash-refund",
          route: "/expense/taxpayment/withholding",
        },
        {
          title: "Income Tax Payments",
          description: "Record and manage company income tax payments.",
          icon: "mdi-domain",
          route: "/expense/taxpayment/income",
        },
        {
          title: "Suppliers",
          description: "Create and manage your suppliers/creditors.",
          icon: "mdi-account-multiple",
          route: "/expense/suppliers",
        },
      ],
    };
  },
};
</script>