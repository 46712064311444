<template>
  <div>
    <v-dialog
      v-model="show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-parallax
          height="650"
          style="overflow-y: auto"
          class="pt-5"
          src="../img/home_bg.jpg"
        >
          <v-row class="mt-5">
            <v-col class="text-white text-center" cols="12" sm="6">
              <h1 class="font-weight-light display-4">
                Simple accounting <br />
                solution for SMEs
              </h1>
              <h2 class="font-weight-light">Sign Up For Free</h2>
            </v-col>

            <v-col class="ml-auto" cols="12" sm="6">
              <v-card>
                <v-tabs
                  v-model="tab"
                  background-color="white"
                  centered
                  light
                  icons-and-text
                  elevation="3"
                  class="mt-5"
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#login"> LOGIN </v-tab>

                  <v-tab href="#signup"> SIGNUP </v-tab>
                </v-tabs>
                <v-progress-linear
                  indeterminate
                  color="blue"
                  v-if="progress"
                ></v-progress-linear>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="login">
                    <v-card flat>
                      <v-card-text class="text-center">
                        <v-form ref="login_form" v-model="valid_form">
                          <v-col cols="12" sm="12">
                            <v-text-field
                              label="E-Mail"
                              outlined
                              prepend-inner-icon="mail"
                              :rules="email_rules"
                              v-model="email"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12">
                            <v-text-field
                              v-model="password"
                              :append-icon="
                                show_pass ? 'visibility' : 'visibility_off'
                              "
                              :rules="password_rules"
                              :type="show_pass ? 'text' : 'password'"
                              name="input-10-1"
                              label="Password"
                              prepend-inner-icon="mdi-textbox-password"
                              counter
                              outlined
                              @click:append="show_pass = !show_pass"
                            ></v-text-field>
                          </v-col>
                        </v-form>
                        <v-switch
                          color="blue"
                          v-model="remember_me"
                          label="Remember Me"
                        ></v-switch>

                        <v-col cols="12" sm="12">
                          <v-btn block color="success" outlined @click="login">
                            Login
                          </v-btn>
                        </v-col>

                        OR
                        <v-col cols="12" sm="12">
                          <v-btn block color="blue">
                            Continue With Google
                            <v-icon>mdi-google</v-icon>
                          </v-btn>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item value="signup">
                    <v-card flat>
                      <v-card-text>
                        <v-stepper v-model="step" elevation="0">
                          <v-stepper-header>
                            <v-stepper-step :complete="step > 1" step="1"
                              >Personal Info.
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step :complete="step > 2" step="2"
                              >Account Info.
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step step="3">Finish</v-stepper-step>
                          </v-stepper-header>
                          <v-stepper-items>
                            <v-stepper-content step="1">
                              <v-form
                                v-model="personal_infor"
                                ref="person_form"
                              >
                                <v-row>
                                  <v-col cols="12" sm="12">
                                    <v-text-field
                                      v-model="first_name"
                                      :rules="first_name_rules"
                                      label="First Name"
                                      prepend-inner-icon="mdi-account"
                                      outlined
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="12">
                                    <v-text-field
                                      v-model="last_name"
                                      :rules="first_name_rules"
                                      label="Last Name"
                                      prepend-inner-icon="mdi-account"
                                      outlined
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="12">
                                    <v-text-field
                                      type="tel"
                                      v-model="phone_number"
                                      :rules="phone_number_rules"
                                      label="Phone Number"
                                      prepend-inner-icon="mdi-phone"
                                      outlined
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="12">
                                    <v-select
                                      outlined
                                      :items="countries"
                                      label="Country"
                                      item-value="id"
                                      item-text="name"
                                      prepend-inner-icon="mdi-flag"
                                      v-model="country_id"
                                      :rules="country_rules"
                                    >
                                    </v-select>
                                  </v-col>
                                </v-row>
                              </v-form>

                              <v-btn color="blue" @click="goto_account" text>
                                Continue
                                <v-icon>mdi-arrow-right</v-icon>
                              </v-btn>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                              <v-form
                                v-model="account_infor"
                                ref="account_form"
                              >
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    label="E-Mail"
                                    outlined
                                    prepend-inner-icon="mail"
                                    :rules="email_rules"
                                    v-model="new_email"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    v-model="new_password"
                                    :append-icon="
                                      show_pass1
                                        ? 'visibility'
                                        : 'visibility_off'
                                    "
                                    :rules="password_rules"
                                    :type="show_pass1 ? 'text' : 'password'"
                                    label="Password"
                                    prepend-inner-icon="mdi-textbox-password"
                                    counter
                                    outlined
                                    @click:append="show_pass1 = !show_pass1"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    v-model="confirm_password"
                                    :append-icon="
                                      show_pass2
                                        ? 'visibility'
                                        : 'visibility_off'
                                    "
                                    :rules="password_rules"
                                    :type="show_pass2 ? 'text' : 'password'"
                                    label="Repeat Password"
                                    prepend-inner-icon="mdi-textbox-password"
                                    counter
                                    outlined
                                    @click:append="show_pass2 = !show_pass2"
                                  ></v-text-field>
                                </v-col>
                              </v-form>

                              <v-btn color="blue" @click="step = step - 1" text>
                                <v-icon>mdi-arrow-left</v-icon>
                                Back
                              </v-btn>
                              <v-btn
                                color="blue"
                                @click="sign_up"
                                :disabled="progress"
                              >
                                Sign Up Now
                                <v-icon>mdi-arrow-right</v-icon>
                              </v-btn>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                              <v-card class="mb-12">
                                <v-card-title
                                  height="120px"
                                  class="text-white bg-primary text-center"
                                  transition="scroll-x-reverse-transition"
                                >
                                  <h3>
                                    Congratulations
                                    {{
                                      $store.state.user.user_infor.first_name
                                    }}
                                    !
                                  </h3>
                                  <small
                                    class="font-weight-light"
                                    style="font-size: 14px"
                                  >
                                    You are just a step away, please tell us
                                    about your business below to complete the
                                    setup
                                  </small>
                                </v-card-title>

                                <v-form
                                  v-model="business_valid"
                                  ref="business_form"
                                >
                                  <v-row>
                                    <v-col cols="12" sm="12">
                                      <v-img
                                        width="250"
                                        aspect-ratio="1"
                                        class="grey lighten-2"
                                        max-width="500"
                                        max-height="300"
                                        :src="preview"
                                        :lazy-src="logo"
                                      >
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                          >
                                            <v-progress-circular
                                              indeterminate
                                              color="grey lighten-5"
                                            ></v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                      <v-btn
                                        @click="selectPass"
                                        color="blue darken-4"
                                        outlined
                                        >Select Logo</v-btn
                                      >
                                      <input
                                        id="filepass"
                                        accept="image/png, image/jpeg, image/bmp"
                                        v-on:change="passChange"
                                        type="file"
                                        class="d-none"
                                      />
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" sm="12">
                                      <v-text-field
                                        :disabled="progress"
                                        outlined
                                        label="Business Name"
                                        hint="Legally Registered name exactly as used in legal documents"
                                        v-model="business_name"
                                        :rules="business_name_rules"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        :disabled="progress"
                                        type="email"
                                        outlined
                                        label="Business Email"
                                        v-model="business_email"
                                        :rules="business_email_rules"
                                      >
                                      </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        :disabled="progress"
                                        type="tel"
                                        outlined
                                        label="Business Phone #"
                                        v-model="business_phone"
                                        :rules="business_phone_rules"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" sm="12">
                                      <v-textarea
                                        :disabled="progress"
                                        outlined
                                        label="Business Address"
                                        v-model="business_address"
                                        :rules="business_address_rules"
                                      >
                                      </v-textarea>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" sm="12">
                                      <vuetify-google-autocomplete
                                        :disabled="progress"
                                        ref="location_input"
                                        id="map"
                                        outlined
                                        classname="form-control"
                                        placeholder="Please enter your location"
                                        v-on:placechanged="getAddressData"
                                        country="GH"
                                      >
                                      </vuetify-google-autocomplete>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        outlined
                                        label="Business Bank Name"
                                        v-model="business_bank_name"
                                        :rules="business_bank_rules"
                                      >
                                      </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        outlined
                                        label="Business Bank Account Name"
                                        v-model="business_account_name"
                                        :rules="business_account_rules"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" sm="12">
                                      <v-text-field
                                        outlined
                                        label="Business Bank Account Number"
                                        v-model="business_account_number"
                                        :rules="business_account_number_rules"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-card>

                              <v-btn
                                color="success"
                                @click="create_business"
                                block
                                :disabled="progress"
                              >
                                Finish
                                <v-icon>mdi-check</v-icon>
                              </v-btn>
                            </v-stepper-content>
                          </v-stepper-items>
                        </v-stepper>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-parallax>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="login_error" color="error">
      {{ error_msg }}
      <v-btn color="light" text @click="login_error = false" timeout="3000">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { getAccessToken, setAccessToken } from "../utils";

export default {
  name: "homeComponent",
  data() {
    return {
      logo: "../img/logo_place.png",
      logo_file: {},
      business_name: "",
      business_name_rules: [(value) => !!value || "Business Name is Required."],
      business_email: "",
      business_email_rules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],

      business_phone: "",
      business_phone_rules: [
        (value) => !!value || "Business Phone Number is Required.",
      ],
      business_bank_name: "",
      business_bank_rules: [
        (value) => !!value || "Business Bank Name is Required.",
      ],
      business_account_name: "",
      business_account_rules: [
        (value) => !!value || "Business Bank account name is Required.",
      ],
      business_account_number: "",

      business_account_number_rules: [
        (value) => !!value || "Business Bank Account Number is Required.",
      ],
      business_address: "",
      business_address_rules: [
        (value) => !!value || "Business Address is Required.",
      ],
      business_location: "",
      business_valid: false,
      account_infor: false,
      personal_infor: false,
      first_name: "",
      last_name: "",
      phone_number: "",
      country_id: "",
      new_password: "",
      confirm_password: "",
      new_email: "",
      progress: false,
      show_pass: false,
      show_pass1: false,
      show_pass2: false,
      remember_me: true,
      valid_form: false,
      login_error: false,
      error_msg: "",
      countries: null,
      password: "",
      email: "",
      step: 0,
      preview: "",
      password_rules: [(value) => !!value || "Password is Required."],
      first_name_rules: [(value) => !!value || "Name is Required."],
      country_rules: [(value) => !!value || "Country is Required."],
      phone_number_rules: [(value) => !!value || "Phone number is Required."],
      email_rules: [(value) => !!value || "E-mail is Required."],
      show: false,
      tab: null,
      preview,
    };
  },
  mounted() {
    // this.$vuetify.theme.dark=true;

    if (getAccessToken() !== undefined) {
      this.show = false;
      this.$router.push({ path: "/" });
      this.$store.commit("init_user");
    } else {
      this.show = true;
      this.get_countries();
    }
  },
  methods: {
    selectPass() {
      let pass = document.getElementById("filepass");
      pass.click();
    },

    passChange(e) {
      this.progress = true;
      this.passport = e.target.files[0];
      if (this.passport.size < 532598) {
        const fr = new FileReader();
        let logo_file = fr.readAsDataURL(this.passport);
        fr.onload = () => {
          this.preview = fr.result;
          this.progress = false;
        };
      } else {
        this.error_msg = "Image size too big please select a smaller image";
        this.login_error = true;
      }
    },

    getAddressData(addressData, placeResultData, id) {
      if (addressData) {
        this.business_location =
          addressData.longitude + "," + addressData.latitude;
      }
    },

    create_business() {
      if (this.$refs.business_form.validate()) {
        this.progress = true;

        let business_data = new FormData();
        business_data.append("name", this.business_name);
        business_data.append("address", this.business_address);
        business_data.append("email", this.business_email);
        business_data.append("location", this.business_location);
        business_data.append("phone_number", this.business_phone);
        business_data.append("bank_name", this.business_bank_name);
        business_data.append("account_number", this.business_account_number);
        business_data.append("account_name", this.business_account_name);
        business_data.append("active", 1);
        business_data.append("owner_id", this.$store.state.user.user_infor.id);
        axios
          .post("/api/createbusiness", business_data)
          .then((res) => {
            this.add_dialog = false;
            this.$store.state.user.businesses.push(res.data);
            this.businesses.push(res.data);
            this.empty = false;
          })
          .catch(() => {
            // this.login_error = true;
            // this.error_msg = "Something went wrong, please check and try again";
            this.progress = false;
          });
      }
    },
    goto_account() {
      if (this.$refs.person_form.validate()) {
        this.step = 2;
      }
    },

    sign_up() {
      if (this.$refs.account_form.validate()) {
        if (this.new_password === this.confirm_password) {
          this.progress = true;
          let signup_form = new FormData();
          signup_form.append("first_name", this.first_name);
          signup_form.append("last_name", this.last_name);
          signup_form.append("phone_number", this.phone_number);
          signup_form.append("email", this.new_email);
          signup_form.append("country_id", this.country_id);
          signup_form.append("password", this.new_password);
          signup_form.append("password_confirmation", this.confirm_password);

          axios
            .post("./api/auth/signup", signup_form)
            .then((res) => {
              setAccessToken(res.data.access_token);

              this.$store.state.user.access_token = res.data.access_token;
              this.$store.commit("init_user");
              this.step = this.step = 3;
              this.progress = false;

              // let formdata = {email:new_email,password:new_password};
              //
              // axios.post('/api/auth/login',formdata)
              //     .then(user=>{
              //         localStorage.access_token = user.data.access_token;
              //         this.$store.state.user.access_token = user.data.access_token;
              //         this.$store.state.user.token_beerer = user.data.token_type;
              //         this.$store.state.user.expires_at= user.data.expires_at;
              //         this.$store.state.user.is_loged_in=true;
              //         this.$store.commit('init_user');
              //         this.step = 3;
              //         this.progress=false;
              //     });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.login_error = true;
          this.error_msg =
            "Your passwords are not the same please check and try again";
        }
      }
    },
    get_countries() {
      axios
        .get("/api/getcountries")
        .then((res) => {
          this.countries = res.data;
        })
        .catch(() => {
          // this.error_msg =
          //   "Looks like there is something wrong with your connection";
          // this.login_error = true;
        });
    },

    first_login(email, password) {
      alert("signed in");
      let formdata = { email: email, password: password };

      axios.post("/api/auth/login", formdata).then((user) => {
        setAccessToken(user.data.access_token);

        this.$store.state.user.access_token = user.data.access_token;
        this.$store.state.user.token_beerer = user.data.token_type;
        this.$store.state.user.expires_at = user.data.expires_at;
        this.$store.state.user.is_loged_in = true;
        this.$store.commit("init_user");
        this.step = 3;
        this.progress = false;
      });
    },
    login() {
      if (this.$refs.login_form.validate()) {
        this.progress = true;

        let formdata = { email: this.email, password: this.password };

        axios
          .post("/api/auth/login", formdata)
          .then((user) => {
            setAccessToken(user.data.access_token);
            this.$store.state.user.access_token = user.data.access_token;
            this.$store.state.user.token_beerer = user.data.token_type;
            this.$store.state.user.expires_at = user.data.expires_at;
            this.$store.state.user.is_loged_in = true;
          })

          .then(() => {
            this.$store.commit("init_user");
          })
          .then(() => {
            this.$router.push({ path: "/" });
          })
          .catch(() => {
            this.progress = false;
            // this.login_error = true;
            // this.error_msg =
            //   "The account you provided is not authorised or cannot be found, please check and try again";
          });
      }
    },
  },
};
</script>

<style scoped></style>
