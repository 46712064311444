var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"10"}},[_c('v-card',{staticStyle:{"margin-top":"10%"},attrs:{"flat":""}},[_c('v-stepper',{staticStyle:{"box-shadow":"none","padding":"0 !important"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card-text',{staticClass:"mb-5 mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-avatar',[_c('v-img',{attrs:{"src":`${_vm.$store.state.user.user_infor.current_business.logo}`,"alt":"logo","contain":""}})],1),_vm._v(" Hi there, ")],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"color":_vm.$vuetify.theme.isDark ? 'white' : 'red',"rounded":"","text":""},on:{"click":_vm.skipOnboarding}},[_vm._v("Skip Onboarding "),_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('h1',{staticClass:"text-center font-weight-bold",class:_vm.$vuetify.theme.isDark ? 'white--text' : 'text-built'},[_vm._v(" Who do we have here today? ")]),_c('h3',{staticClass:"text-center font-weight-light",class:_vm.$vuetify.theme.isDark ? 'white--text' : 'text-built'},[_vm._v(" Which of these best describe you? ")]),_c('p',{staticClass:"text-center font-weight-light text--disabled"},[_vm._v(" This helps us show you what to import to fully onboard you ")])])],1),_c('div',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"outlined":""},on:{"click":function($event){_vm.step = 2}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{class:_vm.$vuetify.theme.isDark
                              ? 'white--text'
                              : 'text-built',staticStyle:{"font-size":"150px"},attrs:{"x-large":""}},[_vm._v("mdi-account")]),_c('h2',{staticClass:"font-weight-bold",class:_vm.$vuetify.theme.isDark
                              ? 'white--text'
                              : 'text-built'},[_vm._v(" Business owner or "),_c('br'),_vm._v("Employee ")]),_c('small',{staticClass:"text--disabled"},[_vm._v("Basic setup for users without accounting knowledge")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"outlined":""},on:{"click":function($event){_vm.step = 3}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{class:_vm.$vuetify.theme.isDark
                              ? 'white--text'
                              : 'text-built',staticStyle:{"font-size":"150px"},attrs:{"x-large":""}},[_vm._v("mdi-book-open-variant")]),_c('h2',{staticClass:"font-weight-bold",class:_vm.$vuetify.theme.isDark
                              ? 'white--text'
                              : 'text-built'},[_vm._v(" Bookkeeper or "),_c('br'),_vm._v("Accountant ")]),_c('small',{staticClass:"text--disabled"},[_vm._v("Advanced setup for users with accounting knowledge")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"outlined":""},on:{"click":function($event){_vm.step = 4}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{class:_vm.$vuetify.theme.isDark
                              ? 'white--text'
                              : 'text-built',staticStyle:{"font-size":"150px"},attrs:{"x-large":""}},[_vm._v("mdi-application-import")]),_c('h2',{staticClass:"font-weight-bold",class:_vm.$vuetify.theme.isDark
                              ? 'white--text'
                              : 'text-built'},[_vm._v(" Account "),_c('br'),_vm._v(" Migration ")]),_c('small',{staticClass:"text--disabled"},[_vm._v("For users moving from other supported bookkeeping apps")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}}),_c('v-col',{attrs:{"cols":"2"}},[(_vm.$vuetify.theme.isDark)?_c('v-img',{staticClass:"ma-4",attrs:{"contain":"","height":"20","src":"https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/logo2.webp"}}):_c('v-img',{staticClass:"ma-4",attrs:{"contain":"","height":"20","src":"/img/app_name.png"}})],1)],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[(!_vm.loading)?_c('business-owner-onboaring-component',{attrs:{"default-cash_bank":_vm.defaultCash_bank,"default-customers":_vm.defaultCustomers,"default-items":_vm.defaultItems,"default-suppliers":_vm.defaultSuppliers,"finished":_vm.finished},on:{"back":function($event){_vm.step = _vm.step - 1}}}):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":"3"}},[(!_vm.loading && _vm.step == 3)?_c('accounting-onboaring-component',{attrs:{"default-trial-balance":_vm.defaultTrial_balance,"finished":_vm.finished},on:{"back":function($event){_vm.step = _vm.step - 2}}}):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":"4"}},[(_vm.step == 4)?_c('account-migration',{attrs:{"default-app":_vm.appName,"trial-balance":_vm.defaultTrial_balance,"default-suppliers":_vm.defaultSuppliers,"default-customers":_vm.defaultCustomers,"defautl-items":_vm.defaultItems,"default-bank-accounts":_vm.defaultCash_bank,"finish":_vm.finished},on:{"back":function($event){_vm.step = _vm.step - 3}}}):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }