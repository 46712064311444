<template>
  <v-alert v-if="false"  class="mt-3 mb-3" color="#F9E251" style="border-radius: 20px; box-shadow: none;">
    <v-row dense>
      <v-col cols="12" sm="1" class="pa-0 ma-0">
        <v-img contain height="70"
               src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/icons8-sun-star-100.png"></v-img>
      </v-col>

      <v-col cols="12" sm="10">

<!--        <h4 style="color: #0D2E57" class="mt-5 font-weight-bold">Get 50% off on your annual subscriptions. valid-->
<!--          for:</h4>-->

           <h4 style="color: #0D2E57" class="mt-5 text-h6 font-weight-light">Dear valued customer, we would like to inform you that our prices will be adjusted effective August 1, 2023.</h4>



      </v-col>

<!--      <v-col cols="12" sm="5" class="pt-3">-->

<!--        <vue-countdown :time="Number(countDown.days) * 24 * 60 * 60 * 1000">-->
<!--          <template slot-scope="props">-->
<!--            <v-row dense>-->
<!--              <v-col cols="12" sm="3">-->
<!--                <div class="counter-item">-->
<!--                  {{ props.days }} day{{ props.days > 1 ? 's' : '' }}-->
<!--                </div>-->
<!--              </v-col>-->

<!--              <v-col cols="12" sm="3">-->
<!--                <div class="counter-item">{{ props.hours }} hour{{ props.hours > 1 ? 's' : '' }}</div>-->
<!--              </v-col>-->

<!--              <v-col cols="12" sm="3">-->
<!--                <div class="counter-item">{{ props.minutes }} minute{{ props.minutes > 1 ? 's' : '' }}</div>-->
<!--              </v-col>-->


<!--              <v-col cols="12" sm="3">-->
<!--                <div class="counter-item">{{ props.seconds }} second{{ props.seconds > 1 ? 's' : '' }}</div>-->
<!--              </v-col>-->


<!--            </v-row>-->

<!--          </template>-->
<!--        </vue-countdown>-->

<!--      </v-col>-->
<!--      <v-col cols="12" sm="2">-->

<!--        <v-btn style="text-decoration: none;" to="/plans?cycle=12&discount=50" class="mt-2" color="green" dark rounded-->
<!--               x-large depressed block>Buy &-->
<!--          Save-->
<!--          <v-icon>mdi-arrow-right</v-icon>-->
<!--        </v-btn>-->
<!--      </v-col>-->


    </v-row>
  </v-alert>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import moment from "moment";

export default {
  name: "promoAnnouncer",
  components: {
    VueCountdown
  },
  computed: {
    countDown() {


      const now = moment();
      const end = moment("1/08/2023", "DD/MM/YYYY"); // another date

      const duration = moment.duration(end.diff(now));


      //Get Days and subtract from duration
      const days = duration.asDays();

      duration.subtract(days, 'days');

      //Get hours and subtract from duration
      const hours = duration.hours();
      duration.subtract(hours, 'hours');

      //Get Minutes and subtract from duration
      const minutes = duration.minutes();
      duration.subtract(minutes, 'minutes');

      //Get seconds
      const seconds = duration.seconds();

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
    }
  }
}
</script>

<style scoped>
.counter-item {
  padding: 15px;
  background-color: #0D2E57;
  border-radius: 10px;
  height: 50px !important;
  color: #F9E251;
  text-align: center;
}

</style>