<template>
<div>
<v-banner v-if="show" outlined transition="slide-x-transition">
    <v-row>
        <v-col cols="12" sm="10">
            <h3>{{message.message[0].title}}</h3>
            {{message.message[0].message}}<br>
            <small class="text-muted">ON: {{message.message[0].created_at}}</small>

        </v-col>
        <v-col cols="12" sm="2">
            <v-btn @click="applyTaxes()" :loading="progress" color="green" rounded depressed dark>
                Apply Taxes
            </v-btn>
        </v-col>
    </v-row>
</v-banner>

    <v-dialog persistent width="500" v-model="applied">
        <v-card>
            <v-card-title>Taxes Applied</v-card-title>
            <v-card-text>
                <h3 class="font-weight-light">These taxes were added to your sales taxes</h3>

                <v-list v-if="appliedtaxes">
                    <v-list-item v-for="tax in appliedtaxes" :key="tax.id">

                        <v-list-item-content>
                            <v-list-item-title>{{tax.name}}</v-list-item-title>
                            <v-list-item-subtitle>{{tax.display_name}}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-icon>
                            <v-icon color="green">mdi-check</v-icon>
                        </v-list-item-icon>

                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn block color="blue darken-4" dark @click="done()">Okay</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
    export default {
        name: "NewTaxesAnnouncement",
        data(){
            return{
                message:null,
                show:false,
                progress:false,
                appliedtaxes:null,
                applied:false,

            }
        },
        methods:{
          getTaxNotification(){
              axios.get('/api/newtaxes')
              .then(res=>{
                  this.message = res.data;
                  this.show = res.data.show;
              })
          },

            applyTaxes(){

              this.progress = true;
              axios.get('/api/applynewtaxes')
                    .then(res=>{
                        this.appliedtaxes = res.data;
                        this.applied = true;
                        this.progress = false;
                    })
            },
            done(){

              window.location.reload();
            }




        },
        mounted() {
           // this.getTaxNotification();
        }
    }
</script>

<style scoped>

</style>
