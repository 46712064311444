
<template>
  <v-btn color="blue" icon small><v-icon small>mdi-bank-plus</v-icon></v-btn>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  props:{
    account:{
      type:Object
    }
  },
  name: "LinkBankBTN"
})
</script>


<style scoped>

</style>