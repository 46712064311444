var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.showStart)?_c('v-card',{attrs:{"flat":"","color":"blue lighten-5 blue--text"}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-img',{attrs:{"src":"/img/migrate_export.svg"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('h2',{staticClass:"font-weight-black blue--text"},[_vm._v("Let's help you move!")]),_c('p',{staticClass:"blue--text"},[_vm._v(" Export all your important data in the new currency so you can easily move to a new account ")]),_c('v-btn',{attrs:{"depressed":"","color":"blue darken-4","dark":"","large":"","rounded":"","block":""},on:{"click":function($event){_vm.showDialog = true}}},[_vm._v("Get Started"),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1):_c('v-card',[_c('v-card-title',{staticClass:"font-weight-light"},[_vm._v("Export data "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red lighten-5 red--text","rounded":"","depressed":"","small":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_vm._v("close"),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-alert',{attrs:{"dense":"","type":"success","color":"green lighten-5 green--text"}},[_c('h4',[_vm._v(" In order to easily move to a new account with the new currency, you would need to download these files ")])]),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-cube-outline")])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',[_vm._v("Download products and services")]),_c('p',[_vm._v("Download your products and services in excel format")]),_c('v-btn',{attrs:{"href":_vm.$store.state.baseURL +
                  '/' +
                  'download/sl/items/' +
                  _vm.business.enc_id,"text":"","small":"","color":"blue"}},[_vm._v("Download"),_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-multiple-outline")])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',[_vm._v("Download customers")]),_c('p',[_vm._v("Download a list of your customers in excel format")]),_c('v-btn',{attrs:{"href":_vm.$store.state.baseURL +
                  '/' +
                  'download/sl/customers/' +
                  _vm.business.enc_id,"text":"","small":"","color":"blue"}},[_vm._v("Download"),_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-multiple-outline")])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',[_vm._v("Download suppliers")]),_c('p',[_vm._v("Download a list of your suppliers in excel format")]),_c('v-btn',{attrs:{"href":_vm.$store.state.baseURL +
                  '/' +
                  'download/sl/suppliers/' +
                  _vm.business.enc_id,"text":"","small":"","color":"blue"}},[_vm._v("Download"),_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-sitemap")])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',[_vm._v("Download Other Accounts")]),_c('p',[_vm._v("Download your chart of accounts in excel format")]),_c('v-btn',{attrs:{"href":_vm.$store.state.baseURL +
                  '/' +
                  'download/sl/accounts/' +
                  _vm.business.enc_id,"text":"","small":"","color":"blue"}},[_vm._v("Download"),_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)],1)],1)],1),_c('h2',{staticClass:"black--text mt-3"},[_vm._v(" Done?, "),_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":_vm.createBusiness}},[_vm._v("Create a new business to continue")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","width":"500","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-light"},[_vm._v("Export data")]),_c('v-card-text',[_c('v-alert',{attrs:{"dense":"","type":"success","color":"green lighten-5 green--text"}},[_c('h4',[_vm._v(" In other to easily move to a new account with the new currency, you would need to download these files ")])]),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-cube-outline")])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',[_vm._v("Download products and services")]),_c('p',[_vm._v("Download your products and services in excel format")]),_c('v-btn',{attrs:{"href":_vm.$store.state.baseURL +
                    '/' +
                    'download/sl/items/' +
                    _vm.business.enc_id,"text":"","small":"","color":"blue"}},[_vm._v("Download"),_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-multiple-outline")])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',[_vm._v("Download customers")]),_c('p',[_vm._v("Download a list of your customers in excel format")]),_c('v-btn',{attrs:{"href":_vm.$store.state.baseURL +
                    '/' +
                    'download/sl/customers/' +
                    _vm.business.enc_id,"text":"","small":"","color":"blue"}},[_vm._v("Download"),_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-multiple-outline")])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',[_vm._v("Download suppliers")]),_c('p',[_vm._v("Download a list of your suppliers in excel format")]),_c('v-btn',{attrs:{"href":_vm.$store.state.baseURL +
                    '/' +
                    'download/sl/suppliers/' +
                    _vm.business.enc_id,"text":"","small":"","color":"blue"}},[_vm._v("Download"),_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-sitemap")])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',[_vm._v("Download Other Accounts")]),_c('p',[_vm._v("Download your chart of accounts in excel format")]),_c('v-btn',{attrs:{"href":_vm.$store.state.baseURL +
                    '/' +
                    'download/sl/accounts/' +
                    _vm.business.enc_id,"text":"","small":"","color":"blue"}},[_vm._v("Download"),_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)],1)],1)],1),_c('h2',{staticClass:"black--text mt-3"},[_vm._v(" Done?, "),_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":_vm.createBusiness}},[_vm._v("Create a new business to continue")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }