<template>

  <v-btn
          v-if="journal && journal.id"
          outlined
          color="blue"
          target="_blank"
          :to="'/settings/openingbalance/'+journal.id+'?itemid='+journal.journal_id"
          class="mt-2 mb-2"
  >{{$t('main.preview')}} {{$t('main.journal')}} <v-icon x-small>mdi-arrow-top-right</v-icon></v-btn>

</template>

<script>
    export default {
        props:['invoice_id','type'],
        name: "journalLink",
        data(){
            return{
                journal:null
            }
        },
        methods:{
            getJournal(){

                axios.get('/api/'+this.type+'/journal/'+this.invoice_id)
                    .then(res=>{
                        this.journal = res.data;
                    })
            }
        },
        mounted() {
            this.getJournal();
        }
    }
</script>

<style scoped>

</style>
