<template>
  <v-row>
    <v-col cols="12" sm="12" class="mx-auto">
      <v-progress-circular
          size="40"
          color="blue darken-4"
          indeterminate
          v-if="verifyingOTP"
      ></v-progress-circular>
      <v-otp-input
          v-else
          length="6"
          @finish="veirifyOTP"
          v-model="code"
      />
    </v-col>
    <v-snackbar color="error" v-model="$store.state.momo.failed">{{$store.state.momo.message}}</v-snackbar>
  </v-row>
</template>

<script>
export default {
  props: ['phone_number', 'id'],
  name: "OTPComponent",
  data(){
    return{
      verifyingOTP:false,
      code:''
    }
  },
  methods: {

    veirifyOTP() {
      this.verifyingOTP = true;
      const formData = new FormData();

      formData.append('id', this.id);
      formData.append('phone_number', this.phone_number);

      axios.post('/api/auth/code/verify/' + this.code, formData)
          .then(res => {

            this.verifyingOTP = false;
            this.$emit('done', res.data);

          })
          .catch(error => {
            this.verifyingOTP = false;
            // this.$store.state.momo.message = "Something went wrong, could not verify the OTP code;";
            // this.$store.state.momo.failed = true;

          });


    },
  }
}
</script>

<style scoped>

</style>