<template>
    <div>
        <v-dialog
                v-model="preview_dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable
                persistent
        >
            <v-card flat>
                <v-toolbar
                        flat
                        class="d-print-none"
                >
                    <v-btn
                            icon
                            @click="preview_dialog = false; $emit('dialogClosed')"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{$store.state.account.account_name}} - {{$t('accounts.transaction_statement')}}

                    <v-toolbar-items>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="invoice_temp" >
                    <accountpreviewComponent></accountpreviewComponent>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script> 
import accountpreviewComponent from './accountpreviewComponent.vue';
export default {
    props: ['account'],
    components: {
        accountpreviewComponent
    },
    data() {
        return {
            preview_dialog: true
        }
    },
    mounted() {
        this.$store.state.account = this.account;
        this.$store.state.totalBalance = this.account.balance;
        this.$store.state.accountId = this.account.id;
        this.$store.commit('get_account_transactions');
        this.$store.state.accountType = 'Assets';
    }
}
</script>