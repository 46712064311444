<template>
	<span>
		<v-dialog v-model="showDialog" width="500" persistent>
			<v-card>
				<v-card-text class="text-center pa-4">
					<center>
						<v-img src="/img/app_name.png" contain max-width="200"></v-img>
						<h1 class="font-weight-light ma-5">Welcome to Built Accounting</h1>
						<v-btn
							@click="finish"
							dark
							color="blue darken-4"
							large
							rounded
							block
							depressed
							>Continue <v-icon>mdi-arrow-right</v-icon></v-btn
						>
					</center>
				</v-card-text>
			</v-card>
		</v-dialog>
	</span>
</template>

<script>
import { setAccessToken } from "../utils";
export default {
	name: "initComponent",
	data() {
		return {
			showDialog: true,
		};
	},
	computed: {
		token() {
			return this.$route.query.token;
		},
    forward_url(){
      if (this.$route.query.token){
        return "/"+this.$route.query.fr;
      }
      return "/dashboard";

    }
	},

	methods: {
		finish() {
			setAccessToken(this.token);
			window.location = this.forward_url;
		},
	},
	mounted() {
		if (this.$route.query.s) {
			setAccessToken(this.token);
			window.location = "/";
		}
	},

	created() {
		if (this.$route.query.s) {
			setAccessToken(this.token);
			window.location = "/";
		}
	},
};
</script>

<style scoped></style>
